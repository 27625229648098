import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'

import { Checkbox } from '../../../checkbox/checkbox'
import { SPToExtend } from '../../bulk-extend-jobs-popup'
import './sp-item.scss'
import { Separator } from '../../../app-layout'

export const SpItem = ({ sp, onClick, selected }: { sp: SPToExtend; onClick: () => void; selected: boolean }) => {
  const { t } = useTranslation()

  return (
    <div onClick={onClick} key={sp.id} className="ExtendedSPItem">
      <div className="ExtendedSPItem__body">
        <img className="ExtendedSPItem__body__image" src={String(sp.image)} />
        <div className="ExtendedSPItem__content">
          <div className="ExtendedSPItem__titleContainer">
            <span className="ExtendedSPItem__title">{sp.name}</span>
          </div>
          <Separator.Vertical height={5} />
          <span className="ExtendedSPItem__subtitle">
            {t('order_extension.current_end_date')}{' '}
            <strong>{moment(sp.current_end_date, 'YYYY-MM-DD').format('MMM DD')}</strong>
          </span>
        </div>
      </div>
      <Checkbox isActive={selected} type="square" />
    </div>
  )
}
