import { useState } from 'react'
import { observer } from 'mobx-react'

import Rating from '../../../rating/rating'
import './sp-item.scss'
import { Separator } from '../../../app-layout'

export const SpItem = observer(
  ({
    sp,
    changeRating,
  }: {
    sp: {
      id: number
      image_url: string | null
      name: string
      rating: number | null
    }
    changeRating: (rating: number) => void
  }) => {
    const [rating, setRating] = useState<number | null>(sp.rating)

    return (
      <div key={sp.id} className="SPItem">
        <div className="SPItem__body">
          <img className="SPItem__body__image" src={String(sp.image_url)} />
          <div className="SPItem__content">
            <div className="SPItem__titleContainer">
              <span className="SPItem__title">{sp.name}</span>
            </div>
            <Separator.Vertical height={10} />
            <Rating
              rating={Number(rating)}
              size={25}
              editable
              onRatingChange={(value: number) => {
                setRating(value)
                changeRating(value)
              }}
            />
          </div>
        </div>
      </div>
    )
  },
)
