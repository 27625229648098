import { flow, types } from 'mobx-state-tree'
import moment from 'moment'

import { formatDateToHhMm, formatDateToIso8601 } from '../../utils/datetime-utils'
import { DataStore } from '../data-store/data-store'
import { ScheduleSpModel } from './sp'
import { ScheduleShiftModel } from './shift'
import { ScheduleDatesModel } from './date-data-map'
import { convertToSchedule } from './utils/convert-to-schedule'
import {
  ApiJobSchedule,
  CancelShift,
  JobModificationRequest,
  ModifyActionRequest,
  Schedule,
  ScheduleActiveShift,
  ScheduleSp,
  SpShiftStatus,
} from './types'
import { getShiftStatusTitle } from './utils/get-shift-status-title'
import { ScheduleActiveShiftSnapshot } from './active-shift'

export type GroupedLocationItem = {
  location_id: number
  location_name: string
  work_categories: GroupedWorkCategoryItem[]
}

export type GroupedWorkCategoryItem = {
  work_category_name: string
  quantity: number
  assigned_sps: GroupedSPItem[]
  default_schedule: Schedule
  unfulfilled_schedules: Schedule[]
}

export type GroupedSPItem = {
  sp_id: number
  name: string
  image: string
  phone: string | null
  is_favorite: boolean
  active_shifts: GroupedActiveShiftItem[]
  original_schedule: Schedule[]
}

export type GroupedActiveShiftItem = {
  job_id: number
  order_id: number
  shift_id: number
  active_shift_id: number
  start_time: number | null
  end_time: number | null
  client_checkin_time: number | null
  client_checkout_time: number | null
  is_available_for_confirmation: boolean
  status: {
    id: number
    title: string
  }
}

export const ScheduleModel = types
  .model('Schedule', {
    // Record<'YYYY-MM-DD', model>
    job_id: types.identifierNumber,
    order_id: types.number,
    location_id: types.number,
    start_date: types.string, // YYYY-MM-DD
    end_date: types.string, // YYYY-MM-DD
    work_category_name: types.string,
    location_name: types.string,
    quantity: types.number,
    shifts: types.map(ScheduleShiftModel),
    assigned_sps: types.map(ScheduleSpModel), // Record<sp_id, ScheduleSpModel>
    dates: ScheduleDatesModel,
    applicants_count: types.number,
    modifications: types.model('ScheduleModifications', {
      shifts: types.map(ScheduleShiftModel),
      assigned_sps: types.map(ScheduleSpModel),
    }),
  })
  .views(self => ({
    get spList() {
      return Array.from(self.assigned_sps.values())
    },

    getDateShift(date: Date) {
      return self.dates.get(formatDateToIso8601(date))
    },

    getJobShift(shiftId: number) {
      const modifiedShift = self.modifications.shifts.get(String(shiftId))
      return modifiedShift ?? self.shifts.get(String(shiftId))
    },

    getSp({ spId }: { spId: number }) {
      return self.assigned_sps.get(String(spId))
    },

    getSpShift({ spId, shiftId }: { spId: number; shiftId: number }) {
      const assignedSp = self.modifications.assigned_sps.get(String(spId))
      if (assignedSp?.active_shifts.has(String(shiftId))) {
        return assignedSp.active_shifts.get(String(shiftId)) as ScheduleActiveShift
      } else {
        return self.assigned_sps.get(String(spId))?.active_shifts.get(String(shiftId))
      }
    },
  }))
  .views(self => ({
    getSpTime({ spId, shiftId }: { spId: number; shiftId: number }) {
      const spShift = self.getSpShift({ spId, shiftId })
      return {
        startTime: spShift?.client_checkin_time ?? spShift?.start_time ?? null,
        endTime: spShift?.client_checkout_time ?? spShift?.end_time ?? null,
      }
    },
  }))
  .actions(self => ({
    modifySpTime({
      spId,
      shiftId,
      startTime,
      endTime,
    }: {
      spId: number
      shiftId: number
      startTime?: number
      endTime?: number
    }) {
      const sp = self.assigned_sps.get(String(spId)) as ScheduleSp
      const shift = sp.active_shifts.get(String(shiftId))
      if (shift) {
        if (self.modifications.assigned_sps.has(String(spId))) {
          const assignedSp = self.modifications.assigned_sps.get(String(spId)) as ScheduleSp
          const modifiedShift = assignedSp.active_shifts.get(String(shiftId))
          const shiftToCopy = modifiedShift ?? shift
          assignedSp.active_shifts.set(String(shiftId), {
            ...shiftToCopy,
            start_time: startTime ?? shiftToCopy.start_time,
            end_time: endTime ?? shiftToCopy.end_time,
            status: { ...shiftToCopy.status },
          })
        } else {
          self.modifications.assigned_sps.set(
            String(spId),
            ScheduleSpModel.create({
              ...sp,
              active_shifts: {
                [shiftId]: {
                  ...shift,
                  start_time: startTime ?? shift.start_time,
                  end_time: endTime ?? shift.end_time,
                  status: { ...shift.status },
                },
              },
            }),
          )
        }
      }
    },
    setOgrammerCheckInTime: function ({ spId, shiftId, time }: { spId: number; shiftId: number; time: number }) {
      const spShift = self.getSpShift({ spId, shiftId })
      if (spShift) {
        spShift.client_checkin_time = time
      }
    },
    setOgrammerCheckOutTime: function ({ spId, shiftId, time }: { spId: number; shiftId: number; time: number }) {
      const spShift = self.getSpShift({ spId, shiftId })
      if (spShift) {
        spShift.client_checkout_time = time
      }
    },
    setOgrammerStatus: function ({ spId, shiftId, status }: { spId: number; shiftId: number; status: SpShiftStatus }) {
      const spShift = self.getSpShift({ spId, shiftId })
      if (spShift) {
        spShift.status.id = status
        spShift.status.title = getShiftStatusTitle(status)
      }
    },
    toogleOgrammerFavouriteStatus: function ({ spId }: { spId: number }) {
      const sp = self.assigned_sps.get(String(spId))
      if (sp) {
        sp.is_favorite = !sp.is_favorite
      }
    },
    applyModification() {
      for (const shift of self.modifications.shifts.values()) {
        self.shifts.set(String(shift.id), { ...shift })
      }
      for (const assignedSp of self.modifications.assigned_sps.values()) {
        const originSp = self.assigned_sps.get(String(assignedSp.id))
        if (originSp) {
          for (const activeShift of assignedSp.active_shifts.values()) {
            originSp.active_shifts.set(String(activeShift.shift_id), {
              ...activeShift,
              status: { ...activeShift.status },
            })
          }
        }
      }
    },
  }))
  .actions(self => ({
    modifyActiveShiftsTime({ shiftId, startTime, endTime }: { shiftId: number; startTime?: number; endTime?: number }) {
      for (const assignedSp of self.assigned_sps.values()) {
        for (const shift of assignedSp.active_shifts.values()) {
          if (shift.shift_id === shiftId) {
            self.modifySpTime({
              spId: assignedSp.id,
              shiftId: shiftId,
              startTime,
              endTime,
            })
          }
        }
      }
      const shift = self.modifications.shifts.get(String(shiftId)) ?? self.shifts.get(String(shiftId))
      if (shift) {
        self.modifications.shifts.set(String(shiftId), {
          ...shift,
          id: shiftId,
          start_time: startTime ?? shift.start_time,
          end_time: endTime ?? shift.end_time,
        })
      }
    },
  }))

export const scheduleActions = (self: DataStore) => ({
  modifyWorkCategoryDateSchedule({
    workCategoryName,
    locationId,
    date,
    spIds,
    startTime,
    endTime,
  }: {
    workCategoryName: string
    locationId: number
    date: string
    spIds: number[]
    startTime?: number
    endTime?: number
  }) {
    self.schedule.forEach(scheduleArray => {
      scheduleArray.forEach(scheduleItem => {
        if (scheduleItem.work_category_name === workCategoryName && scheduleItem.location_id === locationId) {
          scheduleItem.assigned_sps.forEach(assignedSP => {
            if (spIds.includes(assignedSP.id)) {
              assignedSP.active_shifts.forEach(activeShift => {
                if (activeShift.start_time) {
                  const startDate = moment.unix(activeShift.start_time).format('YYYY-MM-DD')
                  if (startDate === date) {
                    scheduleItem.modifySpTime({
                      spId: assignedSP.id,
                      shiftId: activeShift.shift_id,
                      startTime,
                      endTime,
                    })
                  }
                }
              })
            }
          })
        }
      })
    })
  },
  getSchedule: flow(function* (date: Date, locationIds: number[], designationIds: number[]) {
    const formattedDate = formatDateToIso8601(date)

    let uri = `client/advanced-scheduling?date=${formattedDate}`
    locationIds.forEach(locationId => {
      uri += `&location_id[]=${locationId}`
    })
    designationIds.forEach(designationId => {
      uri += `&designation_id[]=${designationId}`
    })

    const schedule = (yield self.request('get', uri)) as ApiJobSchedule[]

    self.schedule.clear()
    self.schedule.set(formattedDate, convertToSchedule(schedule, date))

    return schedule
  }),

  checkInOgrammer: flow(function* ({ id, time }: { id: number; time: string }) {
    yield self.request('post', `client/active-shift/${id}/checkin`, { time })
  }),

  checkOutOgrammer: flow(function* ({ id, time }: { id: number; time: string }) {
    yield self.request('post', `client/active-shift/${id}/checkout`, { time })
  }),

  checkInOgrammerWithQR: flow(function* ({ id, time }: { id: number; time: string }) {
    yield self.request('post', `client/active-shift/${id}/checkin`, { time, is_qr: 1 })
  }),

  checkOutOgrammerWithQR: flow(function* ({ id, time }: { id: number; time: string }) {
    yield self.request('post', `client/active-shift/${id}/checkout`, { time, is_qr: 1 })
  }),

  extendShift: flow(function* ({ id, time }: { id: number; time: number }) {
    yield self.request('post', `client/active-shift/${id}/extend`, { extend_time: time })
  }),

  makeOgrammerFavourite: flow(function* ({ spId }: { spId: number }) {
    yield self.request('post', 'client/sp/save', { sp_id: spId })
  }),
  removeOgrammerFromFavourites: flow(function* ({ spId }: { spId: number }) {
    yield self.request('post', 'client/sp/un-save', { sp_id: spId })
  }),

  cancelShift: flow(function* ({
    id,
    reasonId,
    reasonDetails,
    cancelFollowing,
    blockOgrammer,
  }: {
    id: number
    reasonId: number
    reasonDetails: string
    cancelFollowing: boolean
    blockOgrammer: boolean
  }) {
    const requestBody: CancelShift = {
      cancellation_reason_id: reasonId,
      cancel_following: cancelFollowing ? 1 : 0,
      is_block: blockOgrammer ? 1 : 0,
    }
    if (reasonDetails.length > 0) {
      requestBody.cancellation_reason_details = reasonDetails
    }
    yield self.request('post', `client/active-shift/${id}/cancel`, requestBody)
  }),

  clearScheduleData() {
    self.schedule.clear()
  },

  clearScheduleChanges() {
    for (const jobSchedules of self.schedule.values()) {
      jobSchedules.forEach(jobItemSchedule => {
        jobItemSchedule.modifications.shifts.clear()
        jobItemSchedule.modifications.assigned_sps.clear()
      })
    }
  },

  applyScheduleChanges: flow(function* () {
    const jobs: JobModificationRequest = []
    const jobActions = new Map<number, ModifyActionRequest[]>()
    // Iterate over all date periods (I.g. 01 Sept - 07 Sept)
    Array.from(self.schedule.values()).forEach(dateJobs => {
      // Iterate over all jobs that belong to the date period
      dateJobs.forEach(jobSchedule => {
        if (!jobActions.has(jobSchedule.job_id)) {
          jobActions.set(jobSchedule.job_id, [])
        }
        const actions = jobActions.get(jobSchedule.job_id) as ModifyActionRequest[]
        // Modify shift times only if no OGs assigned (otherwise OGs will be canceled)
        Array.from(jobSchedule.modifications.shifts.values()).forEach(shift => {
          if (shift.fulfilled_count === 0) {
            addAction(actions, {
              start_time: shift.start_time,
              end_time: shift.end_time,
              sps: [],
            })
          }
        })
        // Modify active shift times
        Array.from(jobSchedule.modifications.assigned_sps.values()).forEach(assignedSp => {
          Array.from(assignedSp.active_shifts.values()).forEach(shift => {
            addAction(actions, {
              start_time: shift.start_time as number,
              end_time: shift.end_time as number,
              sps: [assignedSp.id],
            })
          })
        })
      })
    })
    // Fill jobs with non-empty actions
    Array.from(jobActions.entries()).forEach(([jobId, actions]) => {
      if (actions.length > 0) {
        jobs.push({ id: jobId, actions })
      }
    })
    yield self.request('post', 'client/advanced-scheduling/jobs/modify', { jobs })
    // Apply modifications as saved time
    Array.from(self.schedule.values()).forEach(dateJobs => {
      dateJobs.forEach(jobSchedule => {
        jobSchedule.applyModification()
      })
    })

    /**
     * Adds a modification to apply in the request
     * @param actions - array of shift actions to be applied to a job
     * @param shift - shift or active_shift being modified
     */
    function addAction(
      actions: ModifyActionRequest[],
      shift: {
        start_time: number
        end_time: number
        sps: number[]
      },
    ) {
      const shiftDate = formatDateToIso8601(new Date(shift.start_time * 1000))
      actions.push({
        start_date: shiftDate,
        end_date: shiftDate,
        type: 'replace',
        start_time: formatDateToHhMm(new Date(shift.start_time * 1000)),
        end_time: formatDateToHhMm(new Date(shift.end_time * 1000)),
        sp_ids: shift.sps,
      })
    }
  }),
})

export const scheduleViews = (self: DataStore) => ({
  getSPsByWorkCategoryNameAndDate({
    workCategoryName,
    locationId,
    date,
  }: {
    workCategoryName: string
    locationId: number
    date: string
  }) {
    const result: { key: string; label: string; value: number }[] = []
    self.schedule.forEach(scheduleArray => {
      scheduleArray.forEach(scheduleItem => {
        if (scheduleItem.work_category_name === workCategoryName && scheduleItem.location_id === locationId) {
          scheduleItem.assigned_sps.forEach(assignedSP => {
            assignedSP.active_shifts.forEach(activeShift => {
              if (activeShift.start_time) {
                const startDate = moment.unix(activeShift.start_time).format('YYYY-MM-DD')
                if (startDate === date) {
                  const item = {
                    key: String(assignedSP.id),
                    label: assignedSP.name,
                    value: assignedSP.id,
                  }

                  const existingItem = result.find(checkedItem => checkedItem.value === item.value)
                  if (!existingItem) {
                    result.push(item)
                  }
                }
              }
            })
          })
        }
      })
    })

    return result
  },

  getJobsScheduleForWeek(date: string) {
    return self.schedule.get(date) ?? ([] as Schedule[])
  },

  getJobShift(jobId: number, weekDate: string, shiftId: number) {
    const weekSchedule = self.schedule.get(weekDate)
    return weekSchedule?.find(job => job.job_id === jobId)?.getJobShift(shiftId) ?? null
  },

  // collects all active_shift modifications of jobs
  get scheduleModificationsAmount(): number {
    return Array.from(self.schedule.values()).reduce((overallModifications, jobSchedules) => {
      return (
        overallModifications +
        jobSchedules.reduce((weekModifications, jobItemSchedule) => {
          const shiftModifications = Array.from(jobItemSchedule.modifications.shifts.values()).reduce(
            (modifiedShiftsCount, shift) => {
              return modifiedShiftsCount + 1
            },
            0,
          )
          const activeShiftModifications =
            weekModifications +
            Array.from(jobItemSchedule.modifications.assigned_sps.values()).reduce(
              (modifiedActiveShiftsCount, assignedSp) => {
                return modifiedActiveShiftsCount + assignedSp.active_shifts.size
              },
              0,
            )
          return shiftModifications + activeShiftModifications
        }, 0)
      )
    }, 0)
  },

  get groupedSchedule() {
    const groupedResult: GroupedLocationItem[] = []

    self.schedule.forEach(scheduleArray => {
      scheduleArray.forEach(schedule => {
        const { location_id, location_name, work_category_name, job_id, order_id, spList, quantity } = schedule

        // Find or create location group
        let locationGroup = groupedResult.find((location: GroupedLocationItem) => location.location_id === location_id)
        if (!locationGroup) {
          locationGroup = {
            location_id,
            location_name,
            work_categories: [],
          }
          groupedResult.push(locationGroup)
        }

        const isScheduleUnfulfilled = spList.length < quantity

        // Find or create work category group
        let workCategoryGroup = locationGroup.work_categories.find(wc => wc.work_category_name === work_category_name)
        if (!workCategoryGroup) {
          workCategoryGroup = {
            work_category_name,
            quantity,
            default_schedule: schedule,
            unfulfilled_schedules: isScheduleUnfulfilled ? [schedule] : [],
            assigned_sps: [],
          }
          locationGroup.work_categories.push(workCategoryGroup)
        } else {
          workCategoryGroup.quantity += quantity
          if (isScheduleUnfulfilled) {
            workCategoryGroup.unfulfilled_schedules.push(schedule)
          }
        }

        // Aggregate assigned service providers
        Object.entries(spList).forEach(([sp_id, sp]) => {
          if (!sp.getSpModelActiveShifts().length) {
            return
          }
          let spGroup = workCategoryGroup?.assigned_sps.find(spg => spg.sp_id === sp.id)
          if (!spGroup && workCategoryGroup) {
            spGroup = {
              sp_id: sp.id,
              name: sp.name,
              image: sp.image,
              phone: sp.phone,
              is_favorite: sp.is_favorite,
              original_schedule: [schedule],
              active_shifts: sp.getSpModelActiveShifts().map((activeShift: ScheduleActiveShiftSnapshot) => {
                return {
                  job_id: job_id,
                  order_id: order_id,
                  shift_id: activeShift.shift_id,
                  active_shift_id: activeShift.active_shift_id,
                  start_time: activeShift.start_time,
                  end_time: activeShift.end_time,
                  client_checkin_time: activeShift.client_checkin_time,
                  client_checkout_time: activeShift.client_checkout_time,
                  is_available_for_confirmation: activeShift.is_available_for_confirmation,
                  status: {
                    id: activeShift.status.id,
                    title: activeShift.status.title,
                  },
                }
              }),
            }
            workCategoryGroup.assigned_sps.push(spGroup)
          }
          if (spGroup && workCategoryGroup) {
            workCategoryGroup.assigned_sps = workCategoryGroup.assigned_sps.map(assignedSpItem => {
              if (assignedSpItem.sp_id !== sp.id) {
                return assignedSpItem
              }

              const activeShifts = assignedSpItem.active_shifts
              sp.getSpModelActiveShifts().map((activeShiftItem: ScheduleActiveShiftSnapshot) => {
                const activeShift = {
                  job_id: job_id,
                  order_id: order_id,
                  shift_id: activeShiftItem.shift_id,
                  active_shift_id: activeShiftItem.active_shift_id,
                  start_time: activeShiftItem.start_time,
                  end_time: activeShiftItem.end_time,
                  client_checkin_time: activeShiftItem.client_checkin_time,
                  client_checkout_time: activeShiftItem.client_checkout_time,
                  is_available_for_confirmation: activeShiftItem.is_available_for_confirmation,
                  status: {
                    id: activeShiftItem.status.id,
                    title: activeShiftItem.status.title,
                  },
                }

                const existingActiveShift = activeShifts.find(
                  item => item.active_shift_id === activeShift.active_shift_id,
                )
                if (!existingActiveShift) {
                  activeShifts.push(activeShift)
                }
              })

              const originalSchedules = assignedSpItem.original_schedule
              const originalScheduleExists = originalSchedules.map(item => item.job_id).includes(schedule.job_id)
              if (!originalScheduleExists) {
                originalSchedules.push(schedule)
              }

              return {
                ...assignedSpItem,
                original_schedule: originalSchedules,
                active_shifts: activeShifts,
              }
            })
          }
        })
      })
    })

    return groupedResult
  },
})
