import { types } from 'mobx-state-tree'

import { ScheduleActiveShiftModel } from './active-shift'

export const ScheduleSpModel = types
  .model('ScheduleSp', {
    id: types.identifierNumber,
    name: types.string,
    image: types.string,
    phone: types.maybeNull(types.string),
    is_favorite: types.boolean,
    active_shifts: types.map(ScheduleActiveShiftModel), // Record<shift_id, ScheduleActiveShift>
  })
  .views(self => ({
    getSpModelActiveShifts() {
      return Array.from(self.active_shifts.values())
    },
  }))
