{
    "common": {
        "ogram": "Ogram",
        "start_now": "Start Now",
        "dismiss": "Dismiss",
        "continue": "Continue",
        "text_copied": "Text copied to clipboard",
        "tc": "Terms and Conditions",
        "shifts": "shifts",
        "show_more": "Show More",
        "ogs": "OGs",
        "i_accept_terms": "I accept the <1>Terms of Service</1>",
        "i_accept": "I accept the",
        "terms_of_service": "Terms of Service",
        "yes": "Yes",
        "no": "No",
        "id_n": "ID#{{id}}",
        "loading": "Loading...",
        "app_version": "App Version: {{version}}",
        "select": "Select",
        "job_n": "Job #{{id}}",
        "filters": "Filters",
        "filter": "Filter",
        "location": "Location",
        "designation": "Designation",
        "change": "Change",
        "cancel": "Cancel",
        "order_id": "Order ID",
        "is_overdue": "Is Overdue",
        "date_from": "Date From",
        "date_to": "Date To",
        "now": "Now",
        "no_data_found": "No Data Found",
        "not_available": "Not Available",
        "clear": "Clear",
        "done": "Done",
        "optional": "Optional",
        "industry": "Industry",
        "employees_amount_tier": "How many employees does your company have?"
    },
    "password": {
        "minimumChars": "+6 characters",
        "oneLowercase": "1 lowercase letter",
        "oneNumber": "1 Number",
        "oneUppercase": "1 uppercase letter"
    },
    "login": {
        "good_to_see_you_back": "Good to see you back",
        "sign_in_to_account": "Please sign in to your account",
        "enter_email": "please enter your email",
        "enter_password": "please enter your password",
        "email": "Email",
        "password": "Password",
        "remember_me": "Remember Me",
        "forgot_password_question": "Forgot password?",
        "sign_in": "Sign In",
        "signin_in": "Signing in...",
        "sign_up_link_text": "Sign Up",
        "test_string": "Testing the system"
    },
    "create_password": {
        "title": "Create a password",
        "description": "Please create a password to be able to login using your email next time",
        "please_enter_password": "Please enter your password",
        "please_confirm_password": "Please confirm your password",
        "confirm_password": "Confirm Password",
        "password": "Password",
        "password_matched": "Password Matched",
        "password_not_matched": "Password Not Matched",
        "resend_modal_button": "Resend Email",
        "sending": "Sending...",
        "email_invalid": "Your email is invalid",
        "email_required": "Please provide your email",
        "email": "Email",
        "email_is_sent": "New link has been sent to your email address.",
        "submit": "Submit"
    },
    "forgot_password": {
        "title": "Forgot password?",
        "description": "Enter the email address associated with your account",
        "please_enter_email": "please enter your email",
        "email_must_be_valid": "Email must be valid",
        "email": "Email",
        "submit": "Submit",
        "back_to_sign_in": "Back to Sign in",
        "forgot_password": "Forgot Password",
        "password_sent_to_email": "New password is sent to email.",
        "resend": "Resend",
        "reset_password": "Reset Password",
        "email_sent": "A password reset link has been sent to your email account"
    },
    "validation_messages": {
        "email_invalid": "Your email is invalid"
    },
    "reset_password": {
        "title": "Reset Password",
        "description": "Enter new password",
        "please_enter_password": "Please enter new password",
        "please_confirm_password": "Please confirm password",
        "password": "Password",
        "confirm_password": "Confirm Password",
        "password_mathched": "Password Matched",
        "password_not_matched": "Password Not Matched",
        "submit": "Submit"
    },
    "change_password": {
        "title": "Change Password",
        "description": "Enter old and new password",
        "please_enter_old_password": "Please enter old password",
        "please_enter_password": "Please enter new password",
        "please_confirm_password": "Please confirm new password",
        "old_password": "Old Password",
        "new_password": "New Password",
        "confirm_new_password": "Confirm New Password",
        "password_matched": "Password Matched",
        "password_not_matched": "Password Not Matched",
        "submit": "Submit",
        "password_changed_successfully": "Your password has been changed successfully"
    },
    "sign_up": {
        "title": "Client Registration",
        "subtitle": "Please register to be able to create and control your orders",
        "sign_up": "Sign Up",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone",
        "email": "Email",
        "password": "Password",
        "city": "City",
        "company_name_optional": "Company Name (Required for Companies)",
        "company_name": "Company Name",
        "agree_to_emails": "I agree to receive marketing notifications from Ogram",
        "are_you_corporate_question": "Are you a corporate?",
        "back_to_sign_in": "Back to Sign in",
        "first_name_required": "Please enter your first name",
        "last_name_required": "Please enter your last name",
        "industry_required": "Please select your industry",
        "employees_amount_tier_required": "Please select amount of employees",
        "phone_required": "Please enter your phone",
        "email_required": "Please enter your email",
        "company_name_required": "Company name is required for corporate users",
        "city_required": "Please enter your city",
        "submitting": "Submitting...",
        "choose_work_category_title": "What Staff do you need?",
        "choose_work_category_subtitle": "Tell us about the first job",
        "create_new_order_title": "Start Hiring",
        "contact_details_title": "What is your contact details?",
        "contact_details_subtitle": "Add your contact details so we can be able to reach you",
        "how_to_reach_title": "How can we reach you?",
        "next": "Next",
        "phone_invalid": "Please type valid phone",
        "already_registered": "Already have an account?",
        "sign_in": "Sign in",
        "consent": "By clicking the \"Sign Up\" button, you grant permission for our company to use your personal information for account creation and order processing.",
        "thank_you": "Thank you!"
    },
    "verify_email": {
        "title": "Verify your email address",
        "description": "Verification code has been sent to your email",
        "we_sent_code_to_email": "We sent code to {{email}}. Please check your inbox and enter the verification code below the finish setting up your account.",
        "please_enter_code": "please enter the code",
        "verification_code": "Verification code",
        "verify": "Verify",
        "did_not_receive_email_question": "Didn't receive the email? Resend code in 3:00 minutes",
        "not_your_email_question": "Not your email?",
        "change_email": "Change email"
    },
    "applicant_details": {
        "n_shifts_completed": "{{count}} shifts completed",
        "information": "Information",
        "work_experience": "Work Experience",
        "education": "Education",
        "get_next_applicant": "get next applicant",
        "in": "in",
        "chief_og": "Chief OG",
        "skills": "Skills",
        "added_as_favourite": "{{spName}} is now on our preferred list. We'll prioritize {{spName}} in our algorithms.",
        "chief": "<1>Chief OG's </1><2>are experienced team leaders, hand picked to better coordinate Ogram teams on the ground.</2>",
        "interview": "Interview",
        "view_answer": "View Answer",
        "job_history_with_you": "Job History with You",
        "work_category_experience": "{{ workCategoryName }} Experience",
        "other_experience": "Other Experience"
    },
    "sp_rating": {
        "novice": "Novice",
        "advanced_beginner": "Advanced Beginner",
        "competent": "Competent",
        "proficient": "Proficient",
        "expert": "Expert"
    },
    "applicants_review": {
        "title": "Applicants Review",
        "no_applicants_to_reivew": "No Applicants To Review",
        "percent_filled": "{{percent}}% Filled"
    },
    "job_cherry_picking": {
        "title": "Job Details",
        "accepted": "Accepted",
        "rejected": "Rejected",
        "reject": "Reject",
        "accept": "Accept",
        "exp": "Exp",
        "age": "Age",
        "language": "Language",
        "nationality": "Nationality",
        "years_of_experience": "{{count}} Yrs",
        "experience": "Experience",
        "height": "Height",
        "height_cm": "{{count}} cm",
        "years_old": "{{count}} years",
        "total_applicants": "Applicants ({{count}})",
        "sort_by": "Sort by:",
        "rating": "Rating",
        "see_more": "see more",
        "confirmation": "Confirmation",
        "you_are_about_to_accept": "You are about to accept <1>{{name}}</1> for the job",
        "you_are_about_to_reject": "You are about to reject <1>{{name}}</1> They will be removed from the list",
        "confirm": "Confirm",
        "cancel": "Cancel",
        "jobID": "Job ID",
        "designation": "Designation",
        "location": "Location",
        "required_quantity": "Required Quantity",
        "fulfillment": "Fulfilment",
        "fulfillmentRatio": "{{percent}}%",
        "start_date": "Start Date",
        "end_date": "End Date",
        "seniority_level": "Seniority Level",
        "congratulation_exclamation": "Congratulations!",
        "you_have_assigned_staff": "You have assigned all required staff",
        "done": "Done",
        "skill_match": "Skill Match",
        "chief_title": "We Have Something For You",
        "chief_subtitle": "Add a Chief OG to your team - it's free!",
        "chief_og": "Chief OG",
        "what_is_chief": "What is a Chief OG?",
        "chief_definition": "A Chief OG is a team leader, carefully selected by us to help manage larger Ogram teams. For example, by resolving disputes and coordinating team members - at no extra cost to you.\nAnd all this is <1>completely free for you.</1>",
        "chief_button_label": "Sounds good",
        "time": "Time",
        "applicant_assigned": "Applicant is assigned!",
        "shortlisted": "Shortlisted"
    },
    "interview": {
        "applicants_finished": "No more answers to review",
        "applicant_id": "ID#{{id}}",
        "next_question": "Next Question",
        "accept": "Accept",
        "assigned": "Assigned",
        "next_applicant": "Next applicant",
        "question_short": "Q{{index}}:",
        "rating_question_short": "Rating Q{{index}}:",
        "recommended_to_hire": "Recommended to Hire",
        "overall_rating": "Overall Rating",
        "video_player_not_supported": "Your browser does not support video player, please try to update it or use another browser.",
        "submitting_rating": "Submitting rating...",
        "rating_applied": "Rating applied!"
    },
    "complete_registration": {
        "title": "Register a Corporate",
        "legal_information": "Legal Information",
        "field_required": "This Field is Required",
        "yes": "yes",
        "no": "no",
        "company_name": "Company Name",
        "trade_name_as_license": "Trade Name as per Trade License",
        "trade_license_number": "Trade License Number",
        "legal_address": "Legal Address",
        "po_box": "PO Box",
        "free_zone": "Free Zone",
        "lpo_required": "LPO Required",
        "region": "Region",
        "vat_certificate": "VAT certificate",
        "upload_vat": "Upload VAT certificate",
        "i_agree_with_terms": "I agree to the <1>Privacy Policy</1>",
        "i_agree_with_company_pp": "I agree to the <1>Privacy Policy for Companies</1>",
        "i_agree_with_company_terms": "I agree to the <1>Terms and Conditions</1>",
        "continue": "Continue",
        "gr": {
            "company_name": "Company's name",
            "address": "Address with Street - Number - City",
            "tax_jurisdiction": "DOY (Tax Jurisdiction)",
            "registry_number": "Company Registry Number",
            "zip_code": "ZIP code",
            "industry": "Industry",
            "industry_retail": "Retails",
            "industry_hotels": "Hotels",
            "industry_brc": "Bar/Restaurant/Cafe"
        },
        "document_selected": "Document Selected",
        "document_submitted": "Document Submitted"
    },
    "change_schedule_options": {
        "all_dates_from_date": "All Days From {{date}}",
        "date_only": "{{date}} Only",
        "every_day_from_date": "Every {{day}} from {{date}}",
        "custom": "Custom"
    },
    "onboarding_complete": {
        "title": "Thank You!",
        "description": "We've received your company details.",
        "done": "Done"
    },
    "sign_sla": {
        "title": "Sign SLA",
        "i_agree": "I Agree"
    },
    "home": {
        "hello_name": "Hello, <1>{{name}}</1>",
        "hello": "Hello",
        "shifts_attendance_review": "Shifts attendance review",
        "orders_to_confirm": "Orders to confirm",
        "rating_requests": "Rating requests",
        "applicant_review": "Applicant review",
        "pending_payments": "Pending Payments",
        "error_failed_to_load_statistics": "Failed to load statistics",
        "error_failed_to_load_home_page": "Failed to load home page",
        "coming_soon": {
            "hello": "Hello",
            "we_are_coming_soon": "We Are Coming Soon",
            "prepare_for_launch": "Hello! We are preparing for our big launch.",
            "on_demand_staff": "On-Demand Staff",
            "find_workers": "Find and manage high-quality workers on one simple, centralized platform.",
            "short_term_contracts": "Short-Term Contracts",
            "post_jobs": "Post temporary or long-term jobs and fill your next shift within 48 hours of signing up.",
            "incredible_savings": "Incredible Savings",
            "save_money": "Pay only for delivered work hours"
        }
    },
    "interview_popup": {
        "title": "Video Interview",
        "description": "Incorporate video interviewing into your recruitment efforts to save your team hours and quicker moving.",
        "create_order_button": "Create Order"
    },
    "sidebar": {
        "company": "Company",
        "error_failed_to_load_sidebar": "Failed to load sidebar. Please try again",
        "create_order": "Create Order",
        "register_corporate": "Register a Corporate",
        "account": "ACCOUNT",
        "manage_locations": "Manage Locations",
        "users": "Users",
        "knowledge_center": "Knowledge Centre",
        "home": "Home",
        "all_jobs": "All Jobs",
        "attendance_review": "Attendance Review",
        "orders": "Orders",
        "applicants_review": "Applicants Review",
        "rating_requests": "Rating requests",
        "invoices": "Invoices",
        "payments": "Payments",
        "payment_methods": "Payment Methods",
        "billing": "BILLING",
        "orders_uppercase": "ORDERS"
    },
    "all_jobs": {
        "title": "Calendar",
        "day": "Day",
        "3_days": "3 Day",
        "week": "Week",
        "month": "Month",
        "percent_filled": "{{count}}% Filled",
        "start": "Start",
        "end": "End",
        "manage_shift": "Manage shift",
        "unsaved_changes": "You have unsaved changes. Are you sure you want to leave this page?",
        "shift_canceled": "Shift has been cancelled",
        "og_checked_in": "The ogrammer has been checked in",
        "og_checked_out": "The ogrammer has been checked out",
        "assigned_to": "Assigned to",
        "no_one": "",
        "no_other": "",
        "empty_slot": "Empty slot",
        "select_OG": "Select OG",
        "cancel": "Cancel",
        "save_changes_count": "Save Changes ({{count}})",
        "update_success": "Successfully updated",
        "accepted": "Accepted",
        "canceled": "Canceled",
        "completed": "Completed",
        "irrelevant": "Irrelevant",
        "pending": "Pending",
        "started": "Started",
        "extension_announcement": "You can now extend orders directly from this page. Click the three dots next to the order and select \"Extend Order.\"",
        "current_week": "Go to current week",
        "filter_location": "Filter Location",
        "filter_designation": "Filter Designation",
        "sps_required": "{{amount}} OGs required",
        "bulk_apply": "Bulk Apply",
        "apply_to_sp_only": "Apply to {{spName}} only"
    },
    "job_shifts": {
        "title": "Job Shifts",
        "change_schedule": "Change schedule",
        "cancel_shift": "Cancel Shift",
        "confirmation_question": "You are about to cancel the job for all Ogrammers, are you sure of that?",
        "i_have_capacity": "I have capacity",
        "select_reason": "Select A Reason",
        "other": "Other",
        "comment_here": "Comment here (Optional)",
        "cancel_job": "Cancel Job",
        "cancel": "Cancel",
        "set_start_time": "please set start time",
        "set_end_time": "please set end time",
        "start_time": "Start Time",
        "end_time": "End Time",
        "apply_schedule": "Apply Schedule",
        "apply_on_days": "Apply on selected days from {{date}}",
        "confirm": "Confirm",
        "pending": "Pending",
        "started": "Started",
        "check_in": "Check in",
        "check_out": "Check out",
        "extend_shift": "Extend Shift",
        "call": "Call",
        "whatsapp": "WhatsApp",
        "remove_from_favourites": "Remove from favourites",
        "add_to_favourites": "Add to favourites",
        "extra_time": "Extra Time",
        "percent_filled": "{{percent}}% Filled",
        "for_the_job": "for the job",
        "cancel_all_following_shifts": "Cancel all following shifts",
        "block_ogrammer": "Block Ogrammer"
    },
    "location_list": {
        "title": "Locations",
        "add_location": "Add Location",
        "location_required": "Location name is required",
        "address_required": "Address is required",
        "address_details_required": "Additional address details is required",
        "region_required": "Region is required",
        "location_name": "Location name",
        "region": "Region",
        "location_address": "Location address",
        "address_details": "Additional address details",
        "upload_location_image": "Upload the location image (Optional)",
        "save": "Save",
        "delete": "Delete",
        "check_in_restrictions_title": "Check-In Restrictions",
        "large_area": "Mall or a Large community",
        "no_restrictions": "Disable geolocation restriction",
        "additional_details": "Additional Details",
        "restrictions_info_modal_text": "Please turn on “<strong>Mall or a Large community</strong>” option if the staff is required to work at the Large area. We'll adjust the radius of check-in and check-out zone.<br/><br/>“<strong>Disable geolocation restriction</strong>” option will remove geo-restrictions in case of remote work or if the job will take place across several locations.",
        "you_have_n_locations": "You have {{locationsCount}} locations"
    },
    "add_location": {
        "title": "Add Location"
    },
    "edit_location": {
        "title": "Edit Location"
    },
    "orders_list": {
        "title": "Orders",
        "empty_list_title": "Start Hiring Today!",
        "empty_list_description": "Looking for part-time staff?\nLet us know your work requirements.",
        "create_order": "Create Order",
        "tab_title_pending": "Pending",
        "tab_title_active": "Active",
        "tab_title_completed": "Completed"
    },
    "order": {
        "order_number": "Order #{{id}}",
        "order_summary": "Order Summary",
        "jobs": "Jobs",
        "cost": "Cost",
        "i_accept_terms": "I accept the <1>Terms of Service</1>",
        "confirm": "Confirm",
        "order_confirmed": "Order Confirmed",
        "thank_you_for_confirming_order": "Thank you for confirming the order.",
        "done": "Done",
        "payment_terms": "Payment terms",
        "complete_company_details": "Complete company details to change payment terms",
        "change_terms": "Change Terms",
        "location": "Location",
        "end_date": "End date",
        "start_date": "Start date",
        "subtotal": "Subtotal",
        "transportations": "Transportations",
        "vat_percent": "VAT ({{count}}%)",
        "estimated_total": "Estimated total",
        "dates": "Dates",
        "days_count": "({{count}} day)",
        "hours": "Hours",
        "hours_amount": "{{hours}} hour",
        "hours_per_day": "({{hoursPerDay}} h/day)",
        "rate": "Rate",
        "hour_rate": "{{currency}} {{hourRate}}/h",
        "transportation_cost": "{{currency}} {{cost}}/day",
        "category_schedule": "{{category}} Schedule",
        "order_summary_days_count": "({{count}} days)",
        "hello_name": "Hello, <1>{{name}}</1>",
        "confirm_order": "We have received new order request from you. In order to proceed, please review and confirm the details below.",
        "pending_rates_review": "Your order request is under review. We will notify you once the rates are assigned.",
        "send_for_review": "Send For Review",
        "under_review": "Under Review",
        "final_amount_will_be_based": "*Final Total amount will be based on the actual attendance",
        "amend_order": "Amend Order",
        "i_accept": "I accept the",
        "terms_of_service": "Terms of Service",
        "select_amend_reason": "Select Amendment Reason",
        "select_reason": "Select reason",
        "submitting": "Submitting...",
        "continue": "Continue",
        "cancel": "Cancel",
        "quotation_download_title": "Download quotation",
        "quotation_download_action_text": "Download",
        "quotation_download_in_progress_text": "Downloading...",
        "lpo_upload_title": "Upload  your signed LPO",
        "lpo_upload_action_text": "Upload",
        "lpo_upload_in_progress_text": "Uploading...",
        "lpo_upload_success_message": "LPO was successfully uploaded",
        "menu_download_quote": "Download a quote",
        "menu_upload_lpo": "Upload LPO",
        "lpo_download_title": "Download LPO",
        "lpo_download_in_progress_text": "Downloading...",
        "menu_duplicate_order": "Duplicate order",
        "draft_order_title": "Draft Order",
        "job_to_duplicate": "Job to duplicate",
        "need_to_review_corporate_details": "We have received new order request from you. In order to proceed, please review and complete company details",
        "corporate_details_are_being_reviewed": "We are reviewing your company details which you sent us",
        "complete_company_details_link": "Complete company details",
        "duplicate_order": "Duplicate order",
        "days": "Days",
        "to_end": "to End",
        "suggest_extension": "Thank you for choosing our services. Extend your order for additional services or products.",
        "extend_your_order": "Extend your order"
    },
    "job_draft_category": {
        "title": "Create New Order",
        "tell_about_job": "Tell us about the job",
        "tell_about_first_job": "Tell us about the first job",
        "find_staff": "Find vetted staff for your every need"
    },
    "job_draft_details": {
        "title": "{{category}} Job",
        "fill_job_details": "Please fill the job details",
        "staff": "Staff",
        "quantity": "Quantity",
        "location": "Location",
        "start_date": "Start date",
        "end_date": "End date",
        "days": "Days",
        "start_time": "Start time",
        "end_time": "End time",
        "requirements": "Specialization [Optional]",
        "job_description": "Job Description",
        "experience": "Experience",
        "dresscode": "Dresscode",
        "additional_preferences": "Additional preferences [Optional]",
        "languages": "Languages",
        "gender": "Gender",
        "additional_documents": "Additional Documents",
        "video_interview": "Video Interview",
        "manual_selection": "Manual Staff Selection",
        "accreditation": "Accreditation Required",
        "submitting": "Submitting...",
        "save": "Submit",
        "finish": "Finish",
        "next": "Next",
        "create_duplicate": "Save",
        "step_n_of_m_steps": "{{objectNameLabel}} {{index}} of {{count}}",
        "work_category_required": "Please choose work category",
        "quantity_required": "Please set how many staff you need",
        "location_required": "Please set the location",
        "schedule_type_required": "Please select your schedule type",
        "same_time_prompt_required": "Please tell us if all days have the same start/end time",
        "start_date_required": "Please set the start date",
        "end_date_required": "Please set the end date or make the job open ended",
        "end_date_cannot_be_before_start_date": "End date cannot be before start date",
        "start_time_required": "Please set the start time",
        "end_time_required": "Please set the end time",
        "select_one_date_at_least": "Please select at least one day",
        "fill_all_selected_dates": "Please fill in all inputs for selected dates",
        "job_cant_start_early": "Job cannot start earlier than in 24 hours",
        "must_include_day_of_start_date": "Day of start date must be included",
        "must_include_day_of_end_date": "Day of end date must be included",
        "description_required": "Please set the description",
        "description_too_long": "Description is too long, please make it shorter",
        "experience_required": "Please choose seniority level",
        "dresscode_required": "Please choose dresscode",
        "languages_required": "Select languages or remove the filter",
        "documents_required": "Select documents or remove the filter",
        "interview_required": "Select questions or remove the filter",
        "manual_selection_required": "Select manual selection option or remove the filter",
        "accreditation_required": "Select accreditation option or remove the filter",
        "staff_amount_title": "How many staff do you need?",
        "staff_amount_subtitle": "Select the quantity you need or just type it",
        "location_title": "Where do you need the staff?",
        "location_subtitle": "Select the location or venue where you need staff.",
        "location_add_new": "Add New Location",
        "location_add": "Add Location",
        "dates_title": "When are the staff needed?",
        "dates_subtitle": "Select the date/dates when you need them.",
        "dates_open_ended": "Open-ended (no end date)",
        "schedule_title": "Will they work the same days every week?",
        "schedule_subtitle": "For example, Monday to Friday every week.",
        "schedule_i_have_same_weekly": "I have the same shedule every week",
        "schedule_schedule_weekly": "Schedule on a Weekly basis",
        "schedule_i_need_schedule_daily": "I need to shedule on a daily basis",
        "schedule_schedule_daily": "Schedule on a Daily basis",
        "time_title": "What is the hourly schedule for the job?",
        "time_subtitle": "Select which days you want staff to work",
        "time_select_dates_schedule": "Please, select dates and schedule type first",
        "time_days_selected": "{{count}} days selected",
        "time_dates_selected": "{{count}} dates selected",
        "requirements_title": "Do the workers need a specific specialization?",
        "requirements_subtitle": "Select the specialization that best matches your requirements.",
        "requirements_add_new": "Add New Requirement",
        "requirements_create": "Create Work Requirement",
        "requirement_requirement": "Requirement",
        "requirement_add_requirement": "Add Requirement",
        "description_title": "What will they do?",
        "description_subtitle": "Add a job description for the staff (Describe the duties and responsibilities expected of the staff)",
        "description_placeholder": "Describe job details",
        "experience_title": "How experienced do you need the staff to be?",
        "experience_subtitle": "Select the level of experience you want the staff to have",
        "experience_student": "Student",
        "experience_beginner": "Beginner",
        "experience_junior": "Junior",
        "experience_middle": "Mid-Senior",
        "experience_senior": "Senior",
        "experience_hint": "*The higher the seniority of the staff, the higher the hourly rates.",
        "experience_years": "years",
        "uniform_title": "Do you have a dresscode preference?",
        "uniform_subtitle": "Tell us if you need formal or casual attire",
        "uniform_dresscode": "Dresscode",
        "preferences_title": "Do you have any additional preferences?",
        "preferences_subtitle": "Decide on your additional preferences.",
        "preferences_languages": "Language",
        "preferences_gender": "Gender",
        "preferences_documents": "Additional Documents",
        "preferences_interview": "Video Interview",
        "preferences_manual_staff_selection": "Manual Staff Selection",
        "preferences_accreditation_required": "Accreditation Required",
        "preferences_accommodation_offered": "Accommodation offered",
        "accommodation_required": "Accommodation Required",
        "accommodation_yes": "Yes",
        "accommodation_no": "No",
        "boarding_required": "Boarding Required",
        "transport_accessibility_required": "Transport Accessibility required",
        "preferences_boarding_offered": "Boarding offered",
        "accommodation_title": "Do you offer boarding for this role?",
        "do_you_offer_accommodation": "Do you offer accommodation for this role?",
        "no_boarding": "No boarding offered",
        "half_boarding": "Half-board",
        "full_boarding": "Full-board",
        "languages_title": "What's your preference for language?",
        "languages_subtitle": "Pick specific languages the staff should speak",
        "gender_title": "What's your preference for gender?",
        "gender_subtitle": "Please select gender preference, if any",
        "gender_male": "Male",
        "gender_female": "Female",
        "gender_no_preference": "No",
        "documents_title": "What are your additional documentary requirements?",
        "documents_subtitle": "Select required additonal document types",
        "manual_selectinon_title": "Would you like us to auto-select the best Ogrammers for the job?",
        "manual_selectinon_subtitle": "If you choose \"No\", you will have to manually select Ogrammers and will not benefit from our Smart Staff Selection algorithm",
        "manual_selectinon_yes": "Yes",
        "manual_selectinon_no": "No",
        "accreditation_title": "Do the staff require accreditation to enter the venue or event?",
        "accreditation_subtitle": "Please note that accreditation will affect our fulfilment time",
        "accreditation_yes": "Yes",
        "accreditation_no": "No",
        "poc_title": "Who will be handling the attendance?",
        "poc_subtitle": "Select a person will be the main point of contact for the job",
        "point_of_contact": "Point of contact",
        "add_poc": "Add new POC",
        "select_location_first": "Please, select job's location first",
        "poc_required": "Please select contact",
        "start_date_must_be_at_least_today": "Start date must start at least today",
        "end_date_must_be_at_least_today": "End date must start at least today",
        "greece_launch_info": "Please note that orders can only be placed starting from June 1st. We process your orders within 72 hours. Any orders submitted before this date will not be processed. Thank you for your understanding",
        "select_document": "Select Document",
        "junior": "Junior",
        "midSenior": "Mid-Senior",
        "senior": "Senior",
        "student": "Student",
        "beginner": "Beginner",
        "interview_title": "What are the key topics you would like to cover with the applicants?",
        "interview_subtitle": "OG will submit video recordings of their question answers",
        "interview_new_question": "Add New Question?",
        "interview_question_form_title": "Add New Question",
        "interview_question_form_placeholder": "Type description here",
        "interview_question_form_submit": "Submit",
        "interview_question_created": "Question has been created!",
        "start_and_end_date_must_be_selected": "Start date {{startDate}} and End date {{endDate}} must be selected",
        "what_are_the_shifts_timings": "Tell us about the time schedule for this job:",
        "what_are_job_timings": "What are the job timings?",
        "select_same_time": "Select which time you want staff to work every day",
        "start_and_ends_are_same": "Shifts will Start and End at the same time every day",
        "time_vary": "Shift times vary day to day",
        "less_than_24h_warning": "The job starts in less than 24 hours and we may not have enough time to fulfill it.",
        "job_was_prefilled": "The form was pre-filled based on the last job data",
        "invited_quantity_more_than_required_quantity": "You have selected more OGs than required in the job. In this case, the first who accepts will be assigned to the job"
    },
    "order_requested": {
        "title": "Thank you!",
        "subtitle": "Our team has received your order and we will get back to you soon!",
        "done": "Done",
        "complete_registration": "Complete Registration",
        "later": "Later"
    },
    "order_draft": {
        "title": "Create New Order",
        "jobs": "Jobs",
        "confirm_delete_job": "Are you sure you want to delete {{name}} job?",
        "add_another_job": "+ Add Another Job",
        "add_another_job_with_plus": "Add Another Job",
        "add_first_job": "Add First Job",
        "continue": "Continue",
        "quantity": "Quantity",
        "days": "Days",
        "days_amount": "{{count}} day",
        "location": "Location",
        "what_to_copy": "Which preferences of the {{category}} job would you like to copy?",
        "select_preferences": "Please select preferrences which need to be copied",
        "schedule": "Schedule",
        "requirements": "Requirements",
        "job_description": "Job Description",
        "seniority_level": "Seniority Level",
        "dresscode": "Dresscode",
        "language": "Language",
        "gender": "Gender",
        "documents": "Additional Documents",
        "manual_selection": "Manual Staff Selection",
        "accreditation_requried": "Accreditation Required",
        "accommodation_offered": "Accommodation offered",
        "interview_required": "Video Interview",
        "boarding_offered": "Boarding offered",
        "duplicate_job": "Duplicate Job",
        "blank_job": "Blank Job",
        "workers": "workers",
        "jobs_count": "{{count}} Job(s)",
        "public_transport_accessibility": "Public Transport Accessibility",
        "public_transport_accessibility_accessible": "Yes, it's near a bus / metro stop",
        "public_transport_accessibility_not_accessible": "No, it's not easily accessible by public transport",
        "is_location_accessible_by_transport": "Is this location accessible by public transport?",
        "tell_if_location_is_reachable": "Tell us if your location can be reached by bus or metro.",
        "pricing": {
            "best_price_1": "Best price guarantee",
            "best_price_2": "Our pricing model is dynamically structured, taking into account current market demand.",
            "best_price_3": "This approach allows us to continually offer you the best value and services you've come to expect from us. Reach out to us at any time for more information.",
            "best_price_4": "Request a call from the sales team",
            "estimated_total": "Estimated total*",
            "type": "Type",
            "hours": "Hours",
            "hourly_rate": "Hourly rate"
        },
        "callback_request_sent": "Your request has been successfully sent",
        "end_date": "End Date",
        "start_date": "Start Date",
        "updated": "Updated",
        "n_hours": "{{hours}} hours",
        "edit_job": "Edit Job"
    },
    "order_extension": {
        "how_to_extend": "How do you want to extend the dates for the jobs?",
        "you_can_extend_order": "You have ability to extend dates for the jobs",
        "same_extension_date": "New end date is same for all jobs",
        "different_extension_dates": "Extend the jobs for different periods",
        "for_how_long_extend": "For how long do you want to extend your jobs?",
        "do_not_extend": "Don't Extend",
        "select_date": "Select Date",
        "select_start_date": "Select Start Date",
        "select_end_date": "Select End Date",
        "extend_order": "Extend order",
        "schedule_description": "The extend days you need your staff to work",
        "edit": "Edit",
        "done": "Done",
        "full_schedule": "Full Schedule",
        "current_end_date": "Current End Date:",
        "select_sps": "Select Ogrammers",
        "select_sps_to_extend": "Select Ogrammers you want to extend"
    },
    "order_confirmed": {
        "title": "Order Confirmed",
        "description": "Thank you for confirming the order.",
        "done": "Done"
    },
    "attendance_manager_form": {
        "first_name": "First name",
        "last_name": "Last name",
        "phone": "Phone",
        "email": "Email",
        "job_role": "Job Role",
        "save": "Save",
        "edit_attendance_manager": "Edit Attendance Manager",
        "add_attendance_manager": "Add Attendance Manager"
    },
    "attendance_manager_list": {
        "title": "Attendance Managers",
        "add_manager": "Add Manager",
        "edit": "Edit"
    },
    "company_form": {
        "title": "Company Details",
        "saved": "Saved",
        "company_name": "Company Name",
        "company_name_as_in_license": "Company Name as mentioned in the Trade License",
        "address": "Address",
        "po_box": "POBox",
        "vat_certificate": "Vat Certificate",
        "trade_license": "Trade License",
        "finance_first_name": "Finance First Name",
        "finance_last_name": "Finance Last Name",
        "finance_phone": "Finance Phone",
        "finance_email": "Finance Email",
        "extension": "Extension",
        "save": "Save"
    },
    "invoice": {
        "invoice_#": "Invoice #{{index}}",
        "export_pdf": "Export (PDF)",
        "exporting": "Exporting...",
        "report_issue": "Report an issue",
        "details": "Details",
        "invoice_number": "Invoice number",
        "date": "Date",
        "due_date": "Due Date",
        "status": "Status",
        "pending": "Pending",
        "paid": "Paid",
        "costs": "Costs",
        "subtotal": "Subtotal",
        "transportation": "Transportation",
        "vat_percent": "VAT ({{count}}%)",
        "total": "Total",
        "breakdown": "Breakdown",
        "pay_now": "Pay Now",
        "total_shifts": "Total Shifts",
        "total_hours": "Total Hours",
        "total_ogs": "Total OG's",
        "shifts": "Shifts",
        "hours": "Hours",
        "amount": "Amount",
        "report_issue_modal_title": "Report an issue",
        "report_issue_message_required": "Message is required",
        "report_issue_message_placeholder": "Please describe your issue",
        "report_issue_submit": "Submit",
        "report_issue_cancel": "Cancel",
        "issue_was_reported": "Your issue was reported",
        "issue_is_submitting": "Loading..."
    },
    "invoice_card": {
        "invoice_#": "No #{{index}}",
        "due_days_ago": "Due {{count}} days ago",
        "due_today": "Due today",
        "due_tomorrow": "Due tomorrow",
        "due_days_ahead": "Due {{count}} days ahead"
    },
    "invoice_ogrammer": {
        "og_details": "OG details",
        "job": "Job",
        "location": "Location",
        "shifts": "Shifts",
        "hours": "Hours",
        "amount_due": "Amount Due",
        "shift_details": "Shifts details",
        "day": "Day",
        "approved_by": "Approved By"
    },
    "profile_form": {
        "title": "Edit Profile",
        "first_name_required": "first name is required",
        "last_name_required": "last name is required",
        "phone_required": "phone is required",
        "email_required": "email is required",
        "upload_photo": "Upload Photo",
        "private": "Private",
        "corporate": "Corporate",
        "register_corporate": "Register a Corporate",
        "first_name": "First name",
        "last_name": "Last Name",
        "phone": "Phone",
        "email": "Email",
        "save": "Save",
        "cancel": "Cancel",
        "sign_out": "Sign out",
        "agree_to_emails": "I agree to receive marketing notifications from Ogram"
    },
    "rating_request_list": {
        "title": "Rating Requests",
        "empty_list": "No pending requests"
    },
    "rating_request_panel": {
        "title": "Rating Requests",
        "action": "Rate Now"
    },
    "job_rating": {
        "title": "Job Rating",
        "how_was_experience_question": "How was your overall experience?",
        "please_rate_team": "Please rate the entire team",
        "tell_us_about_ogs": "Tell us more about Ogrammers",
        "tell_us_which_stood_out": "Tell us which ones stood out, and which ones  can work on improvements",
        "confirm": "Confirm",
        "comment": "Comment",
        "provide_overall_rating": "Please provide an overall rating",
        "provide_og_rating": "Please provide rating for the ogrammer",
        "provide_ogrammers_rating": "Please provide rating for ogrammers",
        "thank_you_for_submitting": "Thank you for submitting the rating!"
    },
    "shifts_attendance_list": {
        "title": "Attendance Review",
        "empty_list": "No pending attendance reports",
        "review_now": "Review Now"
    },
    "shifts_attendance_details": {
        "attended_question": "Attended?",
        "attended": "Attended",
        "not_attended": "Not Attended",
        "did_not": "Didn't",
        "confirm_amount": "Confirm ({{count}})"
    },
    "shift_attendance_common": {
        "are_you_sure_og_worked_n_hours": "Are you sure that {{name}} worked for {{hours}} hours? It's {{difference}} hours less than what was booked.",
        "no_show": "No Show",
        "limited_time_to_confirm": "Limited time to confirm",
        "you_have_n_to_review": "You have {{time}} to review the attendance for a particular day. If you don't let us know within {{time}}, we will consider any staff who checked-in as attendance confirmed.",
        "i_understand": "I Understand",
        "n_minutes": "{{count}} minutes",
        "n_hours": "{{hours}} hours",
        "select_reason": "Select A Reason",
        "update": "Update",
        "cancel": "Cancel",
        "hours_left": "{{count}} h Left",
        "check_in": "Check-In",
        "check_out": "Check-Out",
        "update_hours": "Update {{count}} hours",
        "add_hours": "Add {{count}} hours",
        "shift_confirmed": "Shift Confirmed",
        "thanks_for_confirming": "Thank you for confirming the attendance of Ogrammers.",
        "done": "Done",
        "day_off": "Day Off",
        "total_hours": "Total Hours",
        "shift_duration_too_long": "Shift duration shouldn't exceed 12 hours"
    },
    "user_management": {
        "users": "Users",
        "add_new_user": "Add New User",
        "invite_user": "Invite User",
        "confirm_deletion_question": "Are you sure you want to delete this contact?",
        "enter_first_name": "please enter first name",
        "enter_last_name": "please enter last name",
        "enter_phone": "please enter phone",
        "enter_email": "please enter email",
        "choose_role": "please choose a role",
        "choose_location": "please choose a location",
        "first_name": "First Name",
        "last_name": "Last Name",
        "phone": "Phone",
        "email": "Email",
        "invite_user_exclamation": "Invite User Now!",
        "job_role": "Job role",
        "location": "Location",
        "save": "Save",
        "delete": "Delete",
        "cancel": "Cancel",
        "you_have_n_users": "You have {{count}} users",
        "add_user": "Add New User",
        "or": "Or",
        "invite_existing_user": "Invite an existing user",
        "invitation_confirmed": "Invitation confirmed!",
        "invitation_alert_text": "Invitation has been sent successfully. The user will be added to your company after accepting the invitation.",
        "users_tab_active_users": "Users",
        "users_tab_invitations": "Invitations",
        "role_admin": "Admin",
        "role_owner": "Owner",
        "role_manager": "Manager",
        "role_finance": "Finance"
    },
    "onboarding": {
        "knowledge_center": "Knowledge Centre",
        "create_password": "Create Password",
        "register_corporate": "Register a Corporate",
        "click_here_to_close": "Click here to close"
    },
    "lead_widget": {
        "looking_for_staff": "Looking for \npart-time staff?",
        "hire_now": "Hire Now"
    },
    "uncompleted_actions_carousel": {
        "looking_for_staff": "Looking for part-time\nstaff?",
        "hire_now": "Hire Now",
        "edit_company": "Provide company details to\nunlock flexible payments",
        "start_edit_company": "Start Now",
        "add_poc": "Assign roles to your team\nmembers",
        "start_add_poc": "Start Now",
        "dismiss_add_poc": "Dismiss",
        "error_failed_to_load": "Failed to load carousel",
        "connect": "Connect",
        "connect_your_bank_account": "Connect your account with your bank",
        "become_affiliate": "Invite your staff to join Ogram"
    },
    "not_authorized_error": {
        "title": "Access Denied",
        "description": "You don't have permission to access the requested page...",
        "sign_in": "Sign In",
        "toast_title": "Your session has expired. Please log in again to continue."
    },
    "page_not_found": {
        "title": "Page Not Found!",
        "description": "The page you are looking for doesn't seem to exist...",
        "go_back": "Go Back"
    },
    "permission_error": {
        "title": "You don't have permission to view this page",
        "back": "Back"
    },
    "too_many_attempts": {
        "title": "Too Many Attempts!",
        "description": "Please try again a little bit later.",
        "ok": "Ok"
    },
    "notifications": {
        "title": "Notifications",
        "load_more": "Load more",
        "no_more_notifications": "No more notifications",
        "no_notifications_yet": "No notifications yet",
        "error_failed_to_load_notifications": "Failed to load notifications. Please try again"
    },
    "home_onboarding": {
        "title": "Home",
        "intro_title": "Welcome to Ogram!",
        "intro_description": "The smart platform that businesses and individuals use to efficiently fulfill their part-time hiring needs.",
        "intro_footer": "Whenever you use a feature for the first time, a similar message will pop up to guide you.",
        "hire_title": "Hire part-time staff",
        "hire": "The “Hire Now” feature allows you to book part-time staff. This is how you tell us what, where, and when you need the staff.",
        "attendance_title": "Attendance review counter",
        "attendance": "This is where you review and sign off the digital attendance reports. The number in the box indicates the pending reports to review.",
        "orders_title": "Orders confirmation counter",
        "orders": "Whenever a staff-order requires manual pricing, we need your final confirmation before we can deploy the staff. Here's where you can access all pending orders.",
        "ratings_title": "Rating requests counter",
        "ratings": "Your feedback is very important to us and is leveraged by cutting-edge technology that continuously elevates the service. Here's where you can rate the service.",
        "applicants_review_title": "Jobs applicant counter",
        "applicants_review": "The platform selects and deploys staff automatically. You can also select the staff based on their experience. Here is where you review and select candidates.",
        "outro": "Ready to get started?\nYou can alway access the “Knowledge Centre” from the home screen menu or by clicking on “?” whenever you need guidance."
    },
    "all_jobs_onboarding": {
        "title": "All Jobs",
        "jobs_list_title": "Jobs list",
        "jobs_list": "“All Jobs” is a list of your booked jobs split by days. By clicking on a job, you are able to see the list of assigned Ogrammers.",
        "job_details_title": "Job details",
        "job_details": "On a job page, you are able to clock in staff, extend or cancel shifts. Adding an Ogrammer to favourites ensures their preferred selection next time.",
        "change_schedule_title": "Change schedule",
        "change_schedule": "You are able to adjust your shifts schedule. Keeping the schedule up-to-date is very important, so Ogrammers can use their apps to clock in.",
        "bulk_schedule_change_title": "Bulk schedule change",
        "bulk_schedule_change": "The schedule may be changed for a selected day or for multiple days at a time. Please keep in mind that shift duration can't be reduced.",
        "cancel_shift_title": "Cancel a shift",
        "cancel_shift": "You can cancel an individual Ogrammer's shift; as well as block them from applying for all future shifts."
    },
    "attendance_review_onboarding": {
        "title": "Attendance Review",
        "jobs_pending_review_title": "Jobs pending application",
        "jobs_pending_review": "The \"Attendance Review\" section allows you to adjust and confirm Ogrammers' attendances on a daily basis. You will be billed accordingly to the time logged.",
        "attendance_details_title": "Attendance details",
        "attendance_details": "A job page shows a list of assigned Ogrammers' respective times logged. You can correct the time by clicking on it.",
        "attendance_status_title": "Attendance status change",
        "attendance_status": "You can also correct an Ogrammer's attendance status if they were mistakenly marked as having not attended.",
        "attendance_time_title": "Attendance time change",
        "attendance_time": "You will be prompted to enter the correct attendance timing, which will be set to the scheduled hours by default.",
        "not_attended_reason_title": "Not attended reason change",
        "not_attended_reason": "If an Ogrammer has not attended, you are also able to change the reason; either it was a Day Off or Sick Leave.",
        "confirmation_time_title": "Confirmation of time",
        "confirmation_time": "It's extremely important to confirm attendances regularly as they will be auto-confirmed within 72 hours. Failing to do so will see you billed accordingly."
    },
    "order_confirmation_onboarding": {
        "title": "Orders",
        "pending_confirmation_title": "Orders pending confirmation",
        "pending_confirmation": "The \"Orders\" section contains a list of your pre-booked orders that are pending your review and confirmation.",
        "order_confirmation_title": "Order confirmation",
        "order_confirmation": "On a pending confirmation Order page, please review the order details and final rates carefully. Staff will be deployed once the order has been confirmed.",
        "order_amend_title": "Amend Order",
        "order_amend": "If your requirements change or you have budget concerns, please feel free to amend the order."
    },
    "order_creation_onboarding": {
        "title": "Order Creation",
        "select_designation_title": "Select designation",
        "select_designation": "The order creation process starts with selecting a designation for the first job. Additional designations can be selected once the first job is created.",
        "job_details_title": "Job details",
        "job_details": "After selecting a designation, you can fill in the job details by starting with the quantity needed.",
        "job_duration_title": "Job duration",
        "job_duration": "If the job is recurring and has no specific end date, you can swipe the toggle which will remove the end date and keep the job open ended.",
        "scheduling_options_title": "Scheduling options",
        "scheduling_options": "If your job schedule repeats every week, choose the 'Weekly' option and enter the timings for each day of the week. Otherwise, you can schedule on a daily basis",
        "job_requirements_title": "Job requirements",
        "job_requirements": "You can select requirements from a predefined list or add specific skills/duties required for the job.",
        "shifts_breakdown_title": "Shifts breakdown",
        "shifts_breakdown": "Once you specified the first job details a draft order has been created. You can add more jobs/designations to the order by pressing on “Add Another Job”.",
        "duplicate_job_title": "Duplicate job",
        "duplicate_job": "If you want to create another job with the same or similar requirements, you can click on the job copy button. You will be able to specify which details to copy.",
        "send_for_review_title": "Sending for review",
        "send_for_review": "By pressing the 'Send for Review' button, you will send the order to our sales team for review. We will price your order then notify you to get final confirmation."
    },
    "applicants_review_onboarding": {
        "title": "Applicants Review",
        "pending_applicant_review_title": "Jobs pending applicants review",
        "pending_applicant_review": "The \"Applicants Review\" section contains a list of jobs with applicants pending review. Once a job has been fulfilled it will disappear from the list.",
        "applicant_list_title": "Job applicants list",
        "applicant_list": "A Job page shows a list of Ogrammers who have applied. You can accept or reject them straight away or review their profile details further.",
        "applicant_details_title": "Applicant details",
        "applicant_details": "An Ogrammer's details page displays their Rating, Top Skills, Seniority Level, BIO, Work Experience and Education."
    },
    "locations_onboarding": {
        "title": "Locations",
        "location_list_title": "Locations list",
        "location_list": "The \"Locations\" section contains a list of your outlet's locations.",
        "location_details_title": "Location details",
        "location_details": "Please make sure that your location on the map is accurate. Ogrammers will not be able to check in outside of the selected point."
    },
    "users_onboarding": {
        "title": "Users",
        "user_list_title": "Users list",
        "user_list": "The \"Users\" section allows you to control who has access to your company. You can create new users or invite existing ones.",
        "user_details_title": "User details",
        "user_details": "You are able to assign one of the following roles: Admin, Attendance Manager, Finance. Roles can be limited by location."
    },
    "rating_requests_onboarding": {
        "title": "Rating Requests",
        "pending_feedback_title": "Jobs pending feedback",
        "pending_feedback": "The \"Rating Requests\" section contains a list of jobs requiring your feedback. After a job has been completed, we would like you to rate your experience.",
        "rating_details_title": "Job rating details",
        "rating_details": "You can rate your overall job experience, as well as rate individual Ogrammers. You are free to give compliments, highlight issues and leave comments."
    },
    "invoices_onboarding": {
        "title": "Invoices",
        "invoice_list_title": "Invoices list",
        "invoice_list": "“Invoices” contains a list of your current and past invoices. If any invoice is in a “Pending” state it means that we are expecting a payment from you.",
        "invoice_breakdown_title": "Invoice breakdown",
        "invoice_breakdown": "An order page contains a summary plus a jobs breakdown. By expanding jobs, you will be able to find a shift's summary split by Ogrammers.",
        "shifts_breakdown_title": "Invoice shifts breakdown",
        "shifts_breakdown": "By clicking on an Ogrammer, you are able to see how many hours on each day this Ogrammer worked.",
        "export_invoice_title": "Export invoice",
        "export_invoice": "From the Invoice Menu, you can download a PDF breakdown of an invoice; as well as report any issues with an invoice."
    },
    "duplicate_order": {
        "choose_jobs_main_title": "First, select your desired jobs",
        "choose_jobs_secondary_title": "choose and select the jobs that you need to extend",
        "choose_jobs_confirm": "Confirm",
        "duplicate_job_subtitle": "Please review the job details",
        "duplicate_order": "Duplicate order"
    },
    "payment": {
        "payment": "Payment",
        "card_payment": "Checkout",
        "was_successful": "Thank you, your payment has been successful.",
        "email_was_sent_to": "A confirmation email has sent to ",
        "can_go_to_payments_list_page": "You can go to payments page and check its status there",
        "transaction_id": "Transaction ID",
        "date": "Date",
        "payment_method": "Payment method",
        "amount": "Amount",
        "status": "Status",
        "go_to_payments_list_page": "Go to Payments",
        "status_unpaid": "Unpaid",
        "status_processing": "Processing",
        "status_paid": "Paid",
        "status_cancelled": "Cancelled",
        "status_refunded": "Refunded",
        "payment_method_card_title": "Card",
        "payment_method_card_wide_title": "Card payment",
        "payment_method_card_description": "New Credit/Debit Card",
        "payment_method_lean_description": "Instant Bank Transfer",
        "processing_running": "Processing...",
        "save_card_for_future": "Save this card for future transactions",
        "all_transactions_are_secured": "All transactions are secure and encrypted",
        "details": "Details",
        "payment_number": "Payment number",
        "due_date": "Due date",
        "invoices": "Invoices",
        "total_payment": "Total payment ",
        "pay_now": "Pay Now",
        "authorize": "Authorize Now",
        "pending": "Pending",
        "processing": "Processing",
        "completed": "Completed",
        "invoice": "Invoice",
        "payment_details_title": "Payment #{{paymentId}}",
        "payments": "Payments",
        "payment_methods": "Payment methods",
        "select_payment_method": "Select the payment method you want to use",
        "continue": "Continue",
        "pay": "Pay",
        "payment_completed": "Payment completed",
        "payment_processing": "Payment is processing",
        "updating_status": "Updating status...",
        "empty_pending_list": "No pending payments",
        "empty_processing_list": "No processing payments",
        "empty_completed_list": "No completed payments",
        "back_to_order": "Back to order",
        "overdue_balance": "Overdue balance",
        "payment_method_wire_transfer_title": "Bank Transfer",
        "payment_method_wire_transfer_description": "Bank Transfer",
        "text_copied": "Text copied to clipboard",
        "checkout": "Checkout",
        "wire_transfer_details": "Wire transfer details",
        "wire_transfer_amount": "Amount",
        "wire_transfer_bank_account_name": "Account name",
        "wire_transfer_bank_account_bank_name": "Bank name",
        "wire_transfer_bank_account_number": "Account number",
        "wire_transfer_bank_account_iban": "IBAN",
        "wire_transfer_bank_account_bank_address": "Bank address",
        "wire_transfer_upload_alert_text": "Please upload the copy of your bank proof of payment in receipt",
        "next": "Next",
        "wire_transfer_has_been_canceled": "Wire transfer has been canceled",
        "wire_transfer_slip_was_uploaded": "The slip was uploaded",
        "please_upload_slip": "Please upload Bank Slip",
        "bank_slip_info_in_alert": "The bank slip must clearly show the payment amount and Bank stamp",
        "payment_slip": "Payment slip",
        "upload_payment_slip": "Upload Payment slip",
        "document_selected": "Document Selected",
        "you_can_upload_slip_now_or_later": "You can upload a slip now or later using button above. We’ll remind you to upload a slip until you upload it.",
        "you_can_select_another_payment_method": "If you wish to select another payment method, please cancel the Wire Transfer using this link.",
        "wire_transfer_cancel_button": "Cancel Wire transfer and select another payment method",
        "wire_transfer_remind_later_button": "Remind me later",
        "wire_transfer_i_have_sent_money": "I've sent money",
        "wire_transfer_slip_uploading": "Loading...",
        "wire_transfer_slip_under_review": "Slip is under review",
        "wire_transfer_out_team_is_checking_slip": "Our team will check the slip soon.",
        "download_slip": "Download Slip",
        "card_payment_cancel_button": "Cancel Card payment and select another payment method",
        "card_payment_has_been_canceled": "Card payment has been canceled",
        "error_failed_to_load_panel": "Failed to load payment panel",
        "add_account": "Add Account",
        "learn_more_about": "Learn More About",
        "lean_payment": "Lean Payment",
        "lean_modal_title_1": "Why use Instant Bank Transfer?",
        "lean_modal_title_2": "Move money instantly with the security of your bank",
        "lean_modal_title_3": "This payment method allows you to initiate an instant bank transfer from your business banking account. You only need to connect your account once and then can use it for any future payments.",
        "lean_modal_action_1": "Connect your bank account",
        "lean_modal_action_2": "Pay instantly",
        "lean_modal_title_4": "Your payments will always be secured by multi-factor authentication, the same way as if you'd use your bank account.",
        "payment_awaiting_authorization": "Payment is awaiting authorization",
        "will_be_paid_after_authorize": "Your payout will be paid after you authorize it.",
        "loading": "Loading...",
        "connect_lean_account": "Connect Lean Account",
        "successfully_connected_bank": "Successfully connected bank account",
        "payment_has_been_queued": "Payment has been queued",
        "successfully_paid": "Payment has been successfully paid",
        "cannot_pay": "Payment Failed",
        "lean": {
            "setup_status_active": "active",
            "setup_status_pending": "pending",
            "setup_status_not_connected": "not connected",
            "account_is_being_connected": "Your account is being connected now. Please choose another payment method or try again later"
        }
    },
    "account_statement": {
        "recent_activities": "Recent Activities",
        "account_statement": "Account Statement",
        "currency": "AED",
        "from": "From",
        "to": "to",
        "starts_from": "Starts from",
        "downloading": "Downloading...",
        "download": "Download",
        "invoice": "Invoice",
        "credit_note": "Credit Note",
        "empty_list": "No data for chosen period"
    },
    "calendar": {
        "month_january": "January",
        "month_february": "February",
        "month_march": "March",
        "month_april": "April",
        "month_may": "May",
        "month_june": "June",
        "month_july": "July",
        "month_august": "August",
        "month_september": "September",
        "month_october": "October",
        "month_november": "November",
        "month_december": "December",
        "month": "Month",
        "year": "Year",
        "cancel": "Cancel",
        "set": "Set",
        "clear": "Clear",
        "all_locations": "All Locations"
    },
    "payment_item": {
        "status_draft": "Draft",
        "status_paid": "Paid",
        "status_unpaid": "Pending",
        "status_processed": "Processed",
        "status_voided": "Voided"
    },
    "saved_payment_methods": {
        "method_has_been_added": "Payment method has been added successfully",
        "save_card": "Save Card",
        "saving_progress": "Saving...",
        "remove_payment_method": "Remove payment method",
        "method_has_been_deleted": "Payment method was deleted successfully",
        "payment_methods": "Payment Methods",
        "no_payment_methods_added": "No payment methods added yet",
        "add_new_card": "Add a new card"
    },
    "error_boundary": {
        "default": {
            "server_error": "Internal Server Error",
            "permission_error": "Permission Denied",
            "client_error": "Client Error",
            "not_found_error": "Not Found",
            "not_authorized_error": "Not Authorized",
            "connection_error": "Connection error. Check your internet connection",
            "too_many_requests_error": "Too Many Requests. Try again later",
            "default_error": "Something Went Wrong. Please try again",
            "try_again": "Try Again"
        },
        "payment": {
            "cannot_fetch_overdue_server_error": "Couldn't fetch overdue payments data"
        }
    },
    "pending_orders_panel": {
        "pending_confirmation": "Pending confirmation",
        "n_orders": "{{count}} order(s)",
        "confirm_now": "Confirm now"
    },
    "step_indicator": {
        "step_of": "Step {{currentStepNumber}} of {{stepsCount}}"
    },
    "page_stepper": {
        "next": "Next",
        "previous": "Previous",
        "finish": "Finish",
        "of": "of"
    },
    "order_status": {
        "draft": "Draft",
        "pending_confirmation": "Pending Confirmation",
        "under_review": "Under Review",
        "started": "Started",
        "upcoming": "Upcoming"
    },
    "toggle": {
        "yes_short": "Y",
        "no_short": "N"
    },
    "time": {
        "mon": "Mon",
        "tue": "Tue",
        "wed": "Wed",
        "thu": "Thu",
        "fri": "Fri",
        "sat": "Sat",
        "sun": "Sun"
    },
    "time_picker": {
        "hour": "hour",
        "minute": "minute",
        "cancel": "cancel",
        "set": "set"
    },
    "become_affiliate": {
        "modal_title": "Become an Affiliate",
        "modal_subtitle": "Invite your staff to join Ogram and earn extra money!",
        "accept_tc": "Terms and Conditions"
    },
    "common_affiliate": {
        "join_ogram": "Join Ogram with my special link",
        "my_referrals": "My Referrals",
        "send_invitation": "Send Invitation",
        "share_link": "Invite your team to Ogram App and start earning!",
        "copy_and_send_link": "Or copy and send the link below",
        "tc_1": "These terms aren’t directly involved with referral programs, but they’re closely related enough for us to include here (several define other distinct categories of referral marketing)",
        "tc_2": "Defining these terms helps alleviate some of the confusion about what is and isn’t a referral program.",
        "section_title": "AFFILIATE",
        "dashboard": "Dashboard",
        "referrals": "Referrals",
        "earnings": "Earnings",
        "designation": "Designation",
        "payout": "Payout",
        "due_date": "Due Date",
        "join_date": "Join Date",
        "this_month": "This Month",
        "invite_more_staff": "Invite more staff",
        "margin_split": "Margin Split",
        "invite_your_staff": "Invite Your Staff",
        "invite_staff_more_details": "Invite your co-workers to join Ogram to earn extra money!",
        "invite_now": "Invite Now",
        "earning_history": "Earnings History",
        "total_earnings": "Total Earnings",
        "status_profile_created": "Profile Created",
        "status_activated": "Activated",
        "status_booked_shifts": "Booked Shifts",
        "status_shifts_done": "Shifts Done",
        "status_unknown": "Unknown",
        "referrals_list": "Referrals List"
    },
    "affiliate_instructions": {
        "how_it_works": "How it works",
        "instruction_1_title": "Send an Invitation",
        "instruction_1_subtitle": "Just share your link",
        "instruction_2_title": "They join",
        "instruction_2_subtitle": "And start completing paid shifts",
        "instruction_3_title": "You Start Earning!",
        "instruction_3_subtitle": "Get paid a commission for their work",
        "instruction_4_title": "Track Your Staff",
        "instruction_4_subtitle": "Using our affiliate dashboard"
    },
    "qr_scan": {
        "invalid_qr_code_provided": "Invalid QR Code Provided. Please, provide the one given by an Ogrammer",
        "camera_permission_required": "Camera Permission required",
        "access_denied": "Access to camera denied.",
        "enable_permission": "To scan the QR code, please enable camera permission or use your mobile camera instead.",
        "instruction_title": "What is the process?",
        "step_1": "Step One",
        "to_scan": "Scan",
        "step_1_text": "Scan the code provided by the OG.",
        "step_2": "Step Two",
        "check_in_or_out": "Check in or out",
        "step_2_text": "Check them in or out, and see job details.",
        "sp_id_form_title": "If the QR code doesn't work, please enter the OG ID",
        "sp_id_label": "Enter the OG ID",
        "scan_qr": "Scan QR",
        "sp_cant_be_checked_in_or_out": "This OG can't be checked-in/out",
        "qr": "QR",
        "scan_next_qr": "Scan Another QR",
        "success_modal_text": "The {{processTitle}} process has been successfully completed.",
        "carousel_title": "Check staff in and out by using the QR code",
        "sp_id_form_title_1": "*QR Code not working?",
        "sp_id_form_title_2": "Enter the OG ID below",
        "camera_missing_title": "Your webcam is not compatible with the current browser.",
        "camera_missing_description": " Please try your mobile camera instead 📸📱",
        "set_default_time": "Set Default"
    },
    "staff": {
        "past_workers": "Past Workers",
        "hours_worked": "Hours Worked",
        "n_hours": "{{hoursAmount}} Hours",
        "n_shifts": "{{shiftsAmount}} Shifts",
        "last_job": "Last Job",
        "locations_worked": "Locations Worked",
        "plus_n_locations": "+ {{locationsAmount}} Locations",
        "enter_notes_on_worker": "Enter your notes on worker",
        "favorite_only": "Favorite Only",
        "apply": "Apply",
        "reset": "Reset",
        "how_was_the_worker": "How was the OG?",
        "rating_submitted": "Rating Submitted!",
        "search_worker_by_id_or_name": "Search Worker by ID or name",
        "worker_name": "Worker Name",
        "designations": "Designations",
        "your_rating": "Your Rating",
        "notes": "Notes",
        "no_workers_found": "No workers found",
        "note_validation_length": "Notes must be no longer than 499 characters",
        "n_more": "{{ amount }} more",
        "last_designation": "Last Designation",
        "shifts_worked": "Shifts Worked",
        "last_location": "Last Location"
    },
    "invited_sps": {
        "quantity_must_not_be_greater": "You can send maximum 3 invitations per 1 open job position. If you want to invite more OGs, please increase required job quantity",
        "form_title": "Would you like to invite your past Ogrammers?",
        "form_subtitle": "This step is optional. If you invite fewer Ogrammers than needed, Ogram will fill the remaining positions",
        "select_required_data": "Please select quantity, location, designation and schedule first",
        "invited_sps": "Preferred Ogrammers [Optional]",
        "worked_at_current_location": "Worked at current location"
    },
    "pagination": {
        "page": "Page",
        "of": "of"
    },
    "rating_popup": {
        "title": "Please Rate Your Experience with Ogrammers",
        "subtitle_1": "We prioritize the best-rated workers for your future jobs. ",
        "subtitle_2": "Your ratings help us prioritize top performers, ensuring you get the best service every time",
        "ogs_to_rate": "OGs to Rate",
        "will_be_prioritized": "This OG will be prioritized for you",
        "will_not_be_assigned": "This OG won’t be assigned again."
    }
}
