import { Instance, SnapshotOut } from 'mobx-state-tree'

import { ScheduleActiveShiftModel } from './active-shift'
import { ScheduleDatesModel } from './date-data-map'
import { ScheduleShiftModel, ShiftStatus } from './shift'
import { ScheduleSingleDateDataModel } from './single-date-data'
import { ScheduleSpModel } from './sp'
import { ScheduleModel } from './index'

export { ShiftStatus } from './shift'

export type CancelShift = {
  cancellation_reason_id: number
  cancellation_reason_details?: string
  cancel_following: 1 | 0
  is_block: 1 | 0
}

export enum SpShiftStatus {
  irrelevant = 0,
  accepted = 1,
  completed = 4,
  canceled = 5,
  started = 7,
  pendingConfirmation = 8,
}

export type ApiJobSchedule = {
  job_id: number
  order_id: number
  start_date: string
  end_date: string
  work_category_name: string
  location_id: number
  location_name: string
  quantity: number
  applicants_count: number
  shifts: {
    id: number
    timezone_offset: number
    start_time: number
    end_time: number
    fulfilled_count: number
    required_quantity: number
    status: ShiftStatus
  }[]
  assigned_sps: {
    id: number
    name: string
    image: string
    phone: string
    is_favorite: boolean
    active_shifts: {
      shift_id: number
      active_shift_id: number
      timezone_offset: number
      start_time: number | null
      end_time: number | null
      client_checkin_time: number | null
      client_checkout_time: number | null
      is_available_for_confirmation: boolean
      status: {
        id: number // models/schedule/types SpShiftStatus
        title: string
      }
    }[]
  }[]
}

export type ModifyActionRequest = {
  start_date: string // YYYY-MM-DD
  end_date: string // YYYY-MM-DD
  type: 'replace' | 'delete'
  start_time: string // 'HH:mm'
  end_time: string // 'HH:mm'
  sp_ids: number[]
}

export type JobModificationRequest = {
  id: number // job id
  actions: ModifyActionRequest[]
}[]

export type ScheduleActiveShift = Instance<typeof ScheduleActiveShiftModel>
export type ScheduleActiveShiftSnapshot = SnapshotOut<typeof ScheduleActiveShiftModel>

export type ScheduleDates = Instance<typeof ScheduleDatesModel>
export type ScheduleDatesSnapshot = SnapshotOut<typeof ScheduleDatesModel>

export type ScheduleShift = Instance<typeof ScheduleShiftModel>
export type ScheduleShiftSnapshot = SnapshotOut<typeof ScheduleShiftModel>

export type ScheduleDate = Instance<typeof ScheduleSingleDateDataModel>
export type ScheduleDateSnapshot = SnapshotOut<typeof ScheduleSingleDateDataModel>

export type ScheduleSp = Instance<typeof ScheduleSpModel>
export type ScheduleSpSnapshot = SnapshotOut<typeof ScheduleSpModel>

export type Schedule = Instance<typeof ScheduleModel>
export type ScheduleSnapshot = SnapshotOut<typeof ScheduleModel>
