import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SpColumn } from './sp-column'
import { Cells } from './cell'
import { SpCell } from './sp-cell'
import { Collapsible } from '../../../../../../../components/collapsible/collapsible'
import dropdownRightIcon from '../../../../../../../components/assets/arrow-dropdown-right.svg'
import dropdownDownIcon from '../../../../../../../components/assets/arrow-dropdown-down.svg'
import threeDotsIcon from '../../../../../../../components/assets/3-dots.svg'
import { MenuItem } from '../../../../../../../components/dropdown-menu/components/menu-item/menu-item'
import calendarNextIcon from '../../../../../../../components/assets/calendar-next.svg'
import { DropdownMenu } from '../../../../../../../components/dropdown-menu/dropdown-menu'
import { BulkExtendJobsPopup } from '../../../../../../../components/bulk-extend-jobs-popup/bulk-extend-jobs-popup'
import { GroupedWorkCategoryItem } from '../../../../../../../models/schedule'

type InputProps = {
  workCategorySchedule: GroupedWorkCategoryItem
  firstWeekDate: Date
  weekDates: Date[]
}

export const WorkCategorySchedule = observer(({ workCategorySchedule, firstWeekDate, weekDates }: InputProps) => {
  const { t } = useTranslation()

  const [collapsed, setCollapsed] = useState(false)
  const [showBulkExtendPopup, setShowBulkExtendPopup] = useState(false)

  const rowsNum = workCategorySchedule.assigned_sps.length + workCategorySchedule.unfulfilled_schedules.length

  return (
    <>
      <div className="ScheduleItem__subheader">
        <img
          onClick={() => setCollapsed(!collapsed)}
          src={collapsed ? dropdownRightIcon : dropdownDownIcon}
          className="ScheduleItem__subheader__arrow"
        />
        <span className="ScheduleItem__subheader__title">
          {workCategorySchedule.work_category_name} ({workCategorySchedule.quantity})
        </span>
        <div className="ScheduleItem__subheader__dropdownContainer">
          <DropdownMenu
            outerComponent={
              <div>
                <img src={threeDotsIcon} />
              </div>
            }
            items={[
              <MenuItem
                key="extend"
                img={<img src={calendarNextIcon} />}
                title={t('Extend the Date')}
                onClick={() => setShowBulkExtendPopup(true)}
              />,
            ]}
          />
        </div>
      </div>
      <Collapsible collapsed={collapsed} maxHeight={92 * rowsNum + 120}>
        {workCategorySchedule.assigned_sps.map((spItem, index) => {
          return (
            <div className={`ScheduleItem__row ${index % 2 === 0 ? 'odd' : ''}`} key={index}>
              <SpColumn
                imageUrl={spItem.image}
                name={spItem.name}
                spId={spItem.sp_id}
                jobId={spItem.original_schedule[0].job_id}
              />
              <Cells
                weekDates={weekDates}
                firstWeekDate={firstWeekDate}
                jobSchedules={
                  spItem.original_schedule.length > 0
                    ? spItem.original_schedule
                    : [workCategorySchedule.default_schedule]
                }
                renderCell={({ jobSchedule, shift, dateShift }) => (
                  <SpCell
                    shift={shift}
                    dateShift={dateShift}
                    defaultSchedule={workCategorySchedule.default_schedule}
                    spId={spItem.sp_id} // will render empty cell for non-real id
                    jobSchedule={jobSchedule ?? workCategorySchedule.default_schedule}
                    spName={spItem.name}
                  />
                )}
              />
            </div>
          )
        })}
        {workCategorySchedule.unfulfilled_schedules.map((unfulfilledSchedule, index) => {
          return (
            <div className={`ScheduleItem__row ${index % 2 === 0 ? 'odd' : ''}`} key={index}>
              <SpColumn
                name={`${
                  unfulfilledSchedule.applicants_count >= 100 ? '99+' : unfulfilledSchedule.applicants_count
                } applicants`}
                jobId={unfulfilledSchedule.job_id}
                requiredAggregatedQuantity={unfulfilledSchedule.quantity - unfulfilledSchedule.spList.length}
                isAggregation
              />
              <Cells
                weekDates={weekDates}
                firstWeekDate={firstWeekDate}
                jobSchedules={[unfulfilledSchedule]}
                renderCell={({ shift, dateShift }) => (
                  <SpCell
                    shift={shift}
                    dateShift={dateShift}
                    defaultSchedule={workCategorySchedule.default_schedule}
                    spId={-1} // will render empty cell for non-real id
                    jobSchedule={unfulfilledSchedule}
                  />
                )}
              />
            </div>
          )
        })}
      </Collapsible>
      <BulkExtendJobsPopup
        isOpen={showBulkExtendPopup}
        close={() => setShowBulkExtendPopup(false)}
        clearOnClose
        spToExtendOptions={workCategorySchedule.assigned_sps.map(sp => {
          return {
            id: sp.sp_id,
            name: sp.name,
            image: sp.image,
            job_id: sp.original_schedule[sp.original_schedule.length - 1].job_id,
            current_end_date: sp.original_schedule[sp.original_schedule.length - 1].end_date,
          }
        })}
      />
    </>
  )
})
