import { types, flow, SnapshotIn, SnapshotOut, Instance } from 'mobx-state-tree'

import { DataStore } from '../data-store/data-store'
import { BasicOrderSnapshot, BasicOrderInstance } from './basic-order'

type JobToExtend = {
  id: number
  extensionDate: string
}

type JobToBulkExtend = {
  id: number
  start_date: string
  extension_date: string
  invited_sps: number[]
}

export type OrderExtension = {
  id: number
  jobsToExtend: JobToExtend[]
}

export const OrderSummaryModel = types.model('OrderSummary', {
  id: types.number,
  is_dynamic_priced: types.boolean,
  event_name: types.string,
  client_name: types.string,
  is_draft: types.boolean,
  is_upfront: types.boolean,
  is_paid: types.boolean,
  is_pending_rates_review: types.number,
  is_pending_client_approval: types.number,
  is_pending_lpo_upload: types.boolean,
  quote_was_downloaded: types.boolean,
  locations: types.array(
    types.model({
      name: types.string,
      address: types.string,
      lat: types.number,
      lng: types.number,
    }),
  ),
  sub_total: types.maybeNull(types.number),
  tax: types.maybeNull(types.number),
  tax_percentage: types.maybeNull(types.number),
  payment_terms_days: types.number,
  total: types.maybeNull(types.number),
  transportation_total: types.maybeNull(types.number),
  client_confirmed_at: types.maybeNull(types.number),
  currency: types.maybeNull(types.string),
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  lpo_was_uploaded: types.boolean,
  is_terms_and_conditions_accepted: types.boolean,
  corporate_details_verification_status: types.number,
  days_till_end_count: types.number,
  is_published: types.boolean,
  jobs: types.array(
    types.model({
      id: types.identifierNumber,
      start_date: types.string,
      end_date: types.string,
      quantity: types.number,
      invited_sp_ids: types.array(types.number),
      shifts: types.array(
        types.model({
          timezone_offset: types.number,
          start_time: types.number,
          end_time: types.number,
        }),
      ),
      transportation_costs: types.number,
      price: types.number,
      currency: types.string,
      hours: types.number,
      hourly_rate: types.number,
      location_name: types.string,
      work_category_name: types.string,
      min_extension_date: types.string,
    }),
  ),
})

export const orderSummaryActions = (self: DataStore) => ({
  getOrderSummary: flow(function* ({ orderId }: { orderId: string }) {
    const summary = (yield self.request('get', `client/order/summary?order_id=${orderId}`)) as OrderSummarySnapshot
    if (!self.order.draft.has(orderId)) {
      const basicOrder = (yield self.request('post', 'client/order/basic/get', {
        order_id: orderId,
      })) as BasicOrderSnapshot
      self.order.draft.set(orderId, { ...basicOrder, is_draft: Boolean(basicOrder.is_draft) })
    }
    const order = self.order.draft.get(orderId) as BasicOrderInstance
    order.summary = OrderSummaryModel.create(summary)

    return order.summary
  }),
  confirmOrder: flow(function* ({ orderId }: { orderId: string }) {
    yield self.request('post', 'client/order/confirm', { order_id: orderId })
  }),
  confirmOrderTermsConditions: flow(function* () {
    yield self.request('post', 'client/terms-and-conditions/accept')
  }),
  downloadQuotation: flow(function* (id: string) {
    return (yield self.request('get', `client/order/${id}/quote`, undefined, {
      responseType: 'blob',
    })) as BlobPart
  }),
  downloadLPO: flow(function* (id: string) {
    return (yield self.request('get', `client/order/${id}/lpo`, undefined, {
      responseType: 'blob',
    })) as BlobPart
  }),
  uploadLPO: flow(function* (orderId: string, lpoFile: File) {
    yield self.request(
      'post',
      `client/order/${orderId}/lpo`,
      { file: lpoFile },
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
  }),
  setIsPendingLPOUpload: function (orderId: string, isPendingLPOUpload: boolean) {
    const order = self.order.draft.get(orderId) as BasicOrderInstance
    if (order.summary) {
      order.summary.is_pending_lpo_upload = isPendingLPOUpload
    }
  },
  setQuoteWasDownloaded: function (orderId: string, quoteWasDownloaded: boolean) {
    const order = self.order.draft.get(orderId) as BasicOrderInstance
    if (order.summary) {
      order.summary.quote_was_downloaded = quoteWasDownloaded
    }
  },
  setLPOWasUploaded: function (orderId: string, lPOWasUploaded: boolean) {
    const order = self.order.draft.get(orderId) as BasicOrderInstance
    if (order.summary) {
      order.summary.lpo_was_uploaded = lPOWasUploaded
    }
  },
  setIsTermsAndConditionsAccepted: function (orderId: string, isTermsAndConditionsAccepted: boolean) {
    const order = self.order.draft.get(orderId) as BasicOrderInstance
    if (order.summary) {
      order.summary.is_terms_and_conditions_accepted = isTermsAndConditionsAccepted
    }
  },
  getUpfrontPaymentId: flow(function* (orderId: string) {
    const result = (yield self.request('get', `client/order/${orderId}/upfront-payment`)) as { payment_id: number }

    return result.payment_id
  }),
  requestCallback: flow(function* ({ orderId }: { orderId: string }) {
    yield self.request('post', `client/order/${orderId}/request-callback`)
  }),
  createExtendedOrder: flow(function* ({ id, jobsToExtend }: OrderExtension) {
    const result = (yield self.request('post', `client/order/${id}/create-extended-order`, {
      jobsToExtend,
    })) as { order_id: number }

    return result.order_id
  }),
  bulkExtendJobs: flow(function* ({ jobs }: { jobs: JobToBulkExtend[] }) {
    const result = (yield self.request('post', `client/order/jobs/extend`, {
      jobs,
    })) as { order_id: number }

    return result.order_id
  }),
})

export const orderSummaryViews = (self: DataStore) => ({
  getOrderIdSummary(orderId: string) {
    return self.order.draft.get(orderId)?.summary
  },
})

export type OrderSummary = SnapshotIn<typeof OrderSummaryModel>
export type OrderSummarySnapshot = SnapshotOut<typeof OrderSummaryModel>
export type OrderSummaryInstance = Instance<typeof OrderSummaryModel>
