import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Time } from './time'
import { Status, getShiftStatus, StatusType } from './status'
import {
  Schedule,
  ScheduleActiveShift,
  ShiftStatus,
  ScheduleShiftSnapshot,
  SpShiftStatus,
} from '../../../../../../../models/schedule/types'
import { ShiftTools } from './shift-tools/components'
import './sp-time.scss'
import { ConfirmationShiftTools } from './confirmation-shift-tools'
import { formatDateToHhMm } from '../../../../../../../utils/datetime-utils'
import { TrackedEvent, trackingService } from '../../../../../../../services/tracking-service'
import { AssignShiftTools } from './assign-shift-tools'
import { useStore } from '../../../../../../../utils/mst-hooks'

export type SpTimeProps = {
  jobSchedule: Schedule
  spId: number
  spShift: ScheduleActiveShift | null
  shift: ScheduleShiftSnapshot
  spName?: string | null
}

function SpTimeComponent({ spId, spShift, shift, jobSchedule, spName = null }: SpTimeProps) {
  const { t } = useTranslation()
  const { startTime, endTime } = jobSchedule.getSpTime({ spId, shiftId: shift.id })
  const timeModificationAllowed = shift.status === ShiftStatus.Upcoming || spShift === null
  const startTimeModificationAllowed = timeModificationAllowed && (spShift?.client_checkin_time ?? null) === null
  const endTimeModificationAllowed = timeModificationAllowed && (spShift?.client_checkout_time ?? null) === null
  const highlightAllowed = shift.status === ShiftStatus.Started || shift.status === ShiftStatus.Completed
  const startTimeDate = new Date((startTime as number) * 1000)
  const endTimeDate = new Date((endTime as number) * 1000)

  const { modifyWorkCategoryDateSchedule, getSPsByWorkCategoryNameAndDate } = useStore().data

  const getUpdatedTime = (value: string, date: Date) => {
    const [hours, minutes] = value.split(':')
    const newStartTime = new Date(date)
    newStartTime.setHours(parseInt(hours))
    newStartTime.setMinutes(parseInt(minutes))
    return Math.floor(newStartTime.getTime() / 1000)
  }

  return (
    <div className="SpTime">
      <div className="SpTime__top">
        {spShift ? (
          <>
            <Time
              value={startTime ? formatDateToHhMm(startTimeDate) : null}
              disabled={!startTimeModificationAllowed}
              highlighted={highlightAllowed && Boolean(startTime)}
              label={t('all_jobs.start')}
              handleChange={value => {
                trackingService.track(TrackedEvent.TimesheetSPStartTimeChanged)
                jobSchedule.modifySpTime({
                  spId,
                  shiftId: shift.id,
                  startTime: getUpdatedTime(value, startTimeDate),
                })
              }}
              onPopoverAction={itemIds => {
                modifyWorkCategoryDateSchedule({
                  workCategoryName: jobSchedule.work_category_name,
                  locationId: jobSchedule.location_id,
                  date: moment(startTimeDate).format('YYYY-MM-DD'),
                  spIds: itemIds,
                  startTime: startTime ?? undefined,
                })
              }}
              popoverItems={getSPsByWorkCategoryNameAndDate({
                workCategoryName: jobSchedule.work_category_name,
                locationId: jobSchedule.location_id,
                date: moment(startTimeDate).format('YYYY-MM-DD'),
              }).filter(item => item.value !== spId)}
              popoverDate={moment(startTimeDate).format('ddd, DD MMM')}
              spName={spName}
            />
            <Time
              value={endTime ? formatDateToHhMm(endTimeDate) : null}
              disabled={!endTimeModificationAllowed}
              highlighted={highlightAllowed && Boolean(endTime)}
              label={t('all_jobs.end')}
              handleChange={value => {
                trackingService.track(TrackedEvent.TimesheetSPEndTimeChanged)
                jobSchedule.modifySpTime({
                  spId,
                  shiftId: shift.id,
                  endTime: getUpdatedTime(value, endTimeDate),
                })
              }}
              onPopoverAction={itemIds => {
                modifyWorkCategoryDateSchedule({
                  workCategoryName: jobSchedule.work_category_name,
                  locationId: jobSchedule.location_id,
                  date: moment(startTimeDate).format('YYYY-MM-DD'),
                  spIds: itemIds,
                  endTime: endTime ?? undefined,
                })
              }}
              popoverItems={getSPsByWorkCategoryNameAndDate({
                workCategoryName: jobSchedule.work_category_name,
                locationId: jobSchedule.location_id,
                date: moment(startTimeDate).format('YYYY-MM-DD'),
              }).filter(item => item.value !== spId)}
              popoverDate={moment(startTimeDate).format('ddd, DD MMM')}
              spName={spName}
            />
          </>
        ) : (
          <>
            <Time
              value={formatDateToHhMm(new Date(shift.start_time * 1000))}
              label={t('all_jobs.start')}
              disabled={false}
              handleChange={value => {
                trackingService.track(TrackedEvent.TimesheetStartTimeBulkChanged)
                jobSchedule.modifyActiveShiftsTime({
                  shiftId: shift.id,
                  startTime: getUpdatedTime(value, new Date(shift.start_time * 1000)),
                })
              }}
            />
            <Time
              value={formatDateToHhMm(new Date(shift.end_time * 1000))}
              label={t('all_jobs.end')}
              disabled={false}
              handleChange={value => {
                trackingService.track(TrackedEvent.TimesheetEndTimeBulkChanged)
                jobSchedule.modifyActiveShiftsTime({
                  shiftId: shift.id,
                  endTime: getUpdatedTime(value, new Date(shift.end_time * 1000)),
                })
              }}
            />
          </>
        )}
      </div>
      {spShift ? (
        <div className="SpTime__bottom">
          <Status status={getShiftStatus(spShift.status.id)} title={spShift.status.title} />
          {spShift.status.id === SpShiftStatus.pendingConfirmation && spShift.is_available_for_confirmation ? (
            <ConfirmationShiftTools shiftId={shift.id} />
          ) : shift.status === ShiftStatus.Started || shift.status === ShiftStatus.Upcoming ? (
            <ShiftTools jobSchedule={jobSchedule} shift={spShift} spId={spId} spShift={spShift} />
          ) : null}
        </div>
      ) : (
        <div className="SpTime__bottom">
          <Status status={StatusType.spEmpty} title={t('all_jobs.empty_slot')} />
          {shift.status === ShiftStatus.Started ||
            (shift.status === ShiftStatus.Upcoming && (
              <AssignShiftTools jobId={jobSchedule.job_id} shiftId={shift.id} />
            ))}
        </div>
      )}
    </div>
  )
}

export const SpTime = observer(SpTimeComponent)
