import { useTranslation } from 'react-i18next'

import { SPToExtend } from '../../bulk-extend-jobs-popup'
import crossIcon from '../../../assets/cross-icon-grey.svg'
import { Separator } from '../../../app-layout'
import './sps-selector.scss'
import { SpItem } from '../sp-item/sp-item'

type InputProps = {
  spToExtendOptions: SPToExtend[]
  selectedSpIds: number[]
  addOrRemoveSP: (value: number) => void
}

export const SpsSelector = ({ spToExtendOptions, selectedSpIds, addOrRemoveSP }: InputProps) => {
  const { t } = useTranslation()

  return (
    <div className="SPsSelector">
      <span className="Popup__title">{t('order_extension.select_sps')}</span>
      <span className="Popup__subtitle">{t('order_extension.select_sps_to_extend')}</span>
      {selectedSpIds.length > 0 && (
        <>
          <div className="SPsSelector__addedList">
            {spToExtendOptions
              .filter(sp => selectedSpIds.includes(sp.id))
              .map(sp => (
                <div onClick={() => addOrRemoveSP(sp.id)} className="SPsSelector__addedList__item" key={sp.id}>
                  <span>{sp.name}</span>
                  <img src={crossIcon} />
                </div>
              ))}
          </div>
        </>
      )}
      <div className="SPsSelector__list">
        {spToExtendOptions.map(sp => {
          const selected = selectedSpIds.includes(sp.id)
          return <SpItem key={sp.id} onClick={() => addOrRemoveSP(sp.id)} sp={sp} selected={selected} />
        })}
      </div>
      <Separator.Vertical height={24} />
    </div>
  )
}
