import './sp-column.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from '../../../../../../../components/popup/popup'
import ApplicantDetails from '../../../../../../applicants-review/applicant-details/applicant-details'
import { TrackedEvent, trackingService } from '../../../../../../../services/tracking-service'
import candidatesIcon from './assets/candidates-icon.svg'
import { useRedirect } from '../../../../../../../services/router'
import { routes } from '../../../../../../../routes'

export type SpColumnProps = {
  name: string
  spId?: number | null
  jobId: number
  imageUrl?: string | null
  isAggregation?: boolean
  requiredAggregatedQuantity?: number
}

export function SpColumn({
  imageUrl,
  name,
  spId,
  jobId,
  isAggregation = false,
  requiredAggregatedQuantity,
}: SpColumnProps) {
  const [isProfileShown, setIsProfileShown] = useState(false)
  const redirect = useRedirect()
  const { t } = useTranslation()

  return (
    <div className="SpColumn">
      <img
        onClick={() => {
          if (!isAggregation) {
            setIsProfileShown(true)

            trackingService.track(TrackedEvent.TimesheetSPProfileClicked)
          } else {
            redirect(routes.jobOGCherryPicker, { jobId: String(jobId) })
          }
        }}
        src={!isAggregation ? String(imageUrl) : candidatesIcon}
        title={name}
        className="SpColumn__img"
      />
      <div
        onClick={() => {
          if (!isAggregation) {
            setIsProfileShown(true)

            trackingService.track(TrackedEvent.TimesheetSPProfileClicked)
          } else {
            redirect(routes.jobOGCherryPicker, { jobId: String(jobId) })
          }
        }}
        className="SpColumn__textContainer"
      >
        <span className="SpColumn__name">{name}</span>
        <span className="SpColumn__id">
          {!isAggregation
            ? t('common.id_n', { id: spId })
            : t('all_jobs.sps_required', { amount: requiredAggregatedQuantity })}
        </span>
      </div>
      <Popup
        isActive={isProfileShown}
        content={
          <ApplicantDetails
            jobId={String(jobId)}
            applicantId={String(spId)}
            applicantName={name}
            showHeader={false}
            showControls={false}
          />
        }
        close={() => setIsProfileShown(false)}
      />
    </div>
  )
}
