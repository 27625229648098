import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ApiErrorTypes } from 'ogram-react'
import { useState } from 'react'

import { Separator } from '../app-layout'
import { Stepper } from '../stepper/stepper'
import { Popup } from '../popup/popup'
import { withErrorBoundary } from '../error/with-error-boundary'
import { DatesSelector } from './components/dates-selector/dates-selector'
import './bulk-extend-jobs-popup.scss'
import { SpsSelector } from './components/sps-selector/sps-selector'
import { useMutation, useStore } from '../../utils/mst-hooks'
import { routes } from '../../routes'
import { useRedirect } from '../../services/router'

export type SPToExtend = {
  id: number
  job_id: number
  current_end_date: string
  image: string
  name: string
}

export type JobToExtend = {
  id: number
  start_date: string
  extension_date: string
  invited_sps: number[]
}

const STEP_DATES_SELECTION = 0
const STEP_SPS_SELECTION = 1

export const BulkExtendJobsPopup = withErrorBoundary(
  observer(
    ({
      isOpen,
      close,
      clearOnClose,
      spToExtendOptions,
      onSuccess = () => {},
    }: {
      isOpen: boolean
      close: () => void
      clearOnClose: boolean
      spToExtendOptions: SPToExtend[]
      onSuccess?: () => void
    }) => {
      const [step, setStep] = useState<number>(STEP_DATES_SELECTION)
      const [startDate, setStartDate] = useState<string | null>(null)
      const [extensionDate, setExtensionDate] = useState<string | null>(null)
      const [jobsToExtend, setJobsToExtend] = useState<JobToExtend[]>([])

      const redirect = useRedirect()

      const { bulkExtendJobs } = useStore().data
      const [, bulkExtendJobsRequest] = useMutation(({ jobs }: { jobs: JobToExtend[] }) => bulkExtendJobs({ jobs }), {
        onSuccess: orderId => {
          redirect(routes.extendedOrderDraft, { orderId })
        },
        onError: err => {
          toast.error(err.data.message)
        },
        filterError: [ApiErrorTypes.ClientError],
      })

      const getSelectedSpIds = () => {
        const result: number[] = []
        jobsToExtend.forEach(job => {
          job.invited_sps.forEach(sp => {
            if (!result.includes(sp)) {
              result.push(sp)
            }
          })
        })

        return result
      }

      const addOrRemoveSP = (id: number) => {
        const sp = spToExtendOptions.find(item => item.id === id)
        if (!sp) {
          return
        }

        const job = jobsToExtend.find(item => item.id === sp.job_id)
        if (!job) {
          const modifiedJobs: JobToExtend[] = [
            ...jobsToExtend,
            {
              id: sp.job_id,
              start_date: startDate ?? '',
              extension_date: extensionDate ?? '',
              invited_sps: [sp.id],
            },
          ]
          setJobsToExtend(modifiedJobs)
        } else {
          const modifiedJobs = jobsToExtend
            .map(item => {
              if (item.id !== job.id) {
                return item
              }

              return {
                ...item,
                invited_sps: item.invited_sps.includes(sp.id)
                  ? item.invited_sps.filter(invitedSP => invitedSP !== sp.id)
                  : [...item.invited_sps, sp.id],
              }
            })
            .filter(item => item.invited_sps.length > 0)
          setJobsToExtend(modifiedJobs)
        }
      }

      const { t } = useTranslation()

      const getNextDisabled = () => {
        if (step === STEP_DATES_SELECTION) {
          return startDate === null || extensionDate === null
        } else {
          return jobsToExtend.length === 0
        }
      }

      const handleNextPress = () => {
        if (step === STEP_DATES_SELECTION) {
          setStep(STEP_SPS_SELECTION)
        } else {
          bulkExtendJobsRequest({ jobs: jobsToExtend })
        }
      }

      const handleBackPress = () => {
        if (step === STEP_SPS_SELECTION) {
          setStep(STEP_DATES_SELECTION)
        }
      }

      return (
        <Popup
          isActive={isOpen}
          content={
            <>
              <div className="BulkJobsExtension__popup">
                {step === STEP_DATES_SELECTION && (
                  <DatesSelector
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={extensionDate}
                    setEndDate={setExtensionDate}
                  />
                )}
                {step === STEP_SPS_SELECTION && (
                  <SpsSelector
                    spToExtendOptions={spToExtendOptions}
                    selectedSpIds={getSelectedSpIds()}
                    addOrRemoveSP={addOrRemoveSP}
                  />
                )}
              </div>
              <Separator.Spacer />
              <Stepper
                currentStep={step}
                stepsCount={2}
                backDisabled={step === STEP_DATES_SELECTION}
                nextDisabled={getNextDisabled()}
                handleNextPress={handleNextPress}
                handleBackPress={handleBackPress}
                nextButtonLabel={t('page_stepper.next')}
              />
            </>
          }
          close={() => {
            close()
            if (clearOnClose) {
              setStep(STEP_DATES_SELECTION)
              setStartDate(null)
              setExtensionDate(null)
              setJobsToExtend([])
            }
          }}
        />
      )
    },
  ),
)
