import { Fragment, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment/moment'

import { useStore } from '../../../../../../utils/mst-hooks'
import { formatDateToIso8601, getWeekDates } from '../../../../../../utils/datetime-utils'
import { ScheduleItem } from './components/schedule-item'
import { RowSeparator } from './components/row-separator'
import { Column } from './components/column'
import './calendar-schedule.scss'
import { ExtendOrderPopup } from '../../../../../../components/extend-order-popup/extend-order-popup'
import { orderExtensionStore } from '../../../../../../models-ui/order/store'
import { TrackedEvent, trackingService } from '../../../../../../services/tracking-service'

const MIN_ROWS = 8

function CalendarScheduleComponent({ firstWeekDate }: { firstWeekDate: Date }) {
  const [orderIdToExtend, setOrderIdToExtend] = useState<number | null>(null)

  const { getJobsScheduleForWeek, groupedSchedule } = useStore().data

  const formatedWeekDate = formatDateToIso8601(firstWeekDate)
  const jobsScheduleForWeek = getJobsScheduleForWeek(formatedWeekDate)
  const weekDates = getWeekDates(firstWeekDate)

  const emptyColumns = useMemo(() => {
    const jobs = jobsScheduleForWeek.length
    const assignedSps = jobsScheduleForWeek.reduce((spsCount, jobScheduleItem) => {
      return spsCount + jobScheduleItem.assigned_sps.size
    }, 0)
    const totalRows = jobs + assignedSps
    const diff = MIN_ROWS - totalRows
    const numberOfEmptyColumns = diff > 0 ? diff : 0
    return new Array(numberOfEmptyColumns).fill(null) as null[]
  }, [jobsScheduleForWeek])

  useEffect(() => {
    const scheduleItem = jobsScheduleForWeek.find(item => item.order_id === orderIdToExtend)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (scheduleItem) {
      const order = {
        id: scheduleItem.order_id,
        end_date: scheduleItem.end_date,
        jobs: [
          {
            id: scheduleItem.job_id,
            location_name: scheduleItem.location_name,
            work_category_name: scheduleItem.work_category_name,
            quantity: scheduleItem.quantity,
            start_date: scheduleItem.start_date,
            end_date: scheduleItem.end_date,
            min_extension_date: moment(scheduleItem.end_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
          },
        ],
      }

      orderExtensionStore.clear()
      orderExtensionStore.createFromOrderModel(order)
    }
  }, [orderIdToExtend])

  return (
    <div className="CalendarSchedule">
      {groupedSchedule.map(locationSchedule => (
        <ScheduleItem
          key={locationSchedule.toString()}
          locationSchedule={locationSchedule}
          firstWeekDate={firstWeekDate}
          weekDates={weekDates}
        />
      ))}
      {emptyColumns.map((_, index) => (
        <div className="ScheduleItem" key={index}>
          <div className="ScheduleItem__row">
            <div className="JobColumn" />
            {weekDates.map((__, i) => (
              <Fragment key={i}>
                <RowSeparator />
                <Column>
                  <></>
                </Column>
              </Fragment>
            ))}
          </div>
        </div>
      ))}
      <ExtendOrderPopup
        isOpen={orderIdToExtend !== null}
        close={() => setOrderIdToExtend(null)}
        onSuccess={() => trackingService.track(TrackedEvent.ExtendedOrderFromAllJobs)}
        clearOnClose
      />
    </div>
  )
}

export const CalendarSchedule = observer(CalendarScheduleComponent)
