import { flow, SnapshotOut, types } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

const SPToRateModel = types.model('SPToRateModel', {
  id: types.identifierNumber,
  name: types.string,
  image_url: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
})

export const SPsToRateModel = types.model('SPsToRateModel', {
  list: types.map(SPToRateModel),
  currentPage: types.number,
  totalPages: types.number,
  nextPage: types.maybeNull(types.number),
  total: types.number,
})

export const SPsToRateActions = (self: DataStore) => ({
  getSPsToRate: flow(function* ({ page = 1, jobId, collect }: { page: number; jobId: number; collect: boolean }) {
    const { list, paginator } = (yield self.request('get', `client/rating/jobs/${jobId}/sps??page=${page}`)) as {
      list: SPToRateSnapshot[]
      paginator?: Paginator
    }

    if (!collect) {
      self.spsToRate.list.clear()
    }
    list.forEach(item => {
      self.spsToRate.list.set(item.id, item)
    })

    self.spsToRate.totalPages = Number(paginator?.last_page)
    self.spsToRate.currentPage = page
    self.spsToRate.total = Number(paginator?.total)
    self.spsToRate.nextPage = paginator?.next_page ?? null
  }),
})

export const SPsToRateViews = (self: DataStore) => ({
  get spsToRateList() {
    return Array.from(self.spsToRate.list.values())
  },
  get spsToRateNextPage() {
    return self.pastWorkers.nextPage
  },
})

export type SPToRateSnapshot = SnapshotOut<typeof SPToRateModel>

export type GetSPsToRateRequestProps = {
  collect: boolean
  page?: number | null
  jobId: number
}
