import './calendar.scss'
import moment from 'moment'
import { useState, useEffect, forwardRef, RefObject, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar as ReactCalendar } from 'react-calendar'

import { Select } from '../form/select/select'
import { getCurrentMarketConfig, LANGUAGE_EN, marketService } from '../../services/market-service'
import 'react-calendar/dist/Calendar.css'

const months = [
  'calendar.month_january',
  'calendar.month_february',
  'calendar.month_march',
  'calendar.month_april',
  'calendar.month_may',
  'calendar.month_june',
  'calendar.month_july',
  'calendar.month_august',
  'calendar.month_september',
  'calendar.month_october',
  'calendar.month_november',
  'calendar.month_december',
]

type InputProps = {
  value: Date
  setValue: (value: Date | null) => void
  minDate?: Date | undefined
  maxDate?: Date | undefined
  containerClassName?: string
  containerStyle?: object
  setHoveredDate?: (value: Date | null) => void
  hoveredDate?: Date | null
  highlightWeek?: boolean
  bottomActionTitle?: string | null
  bottomActionCallback?: () => void | null
  showNeighboringMonth?: boolean
}

const Calendar = forwardRef(
  (
    {
      value,
      setValue,
      minDate,
      maxDate,
      containerClassName,
      containerStyle,
      hoveredDate,
      setHoveredDate,
      highlightWeek,
      bottomActionTitle,
      bottomActionCallback,
      showNeighboringMonth = false,
    }: InputProps,
    ref,
  ) => {
    const getWeekRange = (date: Date) => {
      const startOfWeek = new Date(date)

      // Adjust day offset based on the week start preference
      const dayOffset = getCurrentMarketConfig().firstDayOfWeek === 'Mon' ? 1 : 0

      // Calculate the start of the week
      const day = startOfWeek.getDay()
      const diff = (day < dayOffset ? 7 : 0) + day - dayOffset
      startOfWeek.setDate(date.getDate() - diff)

      // Calculate the end of the week
      const endOfWeek = new Date(startOfWeek)
      endOfWeek.setDate(startOfWeek.getDate() + 6)

      return { startOfWeek, endOfWeek }
    }

    const tileClassName = ({ date }: { date: Date }) => {
      let className = ''
      if (!highlightWeek) {
        return className
      }

      if (hoveredDate) {
        const { startOfWeek, endOfWeek } = getWeekRange(hoveredDate)
        if (date >= startOfWeek && date <= endOfWeek) {
          className += 'highlight-week'
        }
      }

      const selectedValue = new Date(value)
      selectedValue.setHours(0, 0, 0, 0)

      const { startOfWeek, endOfWeek } = getWeekRange(selectedValue)
      if (date >= startOfWeek && date <= endOfWeek) {
        className += ' highlight-week'
      }

      return className
    }

    const years = useMemo(() => {
      const currentYear = Number(moment().format('YYYY'))
      return [
        {
          key: currentYear,
          value: String(currentYear),
        },
        {
          key: currentYear + 1,
          value: String(currentYear + 1),
        },
      ]
    }, [])

    const { t } = useTranslation()

    const [chosenMonth, setChosenMonth] = useState<number>(new Date().getMonth())
    const [chosenYear, setChosenYear] = useState<number>(new Date().getFullYear())
    const [chosenDate, setChosenDate] = useState<Date>(new Date())

    const language = marketService.getCurrentLanguage()

    const tileContent = ({ date }: { date: Date }) => {
      if (!highlightWeek) {
        return null
      }

      return (
        <div
          onMouseOver={() => setHoveredDate?.(date)} // Set hovered date on hover
          onMouseLeave={() => setHoveredDate?.(null)} // Clear on mouse leave
          style={{
            position: 'absolute', // Ensure it covers the entire tile
            top: '-173%',
            left: '-14%',
            width: '130%',
            height: '230%',
            backgroundColor: 'transparent',
          }}
        ></div>
      )
    }

    useEffect(() => {
      setChosenDate(new Date(chosenYear, chosenMonth))
    }, [chosenMonth, chosenYear])

    return (
      <div
        style={containerStyle}
        ref={ref as RefObject<HTMLDivElement>}
        className={`Calendar__container${highlightWeek ? ' Calendar--highlightWeek' : ''}${
          containerClassName ? ` ${containerClassName}` : ''
        }`}
      >
        <div className="Calendar__topActions">
          <Select
            label={t('calendar.month')}
            className="Calendar__topActions__monthSelector"
            value={chosenMonth}
            onChange={monthValue => {
              setChosenMonth(monthValue)
            }}
            options={months.map((month, index) => {
              return {
                key: String(index),
                label: t(month),
                value: index,
              }
            })}
          />
          <Select
            label={t('calendar.year')}
            value={chosenYear}
            onChange={yearValue => {
              setChosenYear(yearValue)
            }}
            options={years.map(year => {
              return {
                key: String(year.key),
                label: year.value,
                value: Number(year.key),
              }
            })}
          />
        </div>
        <ReactCalendar
          locale={language ?? LANGUAGE_EN}
          activeStartDate={chosenDate}
          onChange={(dateValue: Date) => {
            setValue(dateValue)
          }}
          calendarType={getCurrentMarketConfig().firstDayOfWeek === 'Sun' ? 'US' : 'ISO 8601'}
          showNavigation={false}
          showNeighboringMonth={showNeighboringMonth}
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          tileClassName={tileClassName}
          tileContent={({ date, view }) => (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>{tileContent({ date })}</div>
          )}
        />
        <div className="Calendar__bottomActions">
          <button
            className="Calendar__cancelButton"
            onClick={() => {
              if (bottomActionCallback) {
                bottomActionCallback()
              } else {
                setValue(null)
              }
            }}
          >
            {bottomActionTitle ?? t('calendar.clear')}
          </button>
        </div>
      </div>
    )
  },
)

Calendar.displayName = 'Calendar'

export default Calendar
