{
    "common": {
        "ogram": "Ogram",
        "start_now": "ابدأ الآن",
        "dismiss": "تخطى",
        "continue": "اكمل",
        "text_copied": "تم نسخ النص إلى الحافظة",
        "tc": "الشروط و الأحكام",
        "shifts": "مناوبات",
        "show_more": "عرض المزيد",
        "ogs": "موظفي أوغرام",
        "i_accept_terms": "انا اوافق على <1>شروط الخدمة</1>",
        "i_accept": "انا اوافق على",
        "terms_of_service": "شروط الخدمة",
        "yes": "نعم",
        "no": "لا",
        "id_n": "رقم التعريفي#{{id}}",
        "loading": "يتم التحميل...",
        "app_version": "إصدار التطبيق: {{version}}",
        "select": "اختار",
        "job_n": "وظيفة #{{id}}",
        "filters": "تصفية",
        "filter": "تصفية",
        "location": "الموقع",
        "designation": "نوع الوظيفة",
        "change": "تغيير",
        "cancel": "إلغاء",
        "order_id": "رقم الطلب",
        "is_overdue": "تجاوز تاريخ الاستحقاق",
        "date_from": "التاريخ من",
        "date_to": "التاريخ إلى",
        "now": "الآن",
        "no_data_found": "لم يتم العثور على بيانات",
        "not_available": "غير متوفر",
        "clear": "مسح",
        "done": "تم",
        "optional": "Optional",
        "industry": "Industry",
        "employees_amount_tier": "How many employees does your company have?"
    },
    "password": {
        "minimumChars": "6+ أحرف",
        "oneLowercase": "حرف صغير",
        "oneNumber": "رقم واحد",
        "oneUppercase": "حرف كبير"
    },
    "login": {
        "good_to_see_you_back": "يسعدنا رؤيتك مرة أخرى",
        "sign_in_to_account": "يرجى تسجيل الدخول إلى حسابك",
        "enter_email": "الرجاء إدخال بريدك الإلكتروني",
        "enter_password": "الرجاء إدخال كلمة المرور",
        "email": "بريد إلكتروني",
        "password": "كلمة المرور",
        "remember_me": "تذكرني",
        "forgot_password_question": "نسيت كلمة المرور؟",
        "sign_in": "تسجيل الدخول",
        "signin_in": "يتم تسجيل دخولك...",
        "sign_up_link_text": "تسجيل الدخول",
        "test_string": "اختبار النظام"
    },
    "create_password": {
        "title": "إنشاء كلمة مرور",
        "description": "الرجاء منك إنشاء كلمة المرور لتستطيع من تسجيل الدخول بستخدام بريدك الإلكتروني في المرة القادمة",
        "please_enter_password": "الرجاء إدخال كلمة المرور",
        "please_confirm_password": "الرجاء تأكيد كلمة المرور",
        "confirm_password": "تأكيد كلمة المرور",
        "password": "كلمة المرور",
        "password_matched": "تم تطابق كلمة المرور",
        "password_not_matched": "لم تطابق كلمة المرور",
        "resend_modal_button": "إعادة الإرسال للبريد الإلكتروني",
        "sending": "يتم الإرسال...",
        "email_invalid": "بريدك الإلكتروني غير صالح",
        "email_required": "يرجى توفير بريدك الإلكتروني",
        "email": "بريد إلكتروني",
        "email_is_sent": "لقد تم إرسال رابط جديد إلى بريدك الإلكتروني.",
        "submit": "تقديم"
    },
    "forgot_password": {
        "title": "نسيت كلمة المرور؟",
        "description": "أدخل بريدك الإلكتروني المرتبط بحسابك",
        "please_enter_email": "الرجاء إدخال بريدك الإلكتروني",
        "email_must_be_valid": "البريد الإلكتروني يجب ان يكون صالحاً",
        "email": "بريد الإلكتروني",
        "submit": "تقديم",
        "back_to_sign_in": "العودة إلى صفحة تسجيل الدخول",
        "forgot_password": "نسيت كلمة المرور",
        "password_sent_to_email": "تم إرسال كلمة المرور الجديدة إلى البريد الإلكتروني.",
        "resend": "إعادة الإرسال",
        "reset_password": "إعادة تعيين كلمة المرور",
        "email_sent": "تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني"
    },
    "validation_messages": {
        "email_invalid": "بريدك الإلكتروني غير صالح"
    },
    "reset_password": {
        "title": "إعادة تعيين كلمة المرور",
        "description": "أدخل كلمة مرور الجديدة",
        "please_enter_password": "الرجاء إدخل كلمة مرور الجديدة",
        "please_confirm_password": "الرجاء تأكيد كلمة المرور",
        "password": "كلمة المرور",
        "confirm_password": "تأكيد كلمة المرور",
        "password_mathched": "تم تطابق كلمة المرور",
        "password_not_matched": "لم تطابق كلمة المرور",
        "submit": "تقديم"
    },
    "change_password": {
        "title": "تغيير كلمة المرور",
        "description": "أدخل كلمة مرور قديمة و جديدة",
        "please_enter_old_password": "الرجاء إدخال كلمة المرور القديمة",
        "please_enter_password": "الرجاء إدخال كلمة المرور الجديدة",
        "please_confirm_password": "الرجاء تأكيد كلمة المرور الجديدة",
        "old_password": "كلمة المرور القديمة",
        "new_password": "كلمة المرور الجديدة",
        "confirm_new_password": "تأكيد كلمة المرور الجديدة",
        "password_matched": "تم تطابق كلمة المرور",
        "password_not_matched": "لم تطابق كلمة المرور",
        "submit": "تقديم",
        "password_changed_successfully": "لقد تم تغيير كلمة المرور بنجاح"
    },
    "sign_up": {
        "title": "تسجيل العميل",
        "subtitle": "يرجى التسجيل لتتمكن من إنشاء و التحكم بطلباتك",
        "sign_up": "اشتراك",
        "first_name": "الاسم الأول",
        "last_name": "اسم العائلة",
        "phone": "رقم الهاتف",
        "email": "البريد الإلكتروني",
        "password": "كلمة المرور",
        "city": "المدينة",
        "company_name_optional": "اسم الشركة (مطلوبة للشركات)",
        "company_name": "اسم الشركة",
        "agree_to_emails": "أوافق على تلقي إشعارات تسويقية من أوغرام",
        "are_you_corporate_question": "هل انت شركة؟",
        "back_to_sign_in": "العودة إلى صفحة تسجيل الدخول",
        "first_name_required": "الرجاء إدخال اسمك الأول",
        "last_name_required": "الرجاء إدخال اسم العائلة",
        "industry_required": "Please select your industry",
        "employees_amount_tier_required": "Please select amount of employees",
        "phone_required": "الرجاء إدخال رقم هاتفك",
        "email_required": "الرجاء إدخال بريدك الإلكتروني",
        "company_name_required": "اسم الشركة مطلوب لمستخدمي الشركات",
        "city_required": "الرجاء إدخال مدينتك",
        "submitting": "يتم التقديم...",
        "choose_work_category_title": "ما الموظفين الذين تحتاجهم؟",
        "choose_work_category_subtitle": "اخبرنا عن وظيفتك الأولى",
        "create_new_order_title": "ابدأ بالتوظيف",
        "contact_details_title": "ماهي بيانات الاتصال للتواصل معك؟",
        "contact_details_subtitle": "اضف تفاصيل الاتصال الخاصة بك حتى نتمكن من التواصل معك",
        "how_to_reach_title": "كيف يمكننا التواصل معك؟",
        "next": "التالي",
        "phone_invalid": "الرجاء كتابة رقم هاتف صالح الاستخدام",
        "already_registered": "هل لديك حساب؟",
        "sign_in": "تسجيل الدخول",
        "consent": "بالضغط على زر \"الاشتراك، فإنك تمنح شركتنا الإذن باستخدام معلوماتك الشخصية لإنشاء الحساب و معالجة الطلبات.",
        "thank_you": "شكرا لك!"
    },
    "verify_email": {
        "title": "تحقق من بريدك الإلكتروني",
        "description": "لقد تم إرسال رمز التحقق إلى بريدك الإلكتروني",
        "we_sent_code_to_email": "تم إرسال الرمز إلى {{email}}. يرجة التحقق من صندوق الوارد وإدخال رمز التحقق لإكمال إعداد حسابك.",
        "please_enter_code": "الرجاء إدخال الرمز",
        "verification_code": "رمز التحقق",
        "verify": "تأكيد",
        "did_not_receive_email_question": "لم تستلم على رسالة إلى بريدك الإلكتروني؟ أعد إرسال الرمز خلال 3:00 دقيقة",
        "not_your_email_question": "ليس بريدك الإلكتروني؟",
        "change_email": "تغيير البريد الإلكتروني"
    },
    "applicant_details": {
        "n_shifts_completed": "{{count}} ‌مناوبات تم إكمالها",
        "information": "معلومات",
        "work_experience": "خبرات العمل",
        "education": "مستوى التعليمي",
        "get_next_applicant": "احصل على العميل التالي",
        "in": "في",
        "chief_og": "رئيس موظفي أوغرام",
        "skills": "المهارات",
        "added_as_favourite": "أصبح {{spName}} الآن ضمن قائمتنا المفضلة. وسوف نعطي {{spName}} الأولوية في خوارزمياتنا .",
        "chief": "<1>رئيس عملاء أوغرام</1><2>هم قادة فريق ذو خبرة، تم اختيارهم بعناية من اجل تنسيق افرقة عمال أوغرام بشكل افضل.</2>",
        "interview": "مقابلة",
        "view_answer": "عرض الإجابة",
        "job_history_with_you": "سجل العمل معك",
        "work_category_experience": "{{ workCategoryName }} Experience",
        "other_experience": "Other Experience"
    },
    "sp_rating": {
        "novice": "مبتدئ",
        "advanced_beginner": "مبتدئ متقدم",
        "competent": "عامل مؤهل",
        "proficient": "ماهر",
        "expert": "محترف"
    },
    "applicants_review": {
        "title": "مراجعة المتقدمين",
        "no_applicants_to_reivew": "لا يوجد متقدمين للمراجعة",
        "percent_filled": "{{percent}}% ممتلئ"
    },
    "job_cherry_picking": {
        "title": "تفاصيل الوظيفة",
        "accepted": "تم القبول",
        "rejected": "تم الرفض",
        "reject": "رفض",
        "accept": "قبول",
        "exp": "خبرة",
        "age": "عمر",
        "language": "لغة",
        "nationality": "جنسية",
        "years_of_experience": "{{count}} سنوات",
        "experience": "خبرة",
        "height": "الطول",
        "height_cm": "{{count}} سم",
        "years_old": "{{count}} سنوات",
        "total_applicants": "المتقدمين ({{count}})",
        "sort_by": "فرز حسب:",
        "rating": "التقيم",
        "see_more": "عرض المزيد",
        "confirmation": "تأكيد",
        "you_are_about_to_accept": "انت الآن على وشك قبول <1>{{name}}</1> للوظيفة",
        "you_are_about_to_reject": "انت الآن على وشك رفض <1>{{name}}</1> سوف يتم إزالته من القائمة",
        "confirm": "تأكيد",
        "cancel": "إلغاء",
        "jobID": "رقم التعريفي للوظيفة",
        "designation": "نوع الوظيفة",
        "location": "المكان",
        "required_quantity": "الكمية المطلوبة",
        "fulfillment": "تم إنجاز",
        "fulfillmentRatio": "{{percent}}%",
        "start_date": "تاريخ البدء",
        "end_date": "تاريخ الإنتهاء",
        "seniority_level": "مستوى الخبرة",
        "congratulation_exclamation": "مبروك!",
        "you_have_assigned_staff": "لقد قمت بتعيين جميع الموظفين المطلوبين",
        "done": "تم",
        "skill_match": "تطابق للمهارات",
        "chief_title": "عندنا شيء لك",
        "chief_subtitle": "أضف رئيس موظفي أوغرام إلى فريقك - مجاناً!",
        "chief_og": "رئيس موظفي أوغرام",
        "what_is_chief": "من هو رئيس موظفي أوغرام؟",
        "chief_definition": "رئيس موظفي أوغرام هو قائد الفريق، تم إختياره بعناية من قبلنا للمساعدة في إدارة فريق أكبر حجماً من عملاء أوغرام. على سبيل المثال، من خلال حل نزاعات وتنسيق اعضاء الفريق - دون أي تكلفة إضافية منك .\nوكل هذا <1>مجاناً بشكل كامل لك.</1>",
        "chief_button_label": "شيء جيد",
        "time": "الوقت",
        "applicant_assigned": "تم تعيين المتقدم!",
        "shortlisted": "قائمة المتأهلين"
    },
    "interview": {
        "applicants_finished": "لا يوجد المزيد من مراجعات ليتم الإجابة عليها",
        "applicant_id": "رقم التعريفي#{{id}}",
        "next_question": "السؤال التالي",
        "accept": "قبول",
        "assigned": "عُيّن",
        "next_applicant": "المتقدم التالي",
        "question_short": "سؤال{{index}}:",
        "rating_question_short": "تقييم سؤال{{index}}:",
        "recommended_to_hire": "ينصح بالتوظيف",
        "overall_rating": "التقييم الكامل",
        "video_player_not_supported": "ان متصفحك لا يدعم مشغل الفديو، الرجاء قم بتحديث المتصفح أو قم بإستخدام متصفح آخر.",
        "submitting_rating": "يتم ارسال التقييم...",
        "rating_applied": "تم قبول التقيم!"
    },
    "complete_registration": {
        "title": "تسجيل شركة",
        "legal_information": "معلومات قانونية",
        "field_required": "هذه الخانة مطلوبة",
        "yes": "نعم",
        "no": "لا",
        "company_name": "اسم الشركة",
        "trade_name_as_license": "الاسم التجاري حسب الرخصة التجارية",
        "trade_license_number": "رقم الرخصة التجارية",
        "legal_address": "العنوان القانوني",
        "po_box": "العنوان البريدي",
        "free_zone": "المنطقة الحرة",
        "lpo_required": "طلب الشراء المحلي مطلوب",
        "region": "المنطقة",
        "vat_certificate": "شهادة ضريبة القيمة المضافة",
        "upload_vat": "رفع شهادة ضريبة القيمة المضافة",
        "i_agree_with_terms": "أوافق على <1>سياسة الخصوصية</1>",
        "i_agree_with_company_pp": "أوافق على <1>سياسية الخصوصية للشركات</1>",
        "i_agree_with_company_terms": "أواقق على <1>الشروط و الأحكام</1>",
        "continue": "اكمل",
        "gr": {
            "company_name": "اسم الشركة",
            "address": "العنوان مع أسم الشارع - الرقم - المدينة",
            "tax_jurisdiction": "السلطة الضريبية (DOY)",
            "registry_number": "رقم سجل الشركة",
            "zip_code": "الرمز البريدي",
            "industry": "نوع الصناعة",
            "industry_retail": "تجارة التجزئة",
            "industry_hotels": "الفنادق",
            "industry_brc": "حانة/مطعم/مقهى"
        },
        "document_selected": "تم اختيار المستند",
        "document_submitted": "تم تقديم المستند"
    },
    "change_schedule_options": {
        "all_dates_from_date": "جميع الأيام من {{date}}",
        "date_only": "{{date}} فقط",
        "every_day_from_date": "كل{{day}} من{{date}}",
        "custom": "مخصص"
    },
    "onboarding_complete": {
        "title": "شكرا لك!",
        "description": "لقد تلقينا تفاصيل شركتك.",
        "done": "تم"
    },
    "sign_sla": {
        "title": "توقيع اتفاقيةمستوى الخدمة",
        "i_agree": "أنا موافق"
    },
    "home": {
        "hello_name": "اهلاً وسهلاً, <1>{{name}}</1>",
        "hello": "اهلاً وسهلاً",
        "shifts_attendance_review": "مراجع حضور المناوبات",
        "orders_to_confirm": "أوامر عليك التأكيد عليها",
        "rating_requests": "طلبات التقييم",
        "applicant_review": "مراجعة المتقدمون",
        "pending_payments": "المدفوعات المعلقة",
        "error_failed_to_load_statistics": "فشل تحميل الإحصائيات",
        "error_failed_to_load_home_page": "فشل تحميل على الصفحة الرئيسية",
        "coming_soon": {
            "hello": "اهلاً وسهلاً",
            "we_are_coming_soon": "نحن قادمون قريبا",
            "prepare_for_launch": "مرحبا! نحن نستعد للافتتاحية الكبيرة.",
            "on_demand_staff": "موظفون متوفرون حسب الطلب",
            "find_workers": "ابحث و ادير العمال ذوي الجودة العالية في منصة واحدة بسيطه و مركزية.",
            "short_term_contracts": "عقود قصيرة الأجل",
            "post_jobs": "قم بنشر وظائف مؤقتة أو طويلة الأجل وأملا مناوباتك التالية خلال 48 ساعة من التسجيل.",
            "incredible_savings": "مستوى توفير رائع",
            "save_money": "ادفع الاجر فقط مقابل ساعات العمل التي تم العمل بها"
        }
    },
    "interview_popup": {
        "title": "مقابلة فديو",
        "description": "قم بإدراج المقابلات عبر الفديو لتوفير الوقت لفريقك و الإنجاز بشكل اسرع.",
        "create_order_button": "إنشاء الطلب"
    },
    "sidebar": {
        "company": "شركة",
        "error_failed_to_load_sidebar": "فشل تحميل الشريط الجانبي. الرجاء المحاولة مرة اخرى",
        "create_order": "إنشاء الطلب",
        "register_corporate": "تسجيل شركة",
        "account": "حساب",
        "manage_locations": "إدارة الأماكن",
        "users": "المستخدمون",
        "knowledge_center": "مركز المعرفة",
        "home": "صفحة الرئيسية",
        "all_jobs": "جميع الوظائف",
        "attendance_review": "مراجعة الحضور",
        "orders": "طلبات",
        "applicants_review": "مراجعة المتقدمين",
        "rating_requests": "طلبات التقييم",
        "invoices": "الفواتير",
        "payments": "المدفوعات",
        "payment_methods": "طرق الدفع",
        "billing": "فواتير",
        "orders_uppercase": "الطلبات"
    },
    "all_jobs": {
        "title": "تقويم",
        "day": "يوم",
        "3_days": "يوم 3",
        "week": "أسبوع",
        "month": "شهر",
        "percent_filled": "{{count}}% مملوء",
        "start": "يبدء",
        "end": "ينتهي",
        "manage_shift": "إدارة المناوبة",
        "unsaved_changes": "لديك تغييرات لم تحفظ بعد، هل أنت متأكد من أنك تريد ان تغادر هذه الصفحة؟",
        "shift_canceled": "تم إلغاء المناوبة",
        "og_checked_in": "عميل أوغرام قد سجل حضوره",
        "og_checked_out": "عميل أوغرام قد سجل خروجه",
        "assigned_to": "كُلف إلى",
        "no_one": "واحد",
        "no_other": "اخرى",
        "empty_slot": "مكان شاغر",
        "select_OG": "اختار موظف اوغرام",
        "cancel": "الغاء",
        "save_changes_count": "حفظ التغييرات ({{count}})",
        "update_success": "تم التحديث بنجاح",
        "accepted": "تم القبول",
        "canceled": "تم الإلغاء",
        "completed": "تم الإكمال",
        "irrelevant": "ليس مهم",
        "pending": "قيد الانتظار",
        "started": "قد بدء",
        "extension_announcement": "يمكنك الآن تمديد الطلبات مباشرةً من هذه الصفحة. انقر على النقاط الثلاث بجوار الطلب واختر \"تمديد الطلب.\"",
        "current_week": "Go to current week",
        "filter_location": "Filter Location",
        "filter_designation": "Filter Designation",
        "sps_required": "{{amount}} OGs required",
        "bulk_apply": "Bulk Apply",
        "apply_to_sp_only": "Apply to {{spName}} only"
    },
    "job_shifts": {
        "title": "مناوبات الوظيفة",
        "change_schedule": "تغيير الجدول الزمني",
        "cancel_shift": "إلغاء المناوبة",
        "confirmation_question": "انت على وشك إلغاء الوظيفة لجميع عمال أوغرام، هل انت متأكد من ذلك؟",
        "i_have_capacity": "لدي مساحة",
        "select_reason": "حدد السبب",
        "other": "أخرى",
        "comment_here": "علق هنا (اختياري)",
        "cancel_job": "إلغاء الوظيفة",
        "cancel": "إلغاء",
        "set_start_time": "الرجاء ضبط وقت البدء",
        "set_end_time": "الرجاء ضبط وقت الإنتهاء",
        "start_time": "وقت البدء",
        "end_time": "وقت الإنتهاء",
        "apply_schedule": "تطبيق الجدول الزمني",
        "apply_on_days": "قدم على الايام المحددة من {{date}}",
        "confirm": "تأكيد",
        "pending": "قيد الانتظار",
        "started": "قد بدء",
        "check_in": "تسجيل الحضور",
        "check_out": "تسجيل المغادرة",
        "extend_shift": "تمديد المناوبة",
        "call": "اتصل",
        "whatsapp": "واتساب",
        "remove_from_favourites": "حذف من المفضلة",
        "add_to_favourites": "إضافة إلى المفضلة",
        "extra_time": "وقت إضافي",
        "percent_filled": "{{percent}}% مملوء",
        "for_the_job": "للوظيفة",
        "cancel_all_following_shifts": "إلغاء جميع المناوبات التالية",
        "block_ogrammer": "حظر عامل أوغرام"
    },
    "location_list": {
        "title": "المواقع",
        "add_location": "إضافة موقع",
        "location_required": "اسم الموقع مطلوب",
        "address_required": "العنوان مطلوب",
        "address_details_required": "تفاصيل معلومات العنوان مطلوبة",
        "region_required": "المنقطة مطلوبة",
        "location_name": "اسم الموقع",
        "region": "المنطقة",
        "location_address": "عنوان الموقع",
        "address_details": "تفاصيل العنوان الإضافية",
        "upload_location_image": "رفع صورة للموقع (اختياري)",
        "save": "حفظ",
        "delete": "حذف",
        "check_in_restrictions_title": "قيود تسجيل الحضور",
        "large_area": "مركز تجاري أو مجمع كبير",
        "no_restrictions": "تعطيل تقييد الموقع الجغرافي",
        "additional_details": "تفاصيل إضافية",
        "restrictions_info_modal_text": "الرجاء قم بتشغيل خيار “<strong>مركز تجاري أو مجمع كبير</strong>” إذا كان المطلوب من الموظف ان يعمل في منطقة كبيرة. سوف نقوم بتعديل مساحة منطقة تسجيل الحضور و تسجيل المغادرة. خيار <br/><br/>“<strong>تعطيل تقييد الموقع الجغرافي</strong>” سيؤدي إلى إزالة قيود الجغرافية في حالة العمل عن بعد أو إذا كان العمل سيتم في عدة اماكن.",
        "you_have_n_locations": "لديك {{locationsCount}} موقع"
    },
    "add_location": {
        "title": "إضافة موقع"
    },
    "edit_location": {
        "title": "تعديل الموقع"
    },
    "orders_list": {
        "title": "الطلبات",
        "empty_list_title": "ابدأ بالتوظيف اليوم!",
        "empty_list_description": "هل تبحث عن موظف بدوام جزئي؟\nأخبرنا عن متطلبات عملك.",
        "create_order": "إنشاء الطلب",
        "tab_title_pending": "قيد الانتظار",
        "tab_title_active": "فعال",
        "tab_title_completed": "قد اكتمل"
    },
    "order": {
        "order_number": "طلب #{{id}}",
        "order_summary": "ملخص الطلب",
        "jobs": "الوظائف",
        "cost": "التكلفة",
        "i_accept_terms": "أوافق على <1>شروط الخدمة</1>",
        "confirm": "تأكيد",
        "order_confirmed": "تم تأكيد الطلب",
        "thank_you_for_confirming_order": "شكرا على تأكيد الطلب.",
        "done": "تم",
        "payment_terms": "شروط الدفع",
        "complete_company_details": "اكمل تفاصيل الشركة لتغيير شروط الدفع",
        "change_terms": "تغيير الشروط",
        "location": "الموقع",
        "end_date": "تاريخ الإنتهاء",
        "start_date": "تاريخ البدء",
        "subtotal": "المجموع الفرعي",
        "transportations": "المواصلات",
        "vat_percent": "ضريبة القيمة المضافة ({{count}}%)",
        "estimated_total": "المجموع التقديري",
        "dates": "التواريخ",
        "days_count": "({{count}} يوم)",
        "hours": "ساعة",
        "hours_amount": "{{hours}} ساعة",
        "hours_per_day": "({{hoursPerDay}} س/يوم)",
        "rate": "معدل الاجر",
        "hour_rate": "{{currency}} {{hourRate}}/س",
        "transportation_cost": "{{currency}} {{cost}}/يوم",
        "category_schedule": "{{category}} جدول",
        "order_summary_days_count": "({{count}} ايام)",
        "hello_name": "مرحبا, <1>{{name}}</1>",
        "confirm_order": "قد تلقينا منك طلبًا جديدًا. للمتابعة، يرجى مراجعة وتأكيد التفاصيل أدناه.",
        "pending_rates_review": "طلبك قيد المراجعة، سوف يتم بإعلامك بمجرد تحديد معدل الأجور.",
        "send_for_review": "الإرسال للمراجعة",
        "under_review": "تحت المراجعة",
        "final_amount_will_be_based": "*سوف يتم تحديد المبلغ الإجمالي النهائي بناءً على الحضور الفعلي",
        "amend_order": "تعديل الطلب",
        "i_accept": "انا أوافق على",
        "terms_of_service": "شروط الخدمة",
        "select_amend_reason": "حدد سبب التعديل",
        "select_reason": "حدد السبب",
        "submitting": "يتم التقديم...",
        "continue": "اكمل",
        "cancel": "إلغاء",
        "quotation_download_title": "تحميل التسعيرة",
        "quotation_download_action_text": "تحميل",
        "quotation_download_in_progress_text": "يتم التنزيل...",
        "lpo_upload_title": "قم برفع وثيقة موقعه لطلب الشراء المحلي",
        "lpo_upload_action_text": "رفع",
        "lpo_upload_in_progress_text": "يتم الرفع...",
        "lpo_upload_success_message": "تم رفع وثيقة \"طلب شراء محلي\" بنجاح",
        "menu_download_quote": "تحميل اقتباس",
        "menu_upload_lpo": "رفع مستند \"طلب شراء محلي\"",
        "lpo_download_title": "تحميل \"طلب شراء محلي\"",
        "lpo_download_in_progress_text": "يتم التحميل...",
        "menu_duplicate_order": "نسخ الطلب",
        "draft_order_title": "مسودة الطلبات",
        "job_to_duplicate": "وظيفة للنسخ",
        "need_to_review_corporate_details": "قد تلقينا منك طلبًا جديدًا. للمتابعة، يرجى مراجعة واكمال تفاصيل الشركة",
        "corporate_details_are_being_reviewed": "نحن نقوم بمراجعة تفاصيل شركتك التي أرسلتها إلينا",
        "complete_company_details_link": "اكمل تفاصيل الشركة",
        "duplicate_order": "نسخ الطلب",
        "days": "الأيام",
        "to_end": "للنهاية",
        "suggest_extension": "نشكرك على اختيار خدماتنا. يمكنك تمديد طلبك للحصول على خدمات أو منتجات إضافية.",
        "extend_your_order": "تمديد الطلب الخاص بك"
    },
    "job_draft_category": {
        "title": "إنشاء طلب جديد",
        "tell_about_job": "أخبرنا عن الوظيفة",
        "tell_about_first_job": "أخبرنا عن اول وظيفة",
        "find_staff": "ابحث عن موظفين مؤهلين لكل احتياجاتيك"
    },
    "job_draft_details": {
        "title": "{{category}} وظيفة",
        "fill_job_details": "الرجاء املاء تفاصيل الوظيفة",
        "staff": "موظف",
        "quantity": "كمية",
        "location": "موقع",
        "start_date": "تاريخ البدء",
        "end_date": "تاريخ الإنتهاء",
        "days": "أيام",
        "start_time": "وقت البدء",
        "end_time": "وقت الإنتهاء",
        "requirements": "المتطلبات",
        "job_description": "وصف الوظيفة",
        "experience": "الخبرة",
        "dresscode": "الزي الرسمي",
        "additional_preferences": "التفضيلات الإضافية",
        "languages": "اللغات",
        "gender": "الجنس",
        "additional_documents": "مستندات إضافية",
        "video_interview": "مقابلة عن طريق الفديو",
        "manual_selection": "اختيار الموظفين يدويا",
        "accreditation": "التصديق مطلوب",
        "submitting": "يتم التقديم...",
        "save": "تقديم",
        "finish": "انهي",
        "next": "التالي",
        "create_duplicate": "حفظ",
        "step_n_of_m_steps": "{{objectNameLabel}} {{index}} من {{count}}",
        "work_category_required": "الرجاء اختيار فئة العمل",
        "quantity_required": "يرجى تحديد عدد الموظفين الذين تحتاجهم",
        "location_required": "يرجى تحديد الموقع",
        "schedule_type_required": "يرجى تحديد نوع جدولك",
        "same_time_prompt_required": "الرجاء إخبارنا إذا كانت جميع الايام لديها نفس وقت البدء/الإنتهاء",
        "start_date_required": "يرجى تحديد وقت البدء",
        "end_date_required": "يرجى تحديد وقت الإنتهاء أو ان تجعل الوظيفة مفتوحة الامد",
        "end_date_cannot_be_before_start_date": "لا يمكن ان يكون تاريخ الإنتهاء قبل تاريخ البدء",
        "start_time_required": "يرجى تحديد وقت البدء",
        "end_time_required": "يرجى تحديد وقت الإنتهاء",
        "select_one_date_at_least": "يرجى اختيار يوم واحد على الأقل",
        "fill_all_selected_dates": "يرجى ملء جميع المدخلات للتاريخ المحدد",
        "job_cant_start_early": "لا يمكن للوظيفة ان تبدء في اقل من 24 ساعة",
        "must_include_day_of_start_date": "يجب تضمين تاريخ يوم البدء",
        "must_include_day_of_end_date": "يجب تضمين تاريخ يوم الإنتهاء",
        "description_required": "يرجى ضبط الوصف",
        "description_too_long": "ان الوصف طويل جداً، يرجى جعله أقصر",
        "experience_required": "الرجاء اختيار مستوى الخبرة",
        "dresscode_required": "الرجاء اختيار الزي الرسمي",
        "languages_required": "حدد اللغات أو قم بحذف التصفية",
        "documents_required": "حدد المستندات أو قم بحذف التصفية",
        "interview_required": "حدد الأسئلة أو قم بحذف التصفية",
        "manual_selection_required": "حدد اختيار التحديد اليدوي أو قم بحذف التصفية",
        "accreditation_required": "حدد اختيار الاعتماد أو قم بحذف التصفية",
        "staff_amount_title": "كم عدد الموظفين الذين تحتاجهم؟",
        "staff_amount_subtitle": "حدد العدد الذي تحتاجه أو قم بكتابتها",
        "location_title": "اين تحتاج الموظفين؟",
        "location_subtitle": "حدد الموقع أو المكان الذي تحتاج فيه إلى الموظفين.",
        "location_add_new": "إضافة موقع جديد",
        "location_add": "إضافة موقع",
        "dates_title": "متى تحتاج إلى الموظفين؟",
        "dates_subtitle": "اختار تاريخ/تواريخ عندما تحتاج إليهم.",
        "dates_open_ended": "مفتوح (لا يوجد تاريخ إنتهاء)",
        "schedule_title": "هل سوف يعملون في نفس الأيام كل أسبوع؟",
        "schedule_subtitle": "على سبيل المثال، من الاثنين إلى الجمعة كل اسبوع.",
        "schedule_i_have_same_weekly": "لدي نفس الجدول كل اسبوع",
        "schedule_schedule_weekly": "الجدول الزمني على أساس أسبوعي",
        "schedule_i_need_schedule_daily": "أحتاج إلى الجدولة على أساس يومي",
        "schedule_schedule_daily": "الجدول الزمني على أساس يومي",
        "time_title": "ماهي الفترة الزمنية بالساعات المجدولة في العمل؟",
        "time_subtitle": "حدد الأيام التي تريد أن يعمل فيها الموظفين",
        "time_select_dates_schedule": "الرجاء تحديد التواريخ و نوع الجدول أولاً",
        "time_days_selected": "تم اختيار{{count}} يوماً",
        "time_dates_selected": "تم اختيار {{count}} تاريخ",
        "requirements_title": "Do the workers need a specific specialization?",
        "requirements_subtitle": "فحص الموظفين من خلال سؤالهم لأجل التحقق على مهاراتهم",
        "requirements_add_new": "إضافة متطلبات جديدة",
        "requirements_create": "إنشاء متطلبات العمل",
        "requirement_requirement": "متطلبات",
        "requirement_add_requirement": "إضافة متطلبات",
        "description_title": "تفاصيل الوظيفة",
        "description_subtitle": "أضف وصفاً للوظيفة لأجل الموظفين (اصف المهام و المسؤوليات المتوقعة من الموظفين)",
        "description_placeholder": "وصف تفاصيل الوظيفة",
        "experience_title": "ما مدى الخبرة التي تحتاجها للموظفين؟",
        "experience_subtitle": "حدد مستوى الخبرة الذي تريده لدى الموظفين",
        "experience_student": "طالب",
        "experience_beginner": "مبتدئ",
        "experience_junior": "مبتدئ",
        "experience_middle": "متوسط",
        "experience_senior": "خبير",
        "experience_hint": "*كلما ارتفعت خبرة الموظف، ارتفع اجره بالساعات.",
        "experience_years": "سنوات",
        "uniform_title": "هل لديك تفضيلات محدده للباس الرسمي؟",
        "uniform_subtitle": "أخبرنا إذا كنت تحتاج إلى ملابس رسمية أو غير رسمية",
        "uniform_dresscode": "اللباس الرسمي",
        "preferences_title": "هل لديك اي تفضيلات إضافية؟",
        "preferences_subtitle": "حدد قرارك في التفضيلات الإضافية.",
        "preferences_languages": "اللغة",
        "preferences_gender": "الجنس",
        "preferences_documents": "مستندات إضافية",
        "preferences_interview": "مقابلة فديو",
        "preferences_manual_staff_selection": "اختيار الموظفين يدويا",
        "preferences_accreditation_required": "الاعتمادية مطلوبة",
        "preferences_accommodation_offered": "سكن مؤمن",
        "accommodation_required": "السكن مطلوب",
        "accommodation_yes": "نعم",
        "accommodation_no": "لا",
        "boarding_required": "خدمات التغذية مطلوبة",
        "transport_accessibility_required": "المواصلات مطلوبة",
        "preferences_boarding_offered": "خدمات التغذية مؤمنة",
        "accommodation_title": "هل تؤمن خدمات التعذية لهذا الدور؟",
        "do_you_offer_accommodation": "هل تقدمون سكناً لهذا الدور؟",
        "no_boarding": "لا يوجد تأمين لخدمات التغذية",
        "half_boarding": "وجبة واحدة",
        "full_boarding": "خدمات تغذية كاملة",
        "languages_title": "ما هي اللغة التي تفضلها؟",
        "languages_subtitle": "اختار اللغات المحددة التي يجب أن يتحدث بها الموظفين",
        "gender_title": "ما هو تفضيلك للجنس؟",
        "gender_subtitle": "الرجاء ضع الجنس الذي تفضله إن وجد",
        "gender_male": "ذكر",
        "gender_female": "أنثى",
        "gender_no_preference": "لا",
        "documents_title": "ماهي متطلباتك للمستندات الإضافية؟",
        "documents_subtitle": "حدد أنواع المستندات الإضافية المطلوبة",
        "manual_selectinon_title": "هل ترغب في أن نقوم بختيار التلقائي لأفضل موظفي أوغرام للعمل؟",
        "manual_selectinon_subtitle": "إذا اخترت \"لا\"، فسوف يتعين لك ان تختار بنفسك موظفي أوغرام و لن تستفيد من خورازمية اختيار الموظفين الذكية لدينا",
        "manual_selectinon_yes": "نعم",
        "manual_selectinon_no": "لا",
        "accreditation_title": "هل يحتاج الموظفون إلى الاعتماد لدخول المكان أو الحدث؟",
        "accreditation_subtitle": "يرجى الملاحظة أن الاعتماد سيؤثر على وقت التنفيذ لدينا",
        "accreditation_yes": "نعم",
        "accreditation_no": "لا",
        "poc_title": "من سيتولى أمر الحضور؟",
        "poc_subtitle": "اختر شخصاً الذي سوف يكون نقطة الاتصال الرئيسية للوظيفة",
        "point_of_contact": "نقطة الاتصال",
        "add_poc": "إضافة نقطة اتصال جديدة",
        "select_location_first": "الرجاء تحديد موقع الوظيفة أولاً",
        "poc_required": "الرجاء تحديد جهة الاتصال",
        "start_date_must_be_at_least_today": "تاريخ البدء يجب ان يبدء اليوم على الأقل",
        "end_date_must_be_at_least_today": "تاريخ الإنتهاء يجب ان يبدء اليوم على الأقل",
        "greece_launch_info": "يرجى الملاحظة بإن الطلبات لا يمكن تقديمها إلا اعتباراً من 1 يونيو. فنحن نقوم بمعالجة الطلبات خلال 72 ساعة. اي طلبات قد تم تقديمها قبل هذا التاريخ لن يتم معالجتها. شكرا على تفهمكم",
        "select_document": "اختار مستند",
        "junior": "مبتدئ",
        "midSenior": "مستوى متوسط",
        "senior": "مستوى عالي",
        "student": "طالب",
        "beginner": "جديد",
        "interview_title": "ما هي المواضيع الرئيسية التي تريد ان تناقشها مع المتقدمين؟",
        "interview_subtitle": "سوف يقوم عامل أوغرام بتقديم تسجيلات الفديو للإجابة على الأسئلة",
        "interview_new_question": "إضافة سؤال جديد؟",
        "interview_question_form_title": "إضافة سؤال جديد",
        "interview_question_form_placeholder": "اكتب الوصف هنا",
        "interview_question_form_submit": "تقديم",
        "interview_question_created": "تم إنشاء السؤال!",
        "start_and_end_date_must_be_selected": "يجب ان يتم إختيار تاريخ البدء {{startDate}} وتاريخ الإنتهاء {{endDate}}",
        "what_are_the_shifts_timings": "أخبرنا عن الجدول الزمني لهذه الوظيفة:",
        "what_are_job_timings": "ما هي مواعيد العمل؟",
        "select_same_time": "حدد الوقت الذي تريد أن يعمل فيه الموظفين كل يوم",
        "start_and_ends_are_same": "المناوبات ستبدأ و تنتهي في نفس الوقت كل يوم",
        "time_vary": "تختلف المناوبات من يوم لآخر",
        "less_than_24h_warning": "سيبدا العمل في أقل من 24 ساعة وقد لا يكون لدينا الوقت الكافي لإنجازها.",
        "job_was_prefilled": "The form was pre-filled based on the last job data",
        "invited_quantity_more_than_required_quantity": "You have selected more OGs than required in the job. In this case, the first who accepts will be assigned to the job"
    },
    "order_requested": {
        "title": "شكرا لك!",
        "subtitle": "لقد تلقى فريقنا طلبك وسنقوم بالرد عليك قريبًا!",
        "done": "تم",
        "complete_registration": "اكمل التسجيل",
        "later": "لاحقاً"
    },
    "order_draft": {
        "title": "إنشاء طلب جديد",
        "jobs": "الوظائف",
        "confirm_delete_job": "هل انت متأكد من انك تريد حذف وظيفة {{name}} ?",
        "add_another_job": "+ أضف وظيفة أخرى",
        "add_another_job_with_plus": "أضف وظيفة أخرى",
        "add_first_job": "إضافة الوظيفة الأولى",
        "continue": "اكمل",
        "quantity": "الكمية",
        "days": "أيام",
        "days_amount": "{{count}} يوم",
        "location": "الموقع",
        "what_to_copy": "ما الوظيفة التي تفضل من {{category}} أن ترغب بأن تقوم النسخ منها ؟",
        "select_preferences": "الرجاء اختيار ما تحتاجه للنسخ إليه من التفضيلات",
        "schedule": "الجدول",
        "requirements": "المتطلبات",
        "job_description": "وصف الوظيفة",
        "seniority_level": "مستوى الخبرة",
        "dresscode": "الزي الرسمي",
        "language": "اللغة",
        "gender": "الجنس",
        "documents": "مستندات إضافية",
        "manual_selection": "اختيار الموظفين يدويا",
        "accreditation_requried": "الاعتمادية مطلوبة",
        "accommodation_offered": "سكن مؤمن",
        "interview_required": "مقابلة فديو",
        "boarding_offered": "خدمات التغذية مؤمنة",
        "duplicate_job": "نسخ الوظيفة",
        "blank_job": "وظيفة فارغة",
        "workers": "عمال",
        "jobs_count": "{{count}} وظيفة",
        "public_transport_accessibility": "إمكانية الوصول إلى وسائل النقل العام",
        "public_transport_accessibility_accessible": "نعم، إنه بالقرب من محطة حافلات / محطة مترو",
        "public_transport_accessibility_not_accessible": "لا، ليس من السهل الوصول إليه عن طريق وسائل النقل العامة",
        "is_location_accessible_by_transport": "هل يمكن الوصول إلى هذا الموقع عن طريق وسائل النقل العام؟",
        "tell_if_location_is_reachable": "أخبرنا إذا كان من الممكن الوصول إلى موقعك بالحافلة أو المترو.",
        "pricing": {
            "best_price_1": "أفضل سعر مضمون",
            "best_price_2": "نموذج التسعير لدينا مصمم بشكل ديناميكي، مع الأخذ في الاعتبار الطلب الحالي في السوق.",
            "best_price_3": "هذا الأسلوب يتيح لنا أن نقدم لك باستمرار أفضل قيمة وخدمات تتوقعها منا. تواصل معنا في أي وقت للحصول على مزيد من المعلومات.",
            "best_price_4": "اطلب مكالمة من قسم فريق المبيعات",
            "estimated_total": "المجموع التقديري*",
            "type": "النوع",
            "hours": "ساعات",
            "hourly_rate": "معدل السعر بالساعة"
        },
        "callback_request_sent": "لقد تم إرسال طلبك بنجاح",
        "end_date": "تاريخ الإنتهاء",
        "start_date": "تاريخ البدء",
        "updated": "تم تحديث",
        "n_hours": "{{hours}} ساعات",
        "edit_job": "تعديل على الوظيفة"
    },
    "order_extension": {
        "how_to_extend": "كيف تريد تمديد تواريخ الوظائف؟",
        "you_can_extend_order": "لديك القدرة على تمديد تواريخ الوظائف",
        "same_extension_date": "تاريخ الإنتهاء الجديد هو نفسه لجميع الوظائف",
        "different_extension_dates": "تمديد الوظائف لفترات مختلفة",
        "for_how_long_extend": "إلى متى تريد ان تمدد وظائفك؟",
        "do_not_extend": "لا تمدد",
        "select_date": "حدد التاريخ",
        "select_start_date": "حدد تاريخ البدء",
        "select_end_date": "حدد تاريخ الإنتهاء",
        "extend_order": "تمديد الطلب",
        "schedule_description": "الأيام التي تحتاج إليها الموظفين للعمل",
        "edit": "تعديل",
        "done": "تم",
        "full_schedule": "الجدول الكامل",
        "current_end_date": "Current End Date:",
        "select_sps": "Select Ogrammers",
        "select_sps_to_extend": "Select Ogrammers you want to extend"
    },
    "order_confirmed": {
        "title": "تم تأكيد الطلب",
        "description": "شكرا لك على تأكيد الطلب.",
        "done": "تم"
    },
    "attendance_manager_form": {
        "first_name": "الاسم الأول",
        "last_name": "اسم العائلة",
        "phone": "رقم الهاتف",
        "email": "البريد الإلكتروني",
        "job_role": "الدور الوظيفي",
        "save": "حفظ",
        "edit_attendance_manager": "تعديل مدير الحضور",
        "add_attendance_manager": "إضافة مدير الحضور"
    },
    "attendance_manager_list": {
        "title": "مدراء الحضور",
        "add_manager": "إضافة مدير",
        "edit": "تعديل"
    },
    "company_form": {
        "title": "تفاصيل الشركة",
        "saved": "تم الحفظ",
        "company_name": "اسم الشركة",
        "company_name_as_in_license": "اسم الشركة كما هو مذكور في الرخصة التجارية",
        "address": "العنوان",
        "po_box": "صندوق البريد",
        "vat_certificate": "شهادة ضريبة القيمة المضافة",
        "trade_license": "الرخصة التجارية",
        "finance_first_name": "الاسم الأول لموظف المالية",
        "finance_last_name": "اسم العائلة لموظف المالية",
        "finance_phone": "هاتف موظف المالية",
        "finance_email": "البريد الإلكتروني لموظف المالية",
        "extension": "إضافة",
        "save": "حفظ"
    },
    "invoice": {
        "invoice_#": "فاتورة#{{index}}",
        "export_pdf": "تصدير ملف (PDF)",
        "exporting": "جاري التصدير...",
        "report_issue": "الإبلاغ عن مشكلة",
        "details": "تفاصيل",
        "invoice_number": "رقم الفاتورة",
        "date": "التاريخ",
        "due_date": "تاريخ الاستحقاق",
        "status": "الحالة",
        "pending": "قيد الانتظار",
        "paid": "تم الدفع",
        "costs": "التكاليف",
        "subtotal": "المجموع الفرعي",
        "transportation": "وسائل النقل",
        "vat_percent": "ضريبة القيمة المضافة({{count}}%)",
        "total": "المجموع",
        "breakdown": "التفاصيل",
        "pay_now": "سدد الآن",
        "total_shifts": "مجموع المناوبات",
        "total_hours": "مجموع الساعات",
        "total_ogs": "مجموع عمال أوغرام",
        "shifts": "المناوبات",
        "hours": "الساعات",
        "amount": "المبالغ",
        "report_issue_modal_title": "الإبلاغ عن مشكلة",
        "report_issue_message_required": "الرسالة مطلوبة",
        "report_issue_message_placeholder": "يرجى وصف مشكلتك",
        "report_issue_submit": "تقديم",
        "report_issue_cancel": "إلغاء",
        "issue_was_reported": "تم الإبلاغ عن مشكلتك",
        "issue_is_submitting": "يتم التحميل..."
    },
    "invoice_card": {
        "invoice_#": "رقم #{{index}}",
        "due_days_ago": "تستحق منذ {{count}} ايام",
        "due_today": "تستحق اليوم",
        "due_tomorrow": "تستحق غداً",
        "due_days_ahead": "تستحق في {{count}} أيام القادمة"
    },
    "invoice_ogrammer": {
        "og_details": "تفاصيل موظف أوغرام",
        "job": "الوظيفة",
        "location": "الموقع",
        "shifts": "المناوبات",
        "hours": "الساعات",
        "amount_due": "المبلغ المستحق",
        "shift_details": "تفاصيل المناوبات",
        "day": "يوم",
        "approved_by": "تمت الموافقة من قبل"
    },
    "profile_form": {
        "title": "تعديل الملف الشخصي",
        "first_name_required": "الاسم الأول مطلوب",
        "last_name_required": "اسم العائلة مطلوب",
        "phone_required": "رقم الهاتف مطلوب",
        "email_required": "البريد الإلكتروني مطلوب",
        "upload_photo": "رفع الصورة",
        "private": "خاص",
        "corporate": "شركة",
        "register_corporate": "تسجيل شركة",
        "first_name": "الاسم الأول",
        "last_name": "اسم العائلة",
        "phone": "رقم الهاتف",
        "email": "البريد الإلكتروني",
        "save": "حفظ",
        "cancel": "إلغاء",
        "sign_out": "تسجيل الخروج",
        "agree_to_emails": "أوافق على تلقي إشعارات التسويق من أوغرام"
    },
    "rating_request_list": {
        "title": "طلبات التقييم",
        "empty_list": "لا توجد طلبات معلقة"
    },
    "rating_request_panel": {
        "title": "طلبات التقييم",
        "action": "قيم الآن"
    },
    "job_rating": {
        "title": "تقييم اداء الوظيفة",
        "how_was_experience_question": "كيف كانت تجربتك بشكل عام؟",
        "please_rate_team": "يرجى تقييم جميع أعضاء الفريق",
        "tell_us_about_ogs": "اخبرنا المزيد عن أعضاء أوغرام",
        "tell_us_which_stood_out": "أخبرنا اي منهم برز اكثر، و اي منهم يحتاج العمل على تحسين اداه",
        "confirm": "تأكيد",
        "comment": "تعليق",
        "provide_overall_rating": "يرجى تقديم تقييم شامل",
        "provide_og_rating": "يرجى تقديم تقييم لعضو أوغرام",
        "provide_ogrammers_rating": "يرجى تقديم تقييم لأعضاء أوغرام",
        "thank_you_for_submitting": "شكرا لك على تقديم التقييم!"
    },
    "shifts_attendance_list": {
        "title": "مراجعة الحضور",
        "empty_list": "لا يوجد تقارير حضور معلقة",
        "review_now": "قم بالمراجعة الآن"
    },
    "shifts_attendance_details": {
        "attended_question": "حضر؟",
        "attended": "حضر",
        "not_attended": "لم يحضر",
        "did_not": "لم",
        "confirm_amount": "تأكيد({{count}})"
    },
    "shift_attendance_common": {
        "are_you_sure_og_worked_n_hours": "هل أنت متأكد من أن {{name}} عمل لمدة {{hours}} ساعات؟ إنه أقل من {{difference}} ساعة من المدة المحجوزة.",
        "no_show": "عدم الظهور",
        "limited_time_to_confirm": "وقت محدد للتأكيد",
        "you_have_n_to_review": "لديك {{time}} لمراجعة حضور ليوم معين. إذا لم تفعل ذلك فأخبرنا بذلك خلال {{time}}, سوف نعتبر أي موظف سجل حضوره انه مؤكد الحضور.",
        "i_understand": "قد فهمت",
        "n_minutes": "{{count}} دقيقة",
        "n_hours": "{{hours}} ساعة",
        "select_reason": "اختار السبب",
        "update": "تحديث",
        "cancel": "إلغاء",
        "hours_left": "{{count}} ساعة متبقية",
        "check_in": "تسجيل الحضور",
        "check_out": "تسجيل المغادرة",
        "update_hours": "تحديث {{count}} ساعات",
        "add_hours": "إضافة {{count}} ساعة",
        "shift_confirmed": "تم تأكيد المناوبة",
        "thanks_for_confirming": "شكرا لك على تأكيد حضور أعضاء أوغرام.",
        "done": "تم",
        "day_off": "يوم عطلة",
        "total_hours": "مجموع الساعات",
        "shift_duration_too_long": "لا يجب ان تتجاوز المناوبة مدة 12 ساعة"
    },
    "user_management": {
        "users": "المستخدمون",
        "add_new_user": "إضافة مستخدم جديد",
        "invite_user": "دعوة مستخدم",
        "confirm_deletion_question": "هل أنت متأكد أنك تريد حذف جهة الاتصال هذه؟",
        "enter_first_name": "الرجاء إدخال الاسم الأول",
        "enter_last_name": "الرجاء إدخال اسم العائلة",
        "enter_phone": "الرجاء إدخال رقم الهاتف",
        "enter_email": "الرجاء إدخال البريد الإلكتروني",
        "choose_role": "الرجاء اختيار الدور",
        "choose_location": "الرجاء اختيار الموقع",
        "first_name": "الاسم الأول",
        "last_name": "اسم العائلة",
        "phone": "رقم الهاتف",
        "email": "البريد الإلكتروني",
        "invite_user_exclamation": "قم بدعوة مستخدم الآن!",
        "job_role": "الدور الوظيفي",
        "location": "الموقع",
        "save": "حفظ",
        "delete": "حذف",
        "cancel": "إلغاء",
        "you_have_n_users": "لديك {{count}} مستخدمون",
        "add_user": "إضافة مستخدم جديد",
        "or": "أو",
        "invite_existing_user": "دعوة مستخدم موجود",
        "invitation_confirmed": "تم تأكيد الدعوة!",
        "invitation_alert_text": "تم إرسال الدعوة بنجاح. سيتم إضافة المستخدم إلى شركتك بعد قبوله للدعوة.",
        "users_tab_active_users": "المستخدمون",
        "users_tab_invitations": "الدعوات",
        "role_admin": "مسؤول",
        "role_owner": "المالك",
        "role_manager": "مدير",
        "role_finance": "المالية"
    },
    "onboarding": {
        "knowledge_center": "مركز المعرفة",
        "create_password": "إنشاء كلمة المرور",
        "register_corporate": "تسجيل شركة",
        "click_here_to_close": "اضغط هنا للإغلاق"
    },
    "lead_widget": {
        "looking_for_staff": "هل تبحث عن\nموظفين بدوام جزئي؟",
        "hire_now": "قم بالتوظيف الآن"
    },
    "uncompleted_actions_carousel": {
        "looking_for_staff": "هل تبحث عن موظفين بدوام جزئي؟",
        "hire_now": "قم بالتوظيف الآن",
        "edit_company": "قم بتوفير تفاصيل الشركة\n لفتح طرق دفع مرنة",
        "start_edit_company": "ابدأ الآن",
        "add_poc": "تعيين الأدوار \nلأعضاء فريقك",
        "start_add_poc": "ابدأ الآن",
        "dismiss_add_poc": "تخطي",
        "error_failed_to_load": "فشل في تحميل كاروسيل",
        "connect": "ربط",
        "connect_your_bank_account": "قم بربط حسابك بالبنك الخاص بك",
        "become_affiliate": "قم بدعوة موظفيك للانضمام إلى أوغرام"
    },
    "not_authorized_error": {
        "title": "تم رفض الدخول",
        "description": "ليس لديك الإذن للوصول إلى الصفحة المطلوبة...",
        "sign_in": "تسجيل الدخول",
        "toast_title": "Your session has expired. Please log in again to continue."
    },
    "page_not_found": {
        "title": "لم يتم العثور على الصفحة!",
        "description": "يبدو أن الصفحة التي تبحث عنها غير موجودة...",
        "go_back": "العودة"
    },
    "permission_error": {
        "title": "ليس لديك الإذن لعرض هذه الصفحة",
        "back": "العودة"
    },
    "too_many_attempts": {
        "title": "محاولات كثيرة جداً!",
        "description": "يرجى المحاولة مرة أخرى بعد قليل.",
        "ok": "حسناً"
    },
    "notifications": {
        "title": "إشعارات",
        "load_more": "تحميل المزيد",
        "no_more_notifications": "لا يوجد إشعارات",
        "no_notifications_yet": "لا يوجد إشعارات حتى الآن",
        "error_failed_to_load_notifications": "فشل في تحميل الإشعارات. يرجى المحاولة مرة أخرى"
    },
    "home_onboarding": {
        "title": "الصفحة الرئيسية",
        "intro_title": "اهلاً وسهلاً في أوغرام!",
        "intro_description": "المنصة الذكية التي تستخدمها الشركات والأفراد لتلبية احتياجاتهم من التوظيف بدوام جزئي .",
        "intro_footer": "عندما تستخدم احد المميزات لأولى مرة، ستظهر لك رسالة مشابهة لتقوم بإرشداك.",
        "hire_title": "توظيف موظفين بدوام جزئي",
        "hire": "تتيح لك ميزة \"توظيف الآن\" حجز الموظفين بدوام جزئي. وبهذه الطريقة يمكنك إخبارنا، بماذا و أين و متى تحتاجهم و تريدهم.",
        "attendance_title": "مكان مراجعة الحضور",
        "attendance": "هذا هو المكان الذي يمكنك فيه مراجعة تقارير الحضور الرقمية و التوقيع عليها. يشير الرقم الموجود في المربع إلى التقارير المعلقة التي يجب مراجعتها.",
        "orders_title": "مكان تأكيد الطلبات",
        "orders": "عندما يتطلب طلب الموظفين التسعير اليدوي، نحتاج إلي تأكيدك النهائي قبل أن نتمكن من نشر الموظفين. هنا يمكنك الوصول إلى جميع الطلبات المعلقة.",
        "ratings_title": "مكان طلبات التقييم",
        "ratings": "تعليقاتك مهمة جداً بالنسبة لنا ويتم الاستفادة منها من خلال التكنولوجيا المتطورة التي تعمل على الارتقاء بالخدمة باستمرار. إليك المكان الذي يمكنك من خلاله تقييم الخدمة.",
        "applicants_review_title": "مكان المتقدمين للوظائف",
        "applicants_review": "تختار المنصة الموظفين و تنشرهم تلقائيا. يمكنك ايضاً اختيار الموظفين بناءً على خبرتهم. هنا يمكنك مراجعة المرشحين و اختيارهم.",
        "outro": "هل أنت مستعد لتبدء؟\nيمكنك دائما الوصول إلى \"مركز المعرفة\" من قائمة الصفحة الرئيسية أو بالضغط فوق علامة الأستفاهم \"؟\" كلما اختجت إلى إرشادات."
    },
    "all_jobs_onboarding": {
        "title": "جميع الوظائف",
        "jobs_list_title": "قائمة الوظائف",
        "jobs_list": "\"كل الوظائف\" عبارة عن قائمة بالوظائف المحجوزة لديك مقسمة حسب الأيام. بالنقر على الوظيفة، تستطيع رؤية قائمة موظفي أوغرام المعينين.",
        "job_details_title": "تفاصيل الوظيفة",
        "job_details": "في صفحة الوظيفة، يمكنك تسجيل حضور الموظفين، تمديد أو إلغاء مناوبات العمل. و تستطيع إضافة موظف أوغرام إلى المفضلة يضمن لك اختياره في المرة القادمة.",
        "change_schedule_title": "تغير الجدول",
        "change_schedule": "يمكنك تعديل جدول مناوبات العمل لديك. فإن تحديث الجدول أمراً هما للغاية، ليمكن موظفي أوغرام استخدام تطبيقهم لتسجيل الحضور.",
        "bulk_schedule_change_title": "تغيير جداول العمل",
        "bulk_schedule_change": "قد يتم تغيير الجدول الزمني ليوم محدد أو لعدة أيام. يرجى ملاحظة أنه لا يمكن تقليل مدة المناوبة.",
        "cancel_shift_title": "إلغاء نوبة عمل",
        "cancel_shift": "تستطيع إلغاء مناوبة عمل لعضو فردي لموظف أوغرام؛ بالإضافة إلى منعه من التقدم بطلب العمل لجميع المناوبات المستقبلية."
    },
    "attendance_review_onboarding": {
        "title": "مراجعة الحضور",
        "jobs_pending_review_title": "وظائف قيد انتظار طلبات التوظيف",
        "jobs_pending_review": "يتيح لك قسم \"مراجعة الحضور\" تعديل وتأكيد حضور أعضاء موظفي أوغرام على شكل يومي. سيتم محاسبك وفقاً للوقت المسجل.",
        "attendance_details_title": "تفاصيل الحضور",
        "attendance_details": "صفحة الوظيفة تعرض لك قائمة التي تم تسجيل فيها أوقات موظفي أوغرام المعينون. يمكنك تصحيح الوقت بالنقر عليها.",
        "attendance_status_title": "تغيير حالة الحضور",
        "attendance_status": "يمكنك ايضاً تصحيح حالة حضور موظفي أوغرام إذا تم بالخطأ وضع علامة انه لم يحضر.",
        "attendance_time_title": "تغيير وقت الحضور",
        "attendance_time": "سيتم مطالبتك بإدخال توقيت الحضور الصحيح، الذي سيتم ضبطه على الساعات المجدولة بشكل افتراضي.",
        "not_attended_reason_title": "تغيير سبب \"عدم الحضور\"",
        "not_attended_reason": "إذا لم يحضر أحد أعضاء أوغرام، يمكنك أيضاً تغيير السبب؛ سواء كان يوم إجازة أو إجازة مرضية.",
        "confirmation_time_title": "تأكيد الوقت",
        "confirmation_time": "من المهم للغاية ان تأكد الحضور بانتظام حيث انه سيتم تأكيده تلقائيا خلال 72 ساعة. في حالة فشل القيام بذلك سيتم محاسبتك وفقا لذلك."
    },
    "order_confirmation_onboarding": {
        "title": "الطلبات",
        "pending_confirmation_title": "طلبات في انتاظر التأكيد",
        "pending_confirmation": "يحتوي قسم \"الطلبات\" على قائمة بالطلبات المحجوزة مسبقاً والتي تنتظر منك مراجعتها وتأكيدها.",
        "order_confirmation_title": "تأكيد الطلب",
        "order_confirmation": "في صفحة تأكيد الطلب المعلقة، يرجى مراجعة تفاصيل الطلب والأسعار النهائية بعناية. سيتم توزيع الموظفين بمجرد تأكيد الطلب.",
        "order_amend_title": "تعديل الطلب",
        "order_amend": "إذا تغيرت متطلباتك أو كنت قلق بشأن الميزانية، فلا تتردد في تعديل المتطبات."
    },
    "order_creation_onboarding": {
        "title": "إنشاء الطلب",
        "select_designation_title": "اختار نوع الوظيفة",
        "select_designation": "تبدأ عملية إنشاء الطلب باختيار تسمية للوظفية الأولى. ويمكنك اختيار تسميات إضافية بمجرد إنشاء الوظيفة الأولى.",
        "job_details_title": "تفاصيل الوظيفة",
        "job_details": "بعد اختيارك للمسمى الوظيفي، يمكنك ملء تفاصيل الوظيفة بالبدء بالكمية المطلوبة.",
        "job_duration_title": "مدة الوظيفة",
        "job_duration": "إذا كانت الوظيفة متكررة وليس لها تاريخ إنتهاء، فبإمكانك إزالة تاريخ الانتهاء وإبقاء الوظيفة مفتوحة.",
        "scheduling_options_title": "خيارات الجدولة",
        "scheduling_options": "إذا كان جدول وظيفتك يتكرر كل أسبوع، فعليك اختيار الخيار \"أسبوعي\" وأدخل التوقيتات لكل يوم من أيام الأسبوع. إذا كان خلاف ذلك فيمكنك جدولة العمل على أساس يومي",
        "job_requirements_title": "متطلبات الوظيفة",
        "job_requirements": "يمكنك تحديد المتطبات من قائمة محددة مسبقاً أو إضافة مهارات/مهام محددة مطلوبة للوظيفة.",
        "shifts_breakdown_title": "تفاصيل المناوبات",
        "shifts_breakdown": "بمجرد تحديدك لتفاصيل وظيفتك الأولى سوف يتم إنشاء مسودة للطلبات. تستطيع إضافة المزيد من الوظائف/المسميات إلى الطلب بمجرد الضغط على \"إضافة وظيفة أخرى\".",
        "duplicate_job_title": "نسخ الوظيفة",
        "duplicate_job": "إذا كنت تريد إنشاء وظيفة أخرى بنفس أو مشابهة المتطلبات، فيمكنك الضغط على زر النسخ للوظيفة. سوف تتمكن من تحديد التفاصيل التي تريد نسخها.",
        "send_for_review_title": "إرسال للمراجعة",
        "send_for_review": "بالضغط على زر \"إرسال للمراجعة\"، سترسل الطلب إلى فريق المبيعات لدينا للمراجعة. وسنقوم بتسعير طلبك ثم اعلامك لتحصل على التأكيد النهائي."
    },
    "applicants_review_onboarding": {
        "title": "مراجعة المتقدمين",
        "pending_applicant_review_title": "وظائف في انتظار مراجعة المتقدمين",
        "pending_applicant_review": "يحتوي قسم \"مراجعة المتقدمين\" على قائمة بالوظائف التي تحتوي على المتقدمين في حالة انتظار المراجعة. بمجرد اكتمال الوظيفة سوف ستختفي من هذه القائمة.",
        "applicant_list_title": "قائمة المتقدمين للوظائف",
        "applicant_list": "تعرض صفحة الوظيفة قائمة بموظفي أوغرام الذين تقدموا بطلب الوظيفة. يمكنك قبولهم أو رفضهم فوراً أو قم مراجعة تفاصيل ملفاتهم الشخصية.",
        "applicant_details_title": "تفاصيل مقدم الطلب",
        "applicant_details": "تعرض صفحة تفاصيل موظف أوغرام تصنيفهم و أفضل المهارات و مستوى الخبرة و السيرة الذاتية وخبرة العمل و مستوى التعليمي."
    },
    "locations_onboarding": {
        "title": "المواقع",
        "location_list_title": "قائمة المواقع",
        "location_list": "يحتوي قسم \"المواقع\" على قائمة بمواقع منافذ البيع الخاصة بك.",
        "location_details_title": "تفاصيل الموقع",
        "location_details": "يرجى التأكد من دقة موقعك على الخريطة. لأن موظفي أوغرام لن يتمكنوا من تسجيل حضورهم خارج النقطة المحددة."
    },
    "users_onboarding": {
        "title": "المستخدمون",
        "user_list_title": "قائمة المستخدمين",
        "user_list": "يتيح لك قسم \"المستخدمون\" التحكم في من لديه حق الوصول إلى شركتك. يمكنك إنشاء مستخدمين جدد أو دعوة مستخدمين موجودين.",
        "user_details_title": "تفاصيل المستخدم",
        "user_details": "يمكنك تعيين أحد الأدوار التالية: مسؤول، مدير الحضور، مسؤول المالية. ويمكن تحديد الأدوار حسب الموقع."
    },
    "rating_requests_onboarding": {
        "title": "طلبات التقييم",
        "pending_feedback_title": "في انتظار تعليق على الوظائف",
        "pending_feedback": "يحتوي قسم \"طلبات التقييم\" على قائمة بالوظائف التي تتطلب تعليقاتك. بعد الانتهاء من الوظيفة، نود منك تقييم تجربتك.",
        "rating_details_title": "تفاصيل تقييم الوظيفة",
        "rating_details": "يمكنك تقييم تجربتك الوظيفية الإجمالية، وكذلك ايضا تقييم أفراد موظفي أوغرام. ويحق لك تقديم الثناء و القاء الضوء على المشكلات و ترك التعليقات."
    },
    "invoices_onboarding": {
        "title": "الفواتير",
        "invoice_list_title": "قائمة الفواتير",
        "invoice_list": "تحتوي \"الفواتير\" على قائمة لفواتيرك الحالية و السابقة. إذا كانت أي فاتورة في حالة \"قيد الانتظار\"، فهذا يعني أننا نتوقع سداداً منك.",
        "invoice_breakdown_title": "تفاصيل الفاتورة",
        "invoice_breakdown": "تحتوي صفحة الطلب على ملخص بالإضافة إلى تفصيل للوظائف. من خلال توسيع خيار الوظائف، ستتمكن من العثور على ملخص المناوبات مقسمة حسب موظفي أوغرام.",
        "shifts_breakdown_title": "تفاصيل فوترة المناوبات",
        "shifts_breakdown": "من خلال الضغط على موظف أوغرام، يمكنك رؤية عدد الساعات التي عمل بها موظف أوغرام في كل يوم.",
        "export_invoice_title": "تصدير الفاتورة",
        "export_invoice": "من قائمة الفاتورة، يمكنك تحميل تفاصيل الفاتورة بصيغة PDF؛ بالإضافة إلى الإبلاغ عن أي مشكلات متعلقة بالفاتورة."
    },
    "duplicate_order": {
        "choose_jobs_main_title": "أولاً، حددالوظائف التي تريدها",
        "choose_jobs_secondary_title": "اختر وحدد الوظائف التي تحتاج إلى تمديدها",
        "choose_jobs_confirm": "تأكيد",
        "duplicate_job_subtitle": "يرجى مراجعة تفاصيل الوظيفة",
        "duplicate_order": "نسخ الطلب"
    },
    "payment": {
        "payment": "دفع",
        "card_payment": "الدفع",
        "was_successful": "شكرا لك، لقد تم الدفع بنجاح.",
        "email_was_sent_to": "لقد تم إرسال بريد إلكتروني للتأكيد إلى ",
        "can_go_to_payments_list_page": "يمكنك الذهاب إلى صفحة المدفوعات والتحقق من حالتها هناك",
        "transaction_id": "رقم العملية",
        "date": "التاريخ",
        "payment_method": "طريقة الدفع",
        "amount": "المبلغ",
        "status": "الحالة",
        "go_to_payments_list_page": "الذهاب إلى المدفوعات",
        "status_unpaid": "غير مدفوعة",
        "status_processing": "يتم المعالجة",
        "status_paid": "مدفوع",
        "status_cancelled": "ألغيت",
        "status_refunded": "تم استرداد المبلغ",
        "payment_method_card_title": "بطاقة",
        "payment_method_card_wide_title": "الدفع بالبطاقة",
        "payment_method_card_description": "بطاقة ائتمان/بطاقة سحب",
        "payment_method_lean_description": "التحويل البنكي الفوري",
        "processing_running": "يتم المعالجة...",
        "save_card_for_future": "احفظ هذه البطاقة للمعاملات المستقبلية",
        "all_transactions_are_secured": "جميع المعاملات آمنة ومشفرة",
        "details": "تفاصيل",
        "payment_number": "رقم معاملة الدفع",
        "due_date": "تاريخ الاستحقاق",
        "invoices": "الفواتير",
        "total_payment": "المبلغ الإجمالي ",
        "pay_now": "ادفع الآن",
        "authorize": "وافق الآن",
        "pending": "قيد الانتظار",
        "processing": "يتم المعالجة",
        "completed": "اكتمل",
        "invoice": "فاتورة",
        "payment_details_title": "دفعة#{{paymentId}}",
        "payments": "المدفوعات",
        "payment_methods": "طرق الدفع",
        "select_payment_method": "اختار طريقة الدفع التي تريد استخدامها",
        "continue": "اكمل",
        "pay": "دفع",
        "payment_completed": "تم الدفع",
        "payment_processing": "جاري معالجة الدفع",
        "updating_status": "تحديث الحالة...",
        "empty_pending_list": "لا توجد مدفوعات معلقة",
        "empty_processing_list": "لا يوجد مدفوعات يتم معالجتها",
        "empty_completed_list": "لا يوجد مدفوعات تم إكمالها",
        "back_to_order": "العودة إلى الطلب",
        "overdue_balance": "الرصيد المستحق",
        "payment_method_wire_transfer_title": "التحويل البنكي",
        "payment_method_wire_transfer_description": "التحويل البنكي",
        "text_copied": "تم نسخ النص إلى الحافظة",
        "checkout": "الدفع",
        "wire_transfer_details": "تفاصيل التحويل البنكي",
        "wire_transfer_amount": "القيمة",
        "wire_transfer_bank_account_name": "اسم الحساب",
        "wire_transfer_bank_account_bank_name": "اسم البنك",
        "wire_transfer_bank_account_number": "رقم الحساب",
        "wire_transfer_bank_account_iban": "IBAN",
        "wire_transfer_bank_account_bank_address": "عنوان البنك",
        "wire_transfer_upload_alert_text": "يرجى رفع نسخة من إثبات الدفع البنكي الخاص بك في الإيصال",
        "next": "التالي",
        "wire_transfer_has_been_canceled": "تم إلغاء عملية التحويل البنكي",
        "wire_transfer_slip_was_uploaded": "تم رفع الإيصال",
        "please_upload_slip": "يرجى رفع قسيمة البنك",
        "bank_slip_info_in_alert": "يجب أن يشمل إيصال البنك مبلغ الدفع وختم البنك بشكل واضح",
        "payment_slip": "قسيمة الراتب",
        "upload_payment_slip": "رفع بيان الراتب",
        "document_selected": "تم اختيار المستند",
        "you_can_upload_slip_now_or_later": "يمكنك رفع ورقة القسيمة الآن أو لاحقاً باستخدام الزر أعلاه. سنذكرك برفع ورقة القسيمة حتى تقوم برفعها.",
        "you_can_select_another_payment_method": "إذا كنت تريد في اختيار طريقة دفع أخرى، يرجى إلغاء التحويل البنكي باستخدام هذا الرابط.",
        "wire_transfer_cancel_button": "إلغاء التحويل البنكي واختيار طريقة دفع أخرى",
        "wire_transfer_remind_later_button": "ذكرني لاحقا",
        "wire_transfer_i_have_sent_money": "لقد أرسلت المال",
        "wire_transfer_slip_uploading": "يتم التحميل...",
        "wire_transfer_slip_under_review": "القسيمة قيد المراجعة",
        "wire_transfer_out_team_is_checking_slip": "سيقوم فريقنا بفحص القسيمة بعد قليل.",
        "download_slip": "تحميل القسيمة",
        "card_payment_cancel_button": "إلغاء الدفع بالبطاقة واختيار طريقة دفع أخرى",
        "card_payment_has_been_canceled": "تم إلغاء الدفع بالبطاقة",
        "error_failed_to_load_panel": "فشل تحميل صفحة الدفع",
        "add_account": "إضافة حساب",
        "learn_more_about": "تعرف المزيد حول",
        "lean_payment": "الدفع اللين",
        "lean_modal_title_1": "لماذا تستخدم التحويل البنكي الفوري؟",
        "lean_modal_title_2": "انقل الأموال على الفور مع البنك الخاص بك",
        "lean_modal_title_3": "تتيح لك طريقة الدفع هذه إجراء تحويل مصرفي فوري من حسابك المصرفي التجاري. ما عليك سوى ربط حسابك مرة واحدة ثم يمكنك استخدامه لأي مدفوعات مستقبلية.",
        "lean_modal_action_1": "قم بربط حسابك البنكي",
        "lean_modal_action_2": "الدفع الفوري",
        "lean_modal_title_4": "سيتم تأمين مدفوعاتك دائما من خلال المصادقة متعددة العوامل (MFA)، بنفس الطريقة كما لو كنت تستخدم حسابك المصرفي.",
        "payment_awaiting_authorization": "الدفع في انتظار الموافقة",
        "will_be_paid_after_authorize": "سيتم دفع مستحقاتك بعد موافقتك عليها.",
        "loading": "انتظر...",
        "connect_lean_account": "اربط حسابك المرن",
        "successfully_connected_bank": "تم ربط الحساب المصرفي بنجاح",
        "payment_has_been_queued": "تم وضع الدفع في قائمة الانتظار",
        "successfully_paid": "تم الدفع بنجاح",
        "cannot_pay": "فشل عملية الدفع",
        "lean": {
            "setup_status_active": "قيد العمل",
            "setup_status_pending": "معلقة",
            "setup_status_not_connected": "غير متصل",
            "account_is_being_connected": "يتم الآن ربط حسابك. يرجى اختيار طريقة دفع أخرى أو المحاولة مرة أخرى لاحقاً"
        }
    },
    "account_statement": {
        "recent_activities": "الأنشطة الأخيرة",
        "account_statement": "كشف الحساب",
        "currency": "درهم إماراتي",
        "from": "من",
        "to": "إلى",
        "starts_from": "يبدأ من",
        "downloading": "جاري التحميل...",
        "download": "تحميل",
        "invoice": "فاتورة",
        "credit_note": "مذكرة الائتمان",
        "empty_list": "لا توجد بيانات للفترة المختارة"
    },
    "calendar": {
        "month_january": "يناير",
        "month_february": "فبراير",
        "month_march": "آذار",
        "month_april": "أبريل",
        "month_may": "مايو",
        "month_june": "يونيو",
        "month_july": "تموز",
        "month_august": "أغسطس",
        "month_september": "أيلول",
        "month_october": "أكتوبر",
        "month_november": "نوفمبر",
        "month_december": "ديسمبر",
        "month": "شهر",
        "year": "سنة",
        "cancel": "إلغاء",
        "set": "تعيين",
        "clear": "مسح",
        "all_locations": "جميع المواقع"
    },
    "payment_item": {
        "status_draft": "مسودة",
        "status_paid": "مدفوع",
        "status_unpaid": "معلقة",
        "status_processed": "تمت معالجته",
        "status_voided": "ملغى"
    },
    "saved_payment_methods": {
        "method_has_been_added": "تمت إضافة طريقة الدفع بنجاح",
        "save_card": "حفظ البطاقة",
        "saving_progress": "يتم الحفظ...",
        "remove_payment_method": "إزالة طريقة الدفع",
        "method_has_been_deleted": "تم حذف طريقة الدفع بنجاح",
        "payment_methods": "طرق الدفع",
        "no_payment_methods_added": "لم يتم إضافة طرق للدفع بعد",
        "add_new_card": "إضافة بطاقة جديدة"
    },
    "error_boundary": {
        "default": {
            "server_error": "خطأ في الخادم الداخلي",
            "permission_error": "تم رفض الإذن",
            "client_error": "خطأ في التطبيق",
            "not_found_error": "لم يتم العثور عليه",
            "not_authorized_error": "غير مصرح له",
            "connection_error": "خطأ في الاتصال. تحقق من اتصالك بالإنترنت",
            "too_many_requests_error": "محاولات كثيرة. حاول مرة أخرى لاحقاً",
            "default_error": "حدث خطأ ما. يرجى المحاولة مرة أخرى",
            "try_again": "حاول مرة أخرى"
        },
        "payment": {
            "cannot_fetch_overdue_server_error": "لم نتمكن من جلب بيانات المدفوعات المتأخرة"
        }
    },
    "pending_orders_panel": {
        "pending_confirmation": "في انتظار التأكيد",
        "n_orders": "{{count}} طلب",
        "confirm_now": "تأكيد الآن"
    },
    "step_indicator": {
        "step_of": "خطوة{{currentStepNumber}} من {{stepsCount}}"
    },
    "page_stepper": {
        "next": "التالي",
        "previous": "السابق",
        "finish": "إنتهاء",
        "of": "من"
    },
    "order_status": {
        "draft": "مسودة",
        "pending_confirmation": "في انتظار التأكيد",
        "under_review": "تحت المراجعة",
        "started": "بدأت",
        "upcoming": "القادمة"
    },
    "toggle": {
        "yes_short": "نعم",
        "no_short": "لا"
    },
    "time": {
        "mon": "الإثنين",
        "tue": "الثلاثاء",
        "wed": "الأربعاء",
        "thu": "الخميس",
        "fri": "الجمعة",
        "sat": "السبت",
        "sun": "الأحد"
    },
    "time_picker": {
        "hour": "ساعة",
        "minute": "دقيقة",
        "cancel": "إلغاء",
        "set": "تعيين"
    },
    "become_affiliate": {
        "modal_title": "كن شريكاً",
        "modal_subtitle": "قم بدعوة موظفيك للانضمام إلى أوغرام وكسب أموال إضافية!",
        "accept_tc": "الشروط والأحكام"
    },
    "common_affiliate": {
        "join_ogram": "انضم إلى أوغرام باستخدام رابطي",
        "my_referrals": "إحالاتي",
        "send_invitation": "إرسال دعوة",
        "share_link": "قم بدعوة فريقك إلى تطبيق أوغرام وابدأ في الكسب!",
        "copy_and_send_link": "أو قم بنسخ الرابط أدناه وإرساله",
        "tc_1": "هذه المصطلحات لا ترتبط بشكل مباشر ببرامج الإحالة، ولكن هي قريبة بما يكفي لنشملها هنا",
        "tc_2": "يساعد تعريف هذه المصطلحات في تخفيف بعض الحيرة حول ماهو برنامج إحالة وما ليس بذلك.",
        "section_title": "شريك",
        "dashboard": "لوحة المعلومات",
        "referrals": "الإحالات",
        "earnings": "الأرباح",
        "designation": "مسمى الوظيفي",
        "payout": "المبلغ المستحق",
        "due_date": "تاريخ الاستحقاق",
        "join_date": "تاريخ الانضمام",
        "this_month": "هذا الشهر",
        "invite_more_staff": "دعوة المزيد من الموظفين",
        "margin_split": "الهامش",
        "invite_your_staff": "قم بدعوة موظفيك",
        "invite_staff_more_details": "قم بدعوة زملائك في العمل للانضمام إلى أوغرام لكسب أموال إضافية!",
        "invite_now": "قم بدعوة الآن",
        "earning_history": "تاريخ الأرباح",
        "total_earnings": "مجموع الأرباح",
        "status_profile_created": "تم إنشاء الملف الشخصي",
        "status_activated": "تم التفعيل",
        "status_booked_shifts": "المناوبات المحجوزة",
        "status_shifts_done": "المناوبات تم الإنتهاء منها",
        "status_unknown": "مجهول",
        "referrals_list": "قائمة الإحالات"
    },
    "affiliate_instructions": {
        "how_it_works": "كيف تعمل",
        "instruction_1_title": "إرسال دعوة",
        "instruction_1_subtitle": "شارك الرابط الخاص بك",
        "instruction_2_title": "ينضموا",
        "instruction_2_subtitle": "و ابدأ إكمال المناوبات المدفوعة",
        "instruction_3_title": "ابدأ بالكسب!",
        "instruction_3_subtitle": "احصل على عمولة مقابل عملهم",
        "instruction_4_title": "تتبع موظفيك",
        "instruction_4_subtitle": "استخدام لوحة المعلومات لشركات التابعة لنا"
    },
    "qr_scan": {
        "invalid_qr_code_provided": "تم توفير رمز QR غير صالح. يرجى تقديم الرمز الذي قدمه أحد مستخدمي أوغرام",
        "camera_permission_required": "مطلوب إذن للكاميرا",
        "access_denied": "تم رفض الوصول إلى الكاميرا.",
        "enable_permission": "لمسح رمز QR، يرجى تفعيل إذن للكاميرا أو بدلا من ذلك استخدم كاميرا الهاتف المحمول.",
        "instruction_title": "ما هي العملية؟",
        "step_1": "الخطوة الأولى",
        "to_scan": "مسح",
        "step_1_text": "قم بمسح الرمز المقدم من موظف أوغرام.",
        "step_2": "الخطوة الثانية",
        "check_in_or_out": "تسجيل الحضور أو المغادرة",
        "step_2_text": "قم بتسجيل الحضور أو المغادرة، ورؤية تفاصيل الوظيفة.",
        "sp_id_form_title": "إذا لم يعمل رمز QR، يرجى إدخال رقم الهوية الخاص بموظف أوغرام",
        "sp_id_label": "أدخل رقم هوية موظف أوغرام",
        "scan_qr": "مسح رمز QR",
        "sp_cant_be_checked_in_or_out": "لا يمكن تسجيل حضور/المغادرة من موظف أوغرام هذا",
        "qr": "QR",
        "scan_next_qr": "مسح رمز QR آخر",
        "success_modal_text": "لقد تم إكمال عملية {{processTitle}} بنجاح.",
        "carousel_title": "تسجيل حضور و مغادرة الموظفين بواسطة استخدام رمز QR",
        "sp_id_form_title_1": "رمز QR لا يعمل؟",
        "sp_id_form_title_2": "أدخل رقم هوية موظف أوغرام أدناه",
        "camera_missing_title": "كاميرا الويب الخاصة بك غير متوافقة مع المتصفح الحالي.",
        "camera_missing_description": " الرجاء تجربة كاميرا هاتفك المحمول 📸📱",
        "set_default_time": "تعيين الافتراضي"
    },
    "staff": {
        "past_workers": "الموظفون السابقون",
        "hours_worked": "ساعات العمل",
        "n_hours": "{{hoursAmount}} ساعة",
        "n_shifts": "{{shiftsAmount}} مناوبات",
        "last_job": "أخر عمل",
        "locations_worked": "أماكن تم العمل فيها",
        "plus_n_locations": "+ {{locationsAmount}} مواقع",
        "enter_notes_on_worker": "أدخل ملاحظاتك على الموظف",
        "favorite_only": "المفضلة فقط",
        "apply": "قدم",
        "reset": "إعادة",
        "how_was_the_worker": "كيف كان موظف أوغرام؟",
        "rating_submitted": "تم التقييم!",
        "search_worker_by_id_or_name": "البحث عن عامل حسب رقم الهوية أو الأسم",
        "worker_name": "اسم العامل",
        "designations": "المسمى الوظيفي",
        "your_rating": "تقييمك",
        "notes": "ملاحظات",
        "no_workers_found": "لم يتم العثور على عمال",
        "note_validation_length": "يجب ألا يزيد طول الملاحظات عن 499 حرفًا",
        "n_more": "{{ amount }} أكثر",
        "last_designation": "أخر وظيفة",
        "shifts_worked": "عدد المناوبات",
        "last_location": "أخر مكان"
    },
    "invited_sps": {
        "quantity_must_not_be_greater": "لا يمكن ان يكون عدد موظفي أوغرام المدعوين أكبر من العدد الذي قد طلب.إذا كنت بحاجة إلى المزيد من عملاء أوغرام، فيرجى زيادة عدد الموظفين للوظيفة في الخطوة الأولى",
        "form_title": "هل ترغب في دعوة موظفي أوغرام السابقين؟",
        "form_subtitle": "هذه الخطوة اختيارية. إذا قمت بدعوة عدد أقل من موظفي أوغرام مما تحتاجه، فسوف تقوم أوغرام بشغل الوظائف المتاحة المتبقية",
        "select_required_data": "الرجاء تحديد العدد و الموقع و الجدول أولاً",
        "invited_sps": "موظفي أوغرام المفضلين",
        "worked_at_current_location": "عمل في الموقع الحالي"
    },
    "pagination": {
        "page": "صفحة",
        "of": "من"
    },
    "rating_popup": {
        "title": "Please Rate Your Experience with Ogrammers",
        "subtitle_1": "We prioritize the best-rated workers for your future jobs. ",
        "subtitle_2": "Your ratings help us prioritize top performers, ensuring you get the best service every time",
        "ogs_to_rate": "OGs to Rate",
        "will_be_prioritized": "This OG will be prioritized for you",
        "will_not_be_assigned": "This OG won’t be assigned again."
    }
}
