import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import moment from 'moment'

import { DateInput } from '../../../date-input/date-input'
import { Separator } from '../../../app-layout'

type InputProps = {
  startDate: string | null
  setStartDate: (value: string | null) => void
  endDate: string | null
  setEndDate: (value: string | null) => void
}

export const DatesSelector = observer(({ startDate, setStartDate, endDate, setEndDate }: InputProps) => {
  const { t } = useTranslation()

  return (
    <>
      <span className="Popup__title">{t('order_extension.for_how_long_extend')}</span>
      <span className="Popup__subtitle">{t('order_extension.you_can_extend_order')}</span>
      <div className="BulkJobsExtension__popup__innerContainer">
        <DateInput
          label={t('order_draft.start_date')}
          value={startDate ?? ''}
          handleChange={setStartDate}
          min={moment().format('YYYY-MM-DD')}
          className="Dates__dateInput"
        />
      </div>
      <Separator.Vertical height={10} />
      <div className="BulkJobsExtension__popup__innerContainer">
        <DateInput
          label={t('order_draft.end_date')}
          value={endDate ?? ''}
          handleChange={setEndDate}
          min={startDate ?? undefined}
          className="Dates__dateInput"
        />
      </div>
    </>
  )
})
