import { Fragment } from 'react'
import { observer } from 'mobx-react'

import { useStore } from '../../../../../../../utils/mst-hooks'
import { Column } from './column'
import { RowSeparator } from './row-separator'
import { ScheduleShiftSnapshot, ScheduleDate, Schedule } from '../../../../../../../models/schedule/types'
import { formatDateToIso8601 } from '../../../../../../../utils/datetime-utils'
import { ScheduleSingleDateInstance } from '../../../../../../../models/schedule/single-date-data'

type CellProps = {
  weekDates: Date[]
  firstWeekDate: Date
  jobSchedules: Schedule[]
  renderCell: (props: {
    jobSchedule: Schedule | null
    shift: ScheduleShiftSnapshot | null
    dateShift: ScheduleDate | null
  }) => React.ReactNode | React.ReactNode[]
}

function CellsComponent({ weekDates, jobSchedules, firstWeekDate, renderCell: renderContent }: CellProps) {
  const { getJobShift } = useStore().data
  return (
    <>
      {weekDates.map(date => {
        const formatedWeekDate = formatDateToIso8601(firstWeekDate)
        let dateShift = null as ScheduleSingleDateInstance | null
        let jobSchedule = null as Schedule | null
        jobSchedules.forEach((jobScheduleItem, key) => {
          const existingDateShift = jobScheduleItem.getDateShift(date)
          if (existingDateShift?.shift_id) {
            dateShift = existingDateShift as ScheduleSingleDateInstance | null
            jobSchedule = jobScheduleItem as Schedule | null
          }
        })
        const jobShift =
          dateShift && jobSchedule
            ? getJobShift(jobSchedule.job_id, formatedWeekDate, Number(dateShift.shift_id))
            : null
        return (
          <Fragment key={date.getDate()}>
            <RowSeparator />
            <Column>{renderContent({ jobSchedule, shift: jobShift, dateShift })}</Column>
          </Fragment>
        )
      })}
    </>
  )
}

export const Cells = observer(CellsComponent)
