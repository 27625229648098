import { types, flow, SnapshotIn } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const EmployeesAmountTierModel = types.model('EmployeesAmountTierModel', {
  value: types.identifierNumber,
  label: types.string,
  key: types.string,
})

export const employeesAmountTierActions = (self: DataStore) => ({
  getEmployeesAmountTiers: flow(function* () {
    const data = (yield self.request('get', `common/employee-amount-tiers`)) as { value: number; label: string }[]
    data.forEach(item => {
      self.employeesAmountTier.set(String(item.value), {
        ...item,
        key: String(item.value),
      })
    })
  }),
})

export const employeesAmountTierViews = (self: DataStore) => ({
  get employeesAmountTierList() {
    return Array.from(self.employeesAmountTier.values())
  },
})

export type EmployeesAmountTier = SnapshotIn<typeof EmployeesAmountTierModel>
