import { observer } from 'mobx-react'
import { useRef, useState } from 'react'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { BasicDataFormProps, ContactDataFormProps, RegisterClientUIProps as UIProps } from './types'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { useFirebaseToken } from '../../../utils/use-firebase-token'
import { useRedirect } from '../../../services/router'
import { trackingService } from '../../../services/tracking-service'
import { useDraftJobsStore } from '../../../models-ui/order/store'
import { routes } from '../../../routes'
import { SignUpDataType } from '../../../models/auth'
import { generateDeviceId } from '../../../utils/device-id-generator'

const STEP_BASIC_DATA_INDEX = 0
const STEP_CONTACT_DATA_INDEX = 1

const STEPS_COUNT = 2

export function createRegisterClientContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return observer(() => {
    const [currentStep, setCurrentStep] = useState(STEP_BASIC_DATA_INDEX)
    const {
      signUpWithOrderData,
      setFirstName,
      setLastName,
      setCompanyName,
      setPhone,
      setEmail,
      toggleEmailAgreement,
      setIndustry,
      setEmployeesAmountTier,
      register,
    } = useStore().data

    const basicDataFormRef = useRef<FormikProps<BasicDataFormProps>>(null)
    const contactDataFormRef = useRef<FormikProps<ContactDataFormProps>>(null)

    const firebaseToken = useFirebaseToken()
    const redirect = useRedirect()
    const { t } = useTranslation()

    const { createDraftJob } = useDraftJobsStore()

    const onSignUpSuccess = () => {
      if (!signUpWithOrderData.workCategoryId) {
        return
      }

      trackingService.metaConversion('track', 'Lead')
      const draftJob = createDraftJob()
      draftJob.category.set(signUpWithOrderData.workCategoryId)
      redirect(routes.setJobLocation, { draftJobId: draftJob.id, stepNumber: '2' })
    }

    const handleRegistration = () => {
      const isCorporate = (signUpWithOrderData.companyName ?? '').length > 0 ? 1 : 0
      const submitData: SignUpDataType = {
        first_name: signUpWithOrderData.firstName ?? '',
        last_name: signUpWithOrderData.lastName ?? '',
        phone: signUpWithOrderData.phone ?? '',
        email: signUpWithOrderData.email ?? '',
        allow_marketing_emails: Number(signUpWithOrderData.allow_marketing_emails),
        is_corporate: isCorporate,
        industry: signUpWithOrderData.industry,
        device_id: generateDeviceId(),
        firebase_token: firebaseToken,
        employees_amount_tier: signUpWithOrderData.employeesAmountTier ?? null,
      }

      if (isCorporate) {
        submitData.company_name = signUpWithOrderData.companyName ?? ''
      }

      requestSignUp(submitData)
    }

    const handleForm = <T,>(formRef: React.RefObject<FormikProps<T> | undefined>, onSuccess: () => void) => {
      formRef.current?.validateForm().then(() => {
        if (!formRef.current?.isValid) {
          return
        }

        onSuccess()
      })
    }

    const handleNextPress = () => {
      switch (currentStep) {
        case STEP_BASIC_DATA_INDEX:
          handleForm(basicDataFormRef, () => setCurrentStep(STEP_CONTACT_DATA_INDEX))
          break
        case STEP_CONTACT_DATA_INDEX:
          handleForm(contactDataFormRef, handleRegistration)
          break
        default:
          break
      }
    }

    const handleBackPress = () => {
      switch (currentStep) {
        case STEP_BASIC_DATA_INDEX:
          redirect(routes.signUpWithOrder, {})
          break
        default:
          setCurrentStep(currentStep - 1)
          break
      }
    }

    const [signUpResult, requestSignUp] = useMutation(register, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: onSignUpSuccess,
      onError: () => {
        const redirectToBasicForm =
          signUpResult.error?.data.errors?.company_name ??
          signUpResult.error?.data.errors?.first_name ??
          signUpResult.error?.data.errors?.last_name

        if (redirectToBasicForm) {
          setCurrentStep(STEP_BASIC_DATA_INDEX)
        }
      },
    })

    const remoteErrors = signUpResult.error?.data.errors
    const isSubmitting = signUpResult.isLoading

    return (
      <UIComponent
        showBasicDataForm={currentStep === STEP_BASIC_DATA_INDEX}
        showContactDataForm={currentStep === STEP_CONTACT_DATA_INDEX}
        isSubmitting={isSubmitting}
        currentStep={currentStep}
        basicDataFormInnerRef={basicDataFormRef}
        firstName={signUpWithOrderData.firstName ?? ''}
        lastName={signUpWithOrderData.lastName ?? ''}
        companyName={signUpWithOrderData.companyName ?? ''}
        handleFirstNameChange={setFirstName}
        handleLastNameChange={setLastName}
        handleCompanyNameChange={setCompanyName}
        handleIndustryChange={setIndustry}
        handleEmployeesAmountTierChange={setEmployeesAmountTier}
        firstNameErrors={remoteErrors?.first_name}
        lastNameErrors={remoteErrors?.last_name}
        companyNameErrors={remoteErrors?.company_name}
        industryErrors={remoteErrors?.industry}
        employeesAmountTierErrors={remoteErrors?.employees_amount_tier}
        phone={signUpWithOrderData.phone ?? ''}
        email={signUpWithOrderData.email ?? ''}
        industry={signUpWithOrderData.industry}
        employeesAmountTier={signUpWithOrderData.employeesAmountTier}
        allow_marketing_emails={Boolean(signUpWithOrderData.allow_marketing_emails)}
        handlePhoneChange={setPhone}
        handleEmailChange={setEmail}
        handleToggleEmailAgreement={toggleEmailAgreement}
        contactDataFormInnerRef={contactDataFormRef}
        phoneErrors={remoteErrors?.phone}
        emailErrors={remoteErrors?.email}
        allow_marketing_emailsErrors={remoteErrors?.allow_marketing_emails}
        stepsCount={STEPS_COUNT}
        nextDisabled={false}
        backDisabled={false}
        nextButtonLabel={t('sign_up.next')}
        handleNextPress={handleNextPress}
        handleBackPress={handleBackPress}
      />
    )
  })
}
