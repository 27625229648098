import { useTranslation } from 'react-i18next'
import { default as ReactSelect } from 'react-select'

import { FormField } from '../form'
import './multiselect.scss'

type SelectProps<T> = {
  label?: string
  required?: boolean
  field?: FormField<T>
  value?: T
  onChange: (values: string[]) => void
  options: { label: string; value: T; key: string | null }[]
  className?: string
  preselectAll?: boolean
}

export function Multiselect<T>(props: SelectProps<T>) {
  const { t } = useTranslation()

  const controlDefaultClassNames = `MultiSelect__control${props.className ? ` ${props.className}` : ''}`
  const options = props.options.map(val => {
    return { value: val.value, label: val.label }
  })
  const item = options.find(val => val.value === (props.value ?? props.field?.value))

  return (
    <>
      <ReactSelect
        defaultValue={props.preselectAll ? options : [item]}
        onChange={selectedOptions => {
          props.onChange(selectedOptions.map(option => String(option?.value)))
        }}
        required={props.required}
        options={options}
        placeholder={props.label ?? t('common.select')}
        classNames={{
          control: state =>
            !state.isFocused ? controlDefaultClassNames : `${controlDefaultClassNames} MultiSelect__control--focused`,
          menu: state => 'MultiSelect__menu',
        }}
        className="MultiSelect"
        isMulti
      />
    </>
  )
}
