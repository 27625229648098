import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { flip, offset, shift, useFloating } from '@floating-ui/react'

import { TimeInput } from '../../../../../../../components/form/time-input/time-input'
import './time.scss'
import { Multiselect } from '../../../../../../../components/form/multiselect/multiselect'

export type TimeProps = {
  value: string | null
  label: string
  disabled: boolean
  highlighted?: boolean
  handleChange: (value: string) => void
  onPopoverAction?: (itemIds: number[]) => void
  popoverItems?: { key: string; label: string; value: number }[]
  popoverDate?: string
  spName?: string | null
}

export function Time({
  value,
  label,
  disabled,
  highlighted = false,
  handleChange,
  onPopoverAction,
  popoverItems = [],
  popoverDate = '',
  spName = null,
}: TimeProps) {
  const { t } = useTranslation()

  const [spIdsToBulkChange, setSpIdsToBulkChange] = useState<number[]>([])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { refs, strategy, floatingStyles } = useFloating({
    placement: 'left', // Initial placement
    middleware: [offset(10), flip(), shift()],
  })

  useEffect(() => {
    if (popoverItems.length) {
      setSpIdsToBulkChange(popoverItems.map(item => item.value))
    }
  }, [popoverItems])

  return (
    <div className="ScheduleTime">
      <div ref={refs.setReference} style={{ display: 'inline-block' }}>
        <TimeInput
          disabled={disabled}
          label={label}
          value={value}
          handleChange={val => {
            const prevVal = value
            handleChange(val)
            if (prevVal !== val && popoverItems.length > 0) {
              setIsPopupOpen(true)
            }
          }}
          className={`ScheduleTime__timeInput ${disabled ? 'disabled' : ''} ${highlighted ? 'highlighted' : ''}`}
          useDropdownWithInput
        />
      </div>
      {isPopupOpen && (
        <div
          ref={refs.setFloating} // Reference for the popover positioning
          style={{
            ...floatingStyles,
            position: strategy,
            top: 0,
            left: 0,
            background: 'white',
            borderRadius: '16px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
          }}
        >
          {/* Your Popup Content */}
          <div className="BulkChangePopup">
            <div className="BulkChangePopup__container">
              <span className="BulkChangePopup__title">
                Copy changes to more OGs on <strong>{popoverDate}</strong>:
              </span>
              <Multiselect
                className="BulkChangePopup__select"
                label={'Select OGs to apply changes'}
                onChange={values => {
                  const modifiedValues = values.map(itemValue => Number(itemValue))
                  setSpIdsToBulkChange(modifiedValues)
                }}
                options={popoverItems}
                preselectAll
              />
              <div className="BulkChangePopup__controls">
                <button onClick={() => setIsPopupOpen(false)} className="BulkChangePopup__secondary">
                  {t('all_jobs.apply_to_sp_only', { spName })}
                </button>
                <button
                  onClick={() => {
                    if (onPopoverAction) {
                      onPopoverAction(spIdsToBulkChange)
                    }

                    setIsPopupOpen(false)
                  }}
                  className="BulkChangePopup__primary"
                >
                  {t('all_jobs.bulk_apply')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
